import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import {
    CHECK_USERNAME,
    CREATE_MEMBER,
    GET_COUNTRIES,
    GET_PROFESSIONALREGISTRATIONS,
} from '../graphql/member';
import {
    CountryItem,
    CreateMemberCommandInput,
    MemberProfile,
    ProfessionalRegistrationItem,
} from '../graphql/__generated__/graphql';

import IJoinForm from '../interfaces/IJoinForm';
import { GET_PROFILE } from '../graphql/profile/queries';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

export interface IDataContext {
    getProfessionalRegistrations: () => Promise<ProfessionalRegistrationItem[]>;
    getCountries: () => Promise<CountryItem[]>;
    getUsernameAvailability: (username: string) => Promise<boolean>;
    createMember: (model: IJoinForm) => Promise<any>;
    updateMember: (properties: { property: string; value: any }[]) => Promise<any>;
    getMemberProfile: () => Promise<MemberProfile>;
}

export const DataContext = createContext<IDataContext | null>(null);

export function useDataContext() {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
}

export function DataContextProvider({ children }: { children: React.ReactNode }) {
    // const memberClient = useApolloClient();
    //  const userContext = useContext(UserContext);

    // Queries
    const getMemberProfile = async (): Promise<MemberProfile> => {
        try {
            const { data } = await client.query({
                query: GET_PROFILE,
            });

            return data.member as MemberProfile;
        } catch (error) {
            throw error;
        }
    };

    const getProfessionalRegistrations = async (): Promise<ProfessionalRegistrationItem[]> => {
        try {
            const { data } = await client.query({
                query: GET_PROFESSIONALREGISTRATIONS,
            });

            return data.professionalRegistrations.map((x: ProfessionalRegistrationItem) => {
                return {
                    name: x.name,
                    professionalRegistrationId: x.professionalRegistrationId,
                };
            }) as ProfessionalRegistrationItem[];
        } catch (error) {
            throw error;
        }
    };

    const getCountries = async (): Promise<CountryItem[]> => {
        try {
            const { data } = await client.query({
                query: GET_COUNTRIES,
            });

            return data.countries.map((x: CountryItem) => {
                return {
                    countryId: x.countryId,
                    name: x.name,
                };
            }) as CountryItem[];
        } catch (error) {
            throw error;
        }
    };

    const getUsernameAvailability = async (): Promise<boolean> => {
        try {
            const { data } = await client.query({
                query: CHECK_USERNAME,
                variables: {
                    username: 'eric.riley@syntaxits.com',
                },
            });

            return data as boolean;
        } catch (error) {
            throw error;
        }
    };

    // Mutations
    const createMember = async (form: IJoinForm): Promise<any> => {
        try {
            const member: CreateMemberCommandInput = {
                title: form.title,
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                defaultTelephone: form.phone,
                website: form.website,

                defaultAddress: {
                    addressLine1: form.address1,
                    addressLine2: form.address2,
                    city: form.city,
                    postcode: form.postcode,
                    country: form.country,
                    default: true,
                },

                languages: form.languages,
                qualifications: form.qualifications,
                professionalRegistrations: form.registrations,
                professions: form.professions,
                childTrainingLevel: form.childLevel,
                childTrainingLevelDateAchieved:
                    form.childLevelDate !== '' ? form.childLevelDate : null,
                region: { name: form.region, regionId: form.region },

                emdrTrainingLevel: {
                    name: form.emdrLevel,
                    emdrTrainingLevelId: form.emdrLevel,
                },
                emdrTrainingLevelDateAchieved: form.emdrLevelDate,

                membershipTypeId: form.membershipType,

                declarationAgreed: form.declarationAgreed,
                newsOptIn: form.newsOptIn,
                advertisingOptIn: form.advertisingOptIn,
                userAccount: {
                    username: form.username,
                    password: form.password,
                },
            };

            await client
                .mutate({
                    mutation: CREATE_MEMBER,
                    variables: { member },
                })
                .then((response) => {
                    return response;
                });
        } catch (error) {
            throw error;
        }
    };
    const updateMember = async (properties: { property: string; value: any }[]): Promise<any> => {
        try {
            //if (!userContext) {
            //    console.error("missing userContext");
            //    return;
            //}
            // let profile = userContext?.updateProfileProperties(properties);
            //let member: UpdateMemberCommandInput = {
            //    //firstName: profile.firstName,
            //    //lastName: profile?.lastName
            //}
            //await client.mutate({
            //    mutation: UPDATE_MEMBER,
            //    variables: { member }
            //}).then((response) => {
            //    return response;
            //})
        } catch (e) {}
    };

    return (
        <ApolloProvider client={client}>
            <DataContext.Provider
                value={{
                    getProfessionalRegistrations,
                    getCountries,
                    getUsernameAvailability,
                    createMember,
                    updateMember,
                    getMemberProfile,
                }}
            >
                {children}
            </DataContext.Provider>
        </ApolloProvider>
    );
}
