import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import RankingSelect from '../../Form/RankingSelect/RankingSelect';
import IRankingsPair from '../../../interfaces/IRankingsPair';
import React, { useRef } from 'react';
import { IEvaluationRequirement } from '../../../interfaces/IApplicationRequirements';
import { FeedbackLevel } from '../../../interfaces/FeedbackLevel';
import { IPartData } from '../../../interfaces/IApplicationReviewForm';

interface Props {
    isAlgorithm: boolean;
    readOnly: boolean;
    req: IEvaluationRequirement;
    inputValue: IRankingsPair | string;
    inputComplete: boolean;
    onChange: (change: IPartData<IRankingsPair | string>) => void;
    onFeedback: (level: FeedbackLevel, msg: string) => void;
    minLength: number;
    maxLength?: number;
}

const EvaluationInput: React.FC<Props> = ({
    isAlgorithm,
    req,
    readOnly,
    inputValue,
    inputComplete,
    onChange,
    onFeedback,
    minLength,
    maxLength,
}) => {
    const [showValidation, setShowValidation] = React.useState(false);
    const formControlRef = useRef<any>();

    const handleChangeComplete = (newComplete: boolean) => {
        setShowValidation(true);
        if (newComplete) {
            if (!isAlgorithm) {
                const result = formControlRef.current && formControlRef.current.checkValidity();
                if (!result) {
                    onFeedback('danger', 'Cannot mark as complete whilst form has errors');
                    return;
                }
            } else {
                const pair = inputValue as IRankingsPair;
                const expectedValues = req.elements?.length || 1;
                if (Object.keys(pair).length !== expectedValues) {
                    onFeedback(
                        'danger',
                        'Cannot mark as complete until all elements have been assigned a value'
                    );
                    return;
                }
            }
        }
        onChange({
            value: inputValue,
            complete: newComplete,
        });
    };

    const handleValueChange = (newValue: string) => {
        onChange({
            value: newValue,
            complete: inputComplete,
        });
    };

    const handleRankingChange = (_name: string, value: IRankingsPair) => {
        onChange({
            value: value,
            complete: inputComplete,
        });
    };

    const valid =
        (!isAlgorithm &&
            (inputValue as string)?.length >= minLength &&
            (inputValue as string)?.length <= (maxLength ?? Number.MAX_SAFE_INTEGER)) ||
        isAlgorithm;

    return (
        <>
            {isAlgorithm && (
                <RankingSelect
                    handleChange={handleRankingChange}
                    handleFeedback={onFeedback}
                    readOnly={readOnly || inputComplete}
                    req={req}
                    value={inputValue as IRankingsPair}
                />
            )}
            {!isAlgorithm && (
                <FormGroup>
                    <Form.Label className={'text-black'}>
                        <p className={'fw-bold'}>{req.subtitle}</p>

                        {req.elements?.length && (
                            <ul>
                                {req.elements.map((e, i) => (
                                    <li key={i}>{e}</li>
                                ))}
                            </ul>
                        )}
                        <FormControl
                            ref={formControlRef}
                            plaintext={readOnly}
                            className={`mt-2 ${readOnly && 'border-primary border-opacity-25'}`}
                            as={'textarea'}
                            rows={5}
                            name={'value'}
                            value={(inputValue as string) || ''}
                            onChange={(e) => {
                                handleValueChange(e.target.value);
                            }}
                            readOnly={readOnly || inputComplete}
                            minLength={minLength}
                            required
                            isValid={showValidation && valid}
                            isInvalid={showValidation && !valid}
                        />
                    </Form.Label>
                </FormGroup>
            )}
            {!readOnly && !isAlgorithm && (
                <div>
                    {minLength > 0 && (inputValue as string)?.length < minLength && (
                        <Form.Text className={'flex-grow-0'}>
                            Enter at least {minLength} characters
                        </Form.Text>
                    )}
                    {maxLength && (inputValue as string)?.length > maxLength && (
                        <Form.Text className={'flex-grow-0 text-danger'}>
                            Too many characters
                        </Form.Text>
                    )}
                    {maxLength && (
                        <Form.Text
                            className={`flex-grow-0 float-end text-end ${((inputValue as string)?.length || 0) > maxLength && 'text-danger'}`}
                        >
                            {(inputValue as string)?.length || 0}/{maxLength}
                        </Form.Text>
                    )}
                </div>
            )}
            {!readOnly && (
                <Button
                    disabled={!valid && !inputComplete}
                    className={'mt-3 w-25'}
                    variant="outline-secondary"
                    onClick={() => handleChangeComplete(!inputComplete)}
                >
                    Mark section as {inputComplete ? 'in' : ''}complete
                </Button>
            )}
            <FormControl type={'hidden'} value={inputComplete ? 'true' : 'false'} />
        </>
    );
};

export default EvaluationInput;
