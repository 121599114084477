import React from 'react';
import IEvaluationFormStep from 'interfaces/IEvaluationFormStep';
import { Accordion, FormGroup, Row } from 'react-bootstrap';
import AccordionItem from './AccordionItem';
import _ from 'lodash';
import ISimpleNameValuePair from '../../../../interfaces/ISimpleNameValuePair';
import AlgorithmLegend from '../AlgorithmLegend';
import HelpButton from '../../../Form/HelpButton';

const AccordionInput: React.FC<IEvaluationFormStep> = ({
    formData,
    onFeedback,
    requirementsGroup,
    onInputChange,
    readOnly = false,
    requirements,
}) => {
    const partData: ISimpleNameValuePair[] = formData[requirementsGroup.name] || [];
    const help = requirements.config?.help && requirements.config?.help[requirementsGroup.name];

    const handleOnChange = (name: string, value: any) => {
        onInputChange(requirementsGroup.name, _.unionBy([{ name, value }], partData, 'name'));
    };

    const partBMerged = _.merge(
        _.keyBy(requirementsGroup.requirements, 'name'),
        _.keyBy(partData, 'name')
    );
    const mergedReqs = _.sortBy(_.values(partBMerged), 'sortKey');
    const isAlgorithm = requirementsGroup.requirements[0].type?.toLowerCase() === 'algorithm';
    const sectionComplete = partData.every((b) => b.value?.complete);

    return (
        <Row>
            <h2>
                {requirementsGroup.pageTitle}{' '}
                {sectionComplete && <i className={'bi bi-check2-circle ms-2 fs-4'} />}
                <HelpButton title={requirementsGroup.pageTitle} content={help}>
                    {isAlgorithm && <AlgorithmLegend />}
                </HelpButton>
            </h2>
            <p className={'text-black'}>{requirementsGroup.pageSubtitle}</p>
            <FormGroup>
                <Accordion
                    defaultActiveKey={mergedReqs.find((r) => !r.value?.complete)?.name}
                    className={'bg-transparent'}
                >
                    {mergedReqs.map((req) => (
                        <AccordionItem
                            key={req.name}
                            req={req}
                            readOnly={readOnly}
                            onChange={handleOnChange}
                            value={req.value}
                            handleFeedback={onFeedback}
                        />
                    ))}
                </Accordion>
            </FormGroup>
        </Row>
    );
};

export default AccordionInput;
