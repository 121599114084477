import React from 'react';
import { Card, CardBody, CardHeader, Table } from 'react-bootstrap';
import { useDocuments } from '../../hooks/useDocuments';
import { LoadingSpinner } from '../LoadingSpinner';
import { DocumentItem } from './DocumentItem';
import './index.scss';
import { NewDocumentDialog } from './NewDocumentDialog';

export const DocumentList: React.FC<{ compact?: boolean }> = ({ compact }) => {
    const { models } = useDocuments();

    return (
        <>
            <Card border="light" className="mb-3">
                {compact && (
                    <CardHeader style={{ border: 'none', background: 'white' }}>
                        <div className="medium">Documents</div>
                    </CardHeader>
                )}
                <CardBody style={compact ? { maxHeight: '50vh', overflowY: 'scroll' } : undefined}>
                    {models.loading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : models.documents && models.documents.length > 0 ? (
                        <Table striped hover>
                            {!compact ? (
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Expires</td>
                                        <td>CPD</td>
                                        <td></td>
                                    </tr>
                                </thead>
                            ) : (
                                <thead>
                                    <td width={300} height={0} />
                                    <td height={0} />
                                </thead>
                            )}

                            <tbody>
                                {models.documents?.map((document, index) => (
                                    <DocumentItem
                                        document={document}
                                        compact={compact}
                                        key={document.id}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '2em',
                                marginBottom: '2em',
                            }}
                        >
                            You do not have any documents stored.
                            <div
                                style={{
                                    marginTop: '2em',
                                    marginBottom: '2em',
                                }}
                            >
                                <NewDocumentDialog buttonTitle="Upload Document"></NewDocumentDialog>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        </>
    );
};
