import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Container>
            {/*<Row>*/}
            {/*    <Col xs={12} className="text-center my-4">*/}
            {/*        <img src={imgSrc}  width="200"></img>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row className="py-4">
                <Col xs={12} className="text-center">
                    {children}
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;
