import React from 'react';
import IAccreditationApplicationForm from 'interfaces/IAccreditationApplicationForm';
import { MemberProfile } from 'graphql/__generated__/graphql';
import MemberDetailsBlock from './MemberDetailsBlock';
import IApplicationRequirements, { IAppRequirement } from 'interfaces/IApplicationRequirements';
import _ from 'lodash';
import { useDocuments } from 'hooks/useDocuments';
import { Col, Row, Tab } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import ClinicalContactReviewEntry from '../../ClinicalContactReviewEntry';

interface IApplicationReviewBlockProps {
    formData: IAccreditationApplicationForm;
    memberProfile?: MemberProfile;
    requirements: IApplicationRequirements;
}

const ApplicationReviewBlock: React.FC<IApplicationReviewBlockProps> = ({
    formData,
    requirements,
    memberProfile,
}) => {
    const { operations } = useDocuments();

    const renderList = (items: any[]) => {
        return (
            <ul className={'list-unstyled'}>
                {items?.map((item, index: any) => <li key={index}>{item}</li>)}
            </ul>
        );
    };

    const toNiceOutput = (value: any) => {
        switch (typeof value) {
            case 'undefined':
                return 'unknown';
            case 'boolean':
                return value ? 'completed' : 'incomplete';
            case 'string':
                const lower = value.charAt(0).toLowerCase() + value.slice(1);
                return lower.startsWith('number of ') ? lower.slice(9) : lower;
            default:
                return value;
        }
    };

    const mergedExperience = _.merge(
        _.keyBy(requirements.experience, 'name'),
        _.keyBy(formData.experiences, 'name')
    );
    const experiences = _.values(mergedExperience).map((exp) =>
        typeof exp.value === 'boolean'
            ? `${exp.title} ${toNiceOutput(exp.value)}`
            : `${toNiceOutput(exp.value)} ${toNiceOutput(exp.title)}`
    );

    const flattenedDocs: IAppRequirement[] = _.flatMap(requirements.documents, 'requirements');
    const mergedDocuments = _.values(
        _.merge(_.keyBy(flattenedDocs, 'name'), _.keyBy(formData.documents, 'name'))
    );

    return (
        <>
            {memberProfile && <MemberDetailsBlock memberProfile={memberProfile} />}
            <dl className={'row'}>
                <dt>Your experience</dt>
                <dd>{renderList(experiences)}</dd>
            </dl>
            <dl className={'row'}>
                <dt className={'mb-1'}>
                    Clinical contact records ({formData.clinicalContacts?.length})
                </dt>
                <dd>
                    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link0">
                        <Row>
                            <Col sm={3}>
                                <div className={'overflow-y-auto'} style={{ height: '17em' }}>
                                    <ListGroup>
                                        {formData.clinicalContacts?.map((cc, i) => (
                                            <ListGroup.Item
                                                action
                                                href={'#link' + i}
                                                key={'item' + i}
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                {cc.initials}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col>
                                <Tab.Content>
                                    {formData.clinicalContacts?.map((cc, i) => (
                                        <Tab.Pane key={'pane' + i} eventKey={'#link' + i}>
                                            <ClinicalContactReviewEntry cc={cc} />
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </dd>
            </dl>
            <dl className={'row'}>
                <dt className={'col-sm-6'}>Supervisors</dt>
                <dd>
                    {renderList(
                        formData.supervisors?.map(
                            (s) =>
                                s.name +
                                ': ' +
                                (s.startDate
                                    ? toNiceOutput(new Date(s.startDate).toLocaleDateString())
                                    : 'unknown date') +
                                ' - ' +
                                (s.endDate
                                    ? new Date(s.endDate).toLocaleDateString()
                                    : s.ongoing
                                      ? 'present'
                                      : 'unknown date')
                        )
                    )}
                </dd>
            </dl>
            <dl className={'row'}>
                <dt className={'col-sm-6'}>Documents</dt>
                <dd>
                    {renderList(
                        mergedDocuments
                            .filter((d) => !!d.value)
                            .map((d) => (
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        operations.downloadDocument(d.value as string);
                                    }}
                                    href="#"
                                    title={`Download + ${d.title}`}
                                >
                                    {d.title}
                                </a>
                            ))
                    )}
                </dd>
            </dl>
        </>
    );
};

export default ApplicationReviewBlock;
