import { FloatingLabel, Form, FormGroup } from 'react-bootstrap';
import React from 'react';
import { IAppRequirement } from 'interfaces/IApplicationRequirements';

interface IApplicationsRequirementsProps {
    name: string;
    value: any;
    requirement: IAppRequirement;
    handleChange: (name: string, value: any) => void;
    selectOptions?: { id: string; name: string }[];
}

const ApplicationRequirementControl: React.FC<IApplicationsRequirementsProps> = ({
    name,
    value,
    requirement,
    handleChange,
    selectOptions = [],
}) => {
    switch (requirement.type) {
        case 'checkbox':
            const isValid = requirement.required === value;
            return (
                <FormGroup controlId={name}>
                    <Form.Label className={'d-flex'}>
                        <Form.Check
                            style={{ paddingRight: '1em' }}
                            required={requirement.required || false}
                            name={`experience.${name}`}
                            onChange={(e) => {
                                const { checked } = e.currentTarget;
                                handleChange(name, checked);
                            }}
                            checked={value !== undefined ? (value as boolean) : false}
                            isValid={value !== undefined && isValid}
                            isInvalid={value !== undefined && !isValid}
                        />
                        {requirement.label}
                    </Form.Label>
                </FormGroup>
            );
        case 'document':
            return (
                <FormGroup controlId={name}>
                    <FloatingLabel
                        label={requirement.title + (requirement.required ? ' (required)' : '')}
                    >
                        <Form.Select
                            name={`document.${name}`}
                            required={requirement.required || false}
                            value={value || ''}
                            onChange={(e) => handleChange(name, e.currentTarget.value)}
                            disabled={selectOptions.length === 0}
                        >
                            <option>
                                {selectOptions.length > 0
                                    ? 'Select your document'
                                    : 'No documents available'}
                            </option>
                            {selectOptions.map((item) => (
                                <option
                                    value={item.id}
                                    key={`${requirement.name}-${name}-${item.id}`}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </FormGroup>
            );
        default:
            const isValidNumber =
                (value as number) >= (requirement.min ? requirement.min : 0) &&
                (value as number) <= (requirement.max ? requirement.max : Number.MAX_SAFE_INTEGER);
            return (
                <FormGroup controlId={`experience.${name}`}>
                    <Form.Control
                        aria-label={requirement.title}
                        style={{ width: '90px', borderRadius: '5px' }}
                        type={requirement.type}
                        required={requirement.required}
                        name={`experience.${name}`}
                        min={requirement.min}
                        max={requirement.max}
                        onChange={(e) => handleChange(name, e.target.value)}
                        value={(value as number) || ''}
                        isInvalid={value !== undefined && !isValidNumber}
                        isValid={value !== undefined && isValidNumber}
                    />
                </FormGroup>
            );
    }
};

export default ApplicationRequirementControl;
