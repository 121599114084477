import React, { useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    FloatingLabel,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import TitleSelect from '../../Form/TitleSelect';
import { LoadingError } from '../../LoadingError';
import { IEditableNameProps } from './IEditableNameProps';
import { useEditableName } from './useEditableName';

export const EditableName: React.FC<IEditableNameProps> = ({ data, loading, error, onUpdate }) => {
    const [hover, setHover] = useState(false);

    const { models, operations } = useEditableName({ data });
    const { show, formData } = models;
    const { handleInputChangeEvent, handleOpen, handleClose } = operations;

    return (
        <>
            {/*loading && <LoadingSpinner></LoadingSpinner>*/}
            {error && <LoadingError></LoadingError>}
            {!loading && !error && (
                <>
                    <div
                        className="mt-2"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={() => handleOpen()}
                        style={{
                            cursor: 'pointer',
                            opacity: hover ? 0.8 : 1,
                        }}
                        title="click to update your name"
                        data-testid="member-editablename"
                    >
                        {loading ? (
                            <Spinner />
                        ) : (
                            <h2>
                                {data?.profile?.firstName} {data?.profile?.lastName}
                                <span
                                    style={{
                                        position: 'absolute',
                                        marginLeft: '1em',
                                        fontSize: '16px',
                                    }}
                                >
                                    <i className="bi bi-pencil"></i>
                                </span>
                            </h2>
                        )}
                    </div>
                    <Modal show={show} onHide={handleClose} data-testid="member-editablename-modal">
                        <Modal.Body className="text-center">
                            <Row>
                                <Form.Group
                                    as={Col}
                                    md={4}
                                    lg={3}
                                    className="mb-3 fade-out"
                                    data-testid="member-title"
                                >
                                    <FloatingLabel label="Title">
                                        <TitleSelect
                                            name="title"
                                            value={formData?.title ?? ''}
                                            onChange={handleInputChangeEvent}
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md={9}
                                    lg={4}
                                    className="mb-3"
                                    data-testid="member-firstname"
                                >
                                    <FloatingLabel label="First Name">
                                        <Form.Control
                                            required
                                            placeholder=""
                                            name="firstName"
                                            value={formData?.firstName}
                                            onChange={handleInputChangeEvent}
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md={9}
                                    lg={5}
                                    className="mb-3"
                                    data-testid="member-lastname"
                                >
                                    <FloatingLabel label="Last Name">
                                        <Form.Control
                                            required
                                            placeholder=""
                                            name="lastName"
                                            value={formData?.lastName}
                                            onChange={handleInputChangeEvent}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <ButtonGroup>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                {formData && (
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            onUpdate(formData)
                                                .then(() => {
                                                    console.log(data);
                                                })
                                                .then(() => handleClose())
                                        }
                                    >
                                        Update
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    );
};

export default EditableName;
