import { useApolloClient, useQuery } from '@apollo/client';
import {
    CREATE_APPLICATION,
    DELETE_APPLICATION,
    GET_ACCREDITATION_APPLICATIONS,
    GET_APPLICATION_CONFIG,
    PROGRESS_APPLICATION,
    UPDATE_APPLICATION,
} from 'graphql/member';
import { useEffect, useState } from 'react';
import {
    AccreditationType,
    ApplicationStatus,
    CreateAccreditationApplicationCommandInput,
    DeleteAccreditationApplicationCommandInput,
    LockOwner,
    ProgressAccreditationApplicationCommandInput,
    UpdateAccreditationApplicationCommandInput,
} from 'graphql/__generated__/graphql';
import { IAccreditationApplication } from 'interfaces/IAccreditationApplication';
import _ from 'lodash';

export const useAccreditationApplications = () => {
    const client = useApolloClient();

    const applicationsQuery = useQuery(GET_ACCREDITATION_APPLICATIONS, {
        client,
    });
    const { data: appsData, loading: appsLoading, error: appsError } = applicationsQuery;
    const {
        data: applicationConfigData,
        loading: configLoading,
        error: configError,
    } = useQuery(GET_APPLICATION_CONFIG);

    const [loading, setLoading] = useState<boolean>(true);

    const [applications, setApplications] = useState<IAccreditationApplication[]>([]);

    // Accreditation data
    const [accreditationTypes, setAccreditationTypes] = useState<AccreditationType[]>([]);
    const [applicationStatuses, setApplicationStatuses] = useState<ApplicationStatus[]>([]);

    useEffect(() => {
        if (!appsLoading && !appsError) setApplications(appsData.accreditationApplications);
    }, [appsLoading, appsError, appsData]);

    useEffect(() => {
        if (!configLoading && !configError) {
            setApplicationStatuses(applicationConfigData.applicationStatuses);
            setAccreditationTypes(applicationConfigData.activeAccreditationTypes);
        }
        if (configError) console.error(configError);
    }, [applicationConfigData, configLoading, configError]);

    useEffect(() => {
        if (!appsLoading && !configLoading) setLoading(false);
    }, [appsLoading, configLoading]);

    const createApplication = async ({
        accreditationType,
    }: IAccreditationApplication): Promise<any> => {
        try {
            const application: CreateAccreditationApplicationCommandInput = {
                accreditationTypeId: accreditationType.accreditationTypeId,
            };

            return client
                .mutate({
                    mutation: CREATE_APPLICATION,
                    variables: { application },
                })
                .then((response) => {
                    const createdApp = response.data
                        .accreditationApplicationCreate as IAccreditationApplication;
                    setApplications((prevState) => {
                        return prevState.concat([createdApp]);
                    });

                    return createdApp;
                });
        } catch (error) {
            //TODO: Handle this properly
            throw error;
        }
    };

    const submitApplication = async (
        submittedApplication: IAccreditationApplication,
        nextLockOwner: LockOwner = LockOwner.Supervisor
    ): Promise<any> => {
        const application: ProgressAccreditationApplicationCommandInput = {
            accreditationApplicationId: submittedApplication.accreditationApplicationId,
            nextLockOwner,
        };

        return client
            .mutate({
                mutation: PROGRESS_APPLICATION,
                variables: { application },
            })
            .then(() => {
                fetchApplications();
            });
    };

    const updateApplication = async (
        updatedApplication: IAccreditationApplication
    ): Promise<any> => {
        try {
            const application: UpdateAccreditationApplicationCommandInput = {
                accreditationApplicationId: updatedApplication.accreditationApplicationId,
                jsonData: updatedApplication.jsonData,
            };

            return client
                .mutate({
                    mutation: UPDATE_APPLICATION,
                    variables: { application },
                })
                .then((response) => {
                    const updatedApplication = response.data
                        .accreditationApplicationUpdate as IAccreditationApplication;
                    setApplications((prevState) =>
                        prevState.map((app) =>
                            app.accreditationApplicationId ===
                            updatedApplication.accreditationApplicationId
                                ? updatedApplication
                                : app
                        )
                    );

                    return updatedApplication;
                });
        } catch (e) {
            console.error('Error updating application', e);
            throw e;
        }
    };

    const deleteApplication = async (
        deletedApplication: IAccreditationApplication
    ): Promise<any> => {
        const application: DeleteAccreditationApplicationCommandInput = {
            accreditationApplicationId: deletedApplication.accreditationApplicationId,
        };

        try {
            return client
                .mutate({
                    mutation: DELETE_APPLICATION,
                    variables: { application },
                })
                .then((response) => {
                    setApplications((prevState) => {
                        return _.filter(
                            prevState,
                            (app) =>
                                app.accreditationApplicationId !==
                                response.data.accreditationApplicationDelete
                                    .accreditationApplicationId
                        );
                    });
                });
        } catch (e) {
            console.error('Error deleting application', e);
        }
    };

    const saveApplication = async (application: IAccreditationApplication): Promise<any> => {
        if (application.accreditationApplicationId) return updateApplication(application);

        return createApplication(application);
    };

    const fetchApplications = async (): Promise<any> => {
        return applicationsQuery
            .refetch({
                cachePolicy: 'no-cache',
            })
            .then(({ data }) => {
                setApplications(data.accreditationApplications);
                return data.accreditationApplications;
            });
    };

    return {
        models: {
            applications,
            loading,
            accreditationTypes,
            applicationStatuses,
        },
        operations: {
            deleteApplication,
            fetchApplications,
            saveApplication,
            submitApplication,
        },
    };
};
