import React, { useEffect } from 'react';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { useAccreditationApplications } from '../../../hooks/useAccreditationApplications';
import { IAccreditationApplication } from 'interfaces/IAccreditationApplication';
import IApplicationReview from 'interfaces/IApplicationReview';
import { LockOwner } from '../../../graphql/__generated__/graphql';
import { useAccreditations } from 'hooks/useAccreditations';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import AccreditationsBlockList from './AccreditationsBlockList';
import _ from 'lodash';
import { IAccreditation } from '../../../interfaces/IAccreditation';
import ApplicationsBlockList from './ApplicationsBlockList';

const ApplicationsBlock: React.FC = () => {
    const { models, operations } = useAccreditationApplications();
    const { accreditations, loading: accreditationsLoading } = useAccreditations();
    const notifications = useNotificationContext();

    const { loading, accreditationTypes, applications } = models;

    const navigate = useNavigate();

    const handleOnSelectApplication = (application: IAccreditationApplication, action: string) => {
        navigate('/accreditation/' + action, { state: { application } });
    };

    const handleOnAbandonApplication = async (application: IAccreditationApplication) => {
        operations
            .deleteApplication(application)
            .then(() => {
                notifications.success('Application abandoned successfully');
            })
            .catch((e) => {
                console.error('Something went wrong abandoning application', e);
                notifications.error('Something went wrong whilst abandoning the application.');
            });
    };

    const handleOnSubmitFinalApplication = async (application: IAccreditationApplication) => {
        operations
            .submitApplication(application, LockOwner.Emdr)
            .then(() => {
                notifications.success('Application successfully submitted to the EMDR committee.');
            })
            .catch((e) => {
                console.error('Something went wrong submitting application', e);
                notifications.error('Something went wrong when submitting your application.');
            });
    };

    const handleOnSelectReview = (
        appRev: IApplicationReview,
        application: IAccreditationApplication
    ) =>
        navigate('/accreditation/review', {
            state: {
                review: {
                    ...appRev,
                    accreditationType: application.accreditationType,
                },
            },
        });

    useEffect(() => {
        operations.fetchApplications().catch((e) => {
            console.error('Something went wrong retrieving applications', e);
            notifications.error('Something went wrong whilst retrieving your applications.');
        });
    }, []);

    if (loading || accreditationsLoading) return <LoadingSpinner />;

    const activeAccreditations = accreditations?.filter((a) => a.active);
    const expiredAccreditations = accreditations?.filter((a) => a.expired);
    const expiredAccreditationsByType = _.groupBy(
        expiredAccreditations,
        (acc) => acc.accreditationType.accreditationTypeId
    );
    const latestExpiredAccreditations: IAccreditation[] = _.map(
        expiredAccreditationsByType,
        (expired) => {
            const sorted = _.sortBy(expired, 'endDate');
            return sorted[0];
        }
    );
    const heldOrPreviousAccreditationTypes: string[] = accreditations.map(
        (a) => a.accreditationType?.accreditationTypeId
    );

    const mergedApplications: IAccreditationApplication[] = accreditationTypes.map(
        (accreditationType) => {
            return {
                ...(applications.find(
                    (app) =>
                        app.accreditationType.accreditationTypeId ===
                        accreditationType.accreditationTypeId
                ) ?? {
                    jsonData: '{}',
                    lockedBy: LockOwner.Applicant,
                }),
                accreditationType: accreditationType,
            };
        }
    );

    return (
        <>
            {latestExpiredAccreditations?.length > 0 && (
                <AccreditationsBlockList
                    accreditations={latestExpiredAccreditations}
                    applications={mergedApplications}
                    header={'Expired Accreditations'}
                    onSelectApplication={handleOnSelectApplication}
                />
            )}
            {activeAccreditations?.length > 0 && (
                <AccreditationsBlockList
                    accreditations={activeAccreditations}
                    applications={mergedApplications}
                    header={'Active Accreditations'}
                    onSelectApplication={handleOnSelectApplication}
                />
            )}

            {applications.length && (
                <>
                    <ApplicationsBlockList
                        header={'Applications in progress'}
                        applications={mergedApplications.filter(
                            (a) => !!a.accreditationApplicationId
                        )}
                        onSelectApplication={handleOnSelectApplication}
                        onSelectReview={handleOnSelectReview}
                        onAbandonApplication={handleOnAbandonApplication}
                        onSubmitFinalApplication={handleOnSubmitFinalApplication}
                    />
                </>
            )}

            <>
                <ApplicationsBlockList
                    header={
                        activeAccreditations.length
                            ? 'Add extra accreditations'
                            : 'Apply for accreditation'
                    }
                    applications={mergedApplications.filter(
                        (a) =>
                            !a.accreditationApplicationId &&
                            !heldOrPreviousAccreditationTypes.includes(
                                a.accreditationType.accreditationTypeId
                            )
                    )}
                    onSelectApplication={handleOnSelectApplication}
                    onSelectReview={handleOnSelectReview}
                    onAbandonApplication={handleOnAbandonApplication}
                    onSubmitFinalApplication={handleOnSubmitFinalApplication}
                />
            </>
        </>
    );
};

export default ApplicationsBlock;
