import IReviewFormProps from './IReviewFormProps';
import { Alert, Button, Col, FloatingLabel, Form, Row, Stack } from 'react-bootstrap';
import IClinicalContact from 'interfaces/IClinicalContact';
import React, { useEffect, useRef, useState } from 'react';
import useApplicationReview from './hooks/useApplicationReview';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useMemberProfile } from '../../../hooks/useMemberProfile';
import { useNavigate } from 'react-router-dom';
import { ReviewStatus } from '../../../graphql/__generated__/graphql';
import ClinicalContactReviewEntry from '../ClinicalContactReviewEntry';

const SecondarySupervisorReviewForm: React.FC<IReviewFormProps> = ({
    reviewSummary,
    readOnly = false,
}) => {
    const { models, operations } = useApplicationReview(reviewSummary);
    const { loading, applicationReview } = models;
    const { models: profileModels } = useMemberProfile();
    const navigate = useNavigate();
    const formRef = useRef(null);

    const [comments, setComments] = useState<string>('');
    const [approved, setApproved] = useState(true);

    useEffect(() => {
        if (!loading && applicationReview) {
            const jsonData = JSON.parse(applicationReview.jsonData || '{}');
            setComments(jsonData.comments);
            setApproved(applicationReview.reviewStatus === ReviewStatus.Complete);
        }
    }, [loading, applicationReview]);

    if (loading || profileModels.loading) return <LoadingSpinner />;

    const applicationJson = JSON.parse(
        applicationReview.accreditationApplication?.jsonData || '{}'
    );
    const clinicalContacts: IClinicalContact[] = applicationJson.clinicalContacts || [];

    if (!clinicalContacts) return <Alert variant={'danger'}>Oops, something went wrong</Alert>;

    const supervisorClinicalContacts = clinicalContacts.filter(
        (cc) => cc.supervisor.id === profileModels.profile?.id
    );

    const handleUpdate = async (approved: boolean) => {
        return operations
            .update({
                ...applicationReview,
                jsonData: approved ? '{}' : JSON.stringify({ comments }),
                reviewStatus: approved ? ReviewStatus.Complete : ReviewStatus.NeedsWork,
            })
            .then(() => {
                return true;
            })
            .catch((error) => {
                // TODO: Handle this
                console.error('An error occurred', error);
                return false;
            });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        return handleUpdate(approved).then((result) => {
            if (!result) {
                // TODO: Handle this
            } else {
                const form = formRef.current as any;
                form.submit();
            }

            return result;
        });
    };

    return (
        <>
            <h2 className="fs-1 fw-bold mb-5">
                Secondary supervisor evaluation for {reviewSummary.applicant.name}
            </h2>
            <h3>
                This review forms part of the application process for{' '}
                {reviewSummary.accreditationType.name} accreditation
            </h3>
            <h4>
                The applicant has identified you as a supervisor from the following clinical contact
                sessions.
            </h4>
            <Row>
                <Col xs={6}>
                    {supervisorClinicalContacts?.map((cc, i) => (
                        <ClinicalContactReviewEntry key={i} cc={cc} showSupervisor={false} />
                    ))}
                </Col>
            </Row>
            <Form onSubmit={(event) => handleSubmit(event)} action={'/accreditation'} ref={formRef}>
                {!readOnly && (
                    <Row>
                        <Col xs={6}>
                            <p>
                                Please confirm you supervised the applicant in the sessions listed
                                above.
                            </p>
                            <Alert variant={'warning'}>
                                If there are any inaccuracies, please provide details.
                            </Alert>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={9}>
                        {!readOnly && (
                            <>
                                <Form.Control
                                    name={'formSubmitResult'}
                                    value="evaluation"
                                    type={'hidden'}
                                />
                                <Form.Switch
                                    checked={approved}
                                    value={`${approved}`}
                                    onChange={() => setApproved(!approved)}
                                    label={'Approve'}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        {!approved && (
                            <FloatingLabel label={'Reasons for return'}>
                                <Form.Control
                                    className={`mt-2 ${readOnly && 'border-primary border-opacity-25'}`}
                                    readOnly={readOnly}
                                    plaintext={readOnly}
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    as={'textarea'}
                                    rows={10}
                                />
                            </FloatingLabel>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>{readOnly && approved && 'Approved by supervisor'}</Col>
                </Row>
                <Row className={'mt-3'}>
                    <Stack direction="horizontal" className="mx-auto" gap={4}>
                        <Button
                            variant={'outline-secondary'}
                            onClick={() => navigate('/accreditation')}
                        >
                            Go back
                        </Button>
                        {!readOnly && (
                            <>
                                {approved ? (
                                    <Button type="submit" variant={'success'}>
                                        Submit approval
                                    </Button>
                                ) : (
                                    <Button type="submit" variant={'danger'}>
                                        Return to applicant with comments
                                    </Button>
                                )}
                            </>
                        )}
                    </Stack>
                </Row>
            </Form>
        </>
    );
};

export default SecondarySupervisorReviewForm;
