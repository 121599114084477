import React from 'react';
import { Alert, Badge, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'react-bootstrap';
import IFormStep from 'interfaces/IFormStep';
import ApplicationRequirementControl from '../Components/ApplicationRequirementControl';
import _ from 'lodash';
import HelpButton from '../../../Form/HelpButton';

const YourExperience: React.FC<IFormStep> = ({
    formData,
    handleInputChange,
    requirements,
    models,
}) => {
    const experiences = formData.experiences ?? [];

    const handleChange = (name: string, value: any) =>
        handleInputChange('experiences', _.unionBy([{ name, value }], experiences, 'name'));

    const merged = _.merge(_.keyBy(requirements.experience, 'name'), _.keyBy(experiences, 'name'));
    const formInputs = _.values(merged);

    const { hasMitigation } = models.application;
    const help = requirements.config?.help && requirements.config?.help['yourExperience'];

    return (
        <>
            <Row className="col-12">
                <h3 className="fw-bold mb-3">
                    Your experience{' '}
                    {help && <HelpButton title={'Your experience'} content={help} />}
                </h3>
                <h4 className={'text-black fw-normal'}>Tell us about your experience with EMDR.</h4>
                <Col xs={9}>
                    <Alert>
                        <Alert.Heading>
                            <Badge pill>Mitigating circumstances</Badge>
                        </Alert.Heading>
                        {hasMitigation && (
                            <p>
                                You have been granted access to continue the application despite not
                                meeting the criteria, however please fill in all fields.
                            </p>
                        )}
                        {!hasMitigation && (
                            <p>
                                If you would like to discuss mitigating circumstances which have
                                prevented you from meeting the application criteria please email:
                                <a href={'mailto:accreditationadmin@emdrassociation.org.uk'}>
                                    accreditationadmin@emdrassociation.org.uk
                                </a>
                            </p>
                        )}
                    </Alert>
                </Col>
            </Row>
            {formInputs.map(({ value, ...requirement }) => {
                const { name, title, description } = requirement;
                return (
                    <Row className="col-12 mb-4" key={name}>
                        <FormGroup data-testid={`application-requirement-${name}`}>
                            <Card className="bg-transparent border-0 text-black ps-0">
                                <CardHeader className="bg-transparent border-0 h4 ps-0">
                                    <Card.Title className={'fw-bold h4'}>{title}</Card.Title>
                                    {description && (
                                        <Card.Subtitle className={'text-black'}>
                                            {description}
                                        </Card.Subtitle>
                                    )}
                                </CardHeader>
                                <CardBody className="py-0 ps-0">
                                    <ApplicationRequirementControl
                                        requirement={requirement}
                                        name={name}
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </CardBody>
                                {requirement.min && requirement.max ? (
                                    <Card.Footer className={'bg-transparent border-0 ps-0'}>
                                        Please enter a number between{' '}
                                        <span className="fw-bold">{requirement.min}</span> and{' '}
                                        <span className="fw-bold">{requirement.max}</span>.
                                    </Card.Footer>
                                ) : (
                                    requirement.min && (
                                        <Card.Footer className={'bg-transparent border-0 ps-0'}>
                                            Please enter a number greater than or equal to{' '}
                                            <span className="fw-bold">{requirement.min}</span>.
                                        </Card.Footer>
                                    )
                                )}
                            </Card>
                        </FormGroup>
                    </Row>
                );
            })}
        </>
    );
};

export default YourExperience;
