import IApplicationReviewSummary from 'interfaces/IApplicationReviewSummary';
import { useEffect, useState } from 'react';
import IApplicationReview from 'interfaces/IApplicationReview';
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_APPLICATION_REVIEW, UPDATE_APPLICATION_REVIEW } from 'graphql/member';

const useApplicationReview = (reviewSummary: IApplicationReviewSummary) => {
    const client = useApolloClient();

    const [applicationReview, setApplicationReview] = useState<IApplicationReview>(reviewSummary);

    const { data, loading, error } = useQuery(GET_APPLICATION_REVIEW, {
        variables: { reviewId: reviewSummary?.applicationReviewId },
    });

    useEffect(() => {
        if (!loading && !error) {
            setApplicationReview((prevState) => {
                return {
                    ...prevState,
                    ...data.accreditationApplicationReview,
                };
            });
        }
    }, [data, loading, error]);

    const update = async (applicationReview: IApplicationReview): Promise<any> => {
        try {
            const review = {
                applicationReviewId: applicationReview.applicationReviewId,
                jsonData: applicationReview.jsonData,
                status: applicationReview.reviewStatus,
            };

            return client.mutate({
                mutation: UPDATE_APPLICATION_REVIEW,
                variables: { review },
            });
        } catch (e) {
            console.error('Error updating review', e);
            throw e;
        }
    };

    return {
        models: {
            applicationReview,
            loading,
        },
        operations: {
            update,
        },
    };
};

export default useApplicationReview;
