import { IAccreditationApplication } from '../../../interfaces/IAccreditationApplication';
import { Col, Row } from 'react-bootstrap';
import AccreditationCard from '../AccreditationCard';
import React from 'react';
import { IAccreditation } from '../../../interfaces/IAccreditation';

interface Props {
    accreditations: IAccreditation[];
    applications: IAccreditationApplication[];
    header: string;
    onSelectApplication: (app: IAccreditationApplication, action: string) => void;
}

const ONE_DAY = 1000 * 60 * 60 * 24;

const AccreditationsBlockList: React.FC<Props> = ({
    accreditations,
    applications,
    header,
    onSelectApplication,
}) => {
    const isExpiringWithinDays = (accreditation: IAccreditation, days: number) => {
        return (
            !accreditation?.endDate ||
            new Date(accreditation.endDate).getTime() - new Date().getTime() < days * ONE_DAY
        );
    };

    return (
        <>
            <h2 className={'fs-2 mb-4'}>{header}</h2>
            {accreditations.map((accreditation, index) => {
                const application = applications.find(
                    (application) =>
                        application.accreditationType.accreditationTypeId ===
                        accreditation.accreditationType.accreditationTypeId
                );

                const isDateWithinRenewableWindow = isExpiringWithinDays(accreditation, 30);
                const isDateWithinUrgentWindow = isExpiringWithinDays(accreditation, 7);

                const renewable =
                    application &&
                    !application.accreditationApplicationId &&
                    isDateWithinRenewableWindow;

                return (
                    <Row
                        key={`accreditation-${index}`}
                        className={index === accreditations.length - 1 ? 'mb-4' : ''}
                    >
                        <Col>
                            <AccreditationCard
                                accreditation={accreditation}
                                application={application}
                                onRenewAccreditation={() => {
                                    if (renewable) {
                                        onSelectApplication(application, 'apply');
                                    }
                                }}
                                expiring={isDateWithinRenewableWindow}
                                urgent={isDateWithinUrgentWindow}
                                renewable={renewable}
                            />
                        </Col>
                    </Row>
                );
            })}
        </>
    );
};

export default AccreditationsBlockList;
