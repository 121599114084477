import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { UPDATE_PROFILE } from '../../../graphql/profile/mutations';
import { GET_PROFILE } from '../../../graphql/profile/queries';
import { UpdateProfileCommandInput } from '../../../graphql/__generated__/graphql';
import EditableAvatar from './EditableAvatar';
import { IEditableAvatarFormProps } from './IEditableAvatarFormProps';

export const EditableAvatarContainer: React.FC = () => {
    const [updateProfile] = useMutation(UPDATE_PROFILE);
    const { data, error, loading } = useQuery(GET_PROFILE);

    const handleUpdate = async (form: IEditableAvatarFormProps) => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateProfile({
                    variables: {
                        profile: {
                            id: data.profile.id,
                            avatar: form.imageData,
                        } as UpdateProfileCommandInput,
                    },
                }).then(() => console.log(data))
            );
        });
    };

    return <EditableAvatar data={data} loading={loading} error={error} onUpdate={handleUpdate} />;
};
