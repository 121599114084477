import React from 'react';
import IEvaluationFormStep from 'interfaces/IEvaluationFormStep';
import { Button, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import { IPartData, IPartDData } from 'interfaces/IApplicationReviewForm';
import HelpButton from '../../../Form/HelpButton';

const PartD: React.FC<IEvaluationFormStep> = ({
    requirements,
    formData,
    requirementsGroup,
    formRef,
    onFeedback,
    onInputChange,
    readOnly = false,
}) => {
    const partValue: IPartData<IPartDData> = formData.partD || {};
    const help = requirements.config?.help && requirements.config?.help[requirementsGroup.name];

    const handleChange = (name: string, value: string | number) => {
        onInputChange('partD', {
            ...partValue,
            value: {
                ...partValue.value,
                [name]: value,
            },
        });
    };

    const handleChangeComplete = (value: boolean) => {
        if (value) {
            const result = formRef.current.checkValidity();
            if (!result) {
                onFeedback('danger', 'Cannot mark as complete whilst form has errors');
                return;
            }
        }

        onInputChange('partD', {
            ...partValue,
            complete: value,
        });
    };

    const renderHoursInput = (name: string, labelText: string) => {
        const inputValue = partValue?.value && partValue.value[name];
        return (
            <>
                <dt key={name} className={'col-sm-2'}>
                    {!readOnly && (
                        <Form.Control
                            name={name}
                            plaintext={readOnly}
                            type={'number'}
                            readOnly={readOnly || partValue?.complete}
                            onChange={(e) => handleChange(name, e.target.value)}
                            value={inputValue || ''}
                            min={0}
                            required
                            isValid={inputValue !== undefined && (inputValue as number) >= 0}
                            isInvalid={
                                inputValue !== undefined &&
                                (inputValue === '' || (inputValue as number) < 0)
                            }
                        />
                    )}
                    {readOnly && inputValue}
                </dt>
                <dd className={'col-sm-10'}>{labelText}</dd>
            </>
        );
    };

    return (
        <Row>
            <h2>
                {requirementsGroup.pageTitle}{' '}
                {partValue?.complete && <i className={'bi bi-check2-circle me-2 fs-4'} />}
                <HelpButton title={requirementsGroup.pageTitle} content={help} />
            </h2>
            <p className={'text-black'}>{requirementsGroup.pageSubtitle}</p>
            <Row className={'text-black fw-bold'}>
                <p>How many hours of supervision have taken place?</p>
            </Row>
            <Row>
                <dl className={'row'}>
                    {requirementsGroup.requirements
                        .filter((req) => req.name.startsWith('hours'))
                        .map((req) => renderHoursInput(req.name, req.subtitle))}
                </dl>
            </Row>

            {requirementsGroup.requirements
                .filter((req) => !req.name.startsWith('hours'))
                .map((req) => {
                    const reqValue = partValue?.value && partValue?.value[req.name];
                    return (
                        <FormGroup className={'text-black'} key={req.name}>
                            <Form.Label>
                                <p className={'fw-bold'}>{req.subtitle}</p>
                                <FormControl
                                    as={'textarea'}
                                    rows={10}
                                    plaintext={readOnly}
                                    className="mt-2"
                                    required
                                    value={reqValue || ''}
                                    name={req.name}
                                    readOnly={readOnly || partValue?.complete}
                                    onChange={(e) => handleChange(req.name, e.target.value)}
                                    minLength={req?.minLength || 1}
                                    isValid={
                                        reqValue !== undefined &&
                                        (reqValue as string).trim().length >= (req?.minLength || 10)
                                    }
                                    isInvalid={
                                        reqValue !== undefined &&
                                        (reqValue as string).trim().length < (req?.minLength || 10)
                                    }
                                />
                            </Form.Label>
                        </FormGroup>
                    );
                })}

            <FormControl
                name="complete"
                type={'hidden'}
                value={partValue?.complete ? 'true' : 'false'}
            />
            {!readOnly && (
                <Button
                    className={'mt-3'}
                    variant="outline-secondary"
                    onClick={() => handleChangeComplete(!partValue?.complete)}
                >
                    Mark part D as {partValue?.complete ? 'in' : ''}complete
                </Button>
            )}
        </Row>
    );
};

export default PartD;
