import { Button, ButtonProps, Modal, ModalProps, ModalTitle, Stack } from 'react-bootstrap';
import React, { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

interface Props extends PropsWithChildren {
    modalTitle: string;
    buttonProps?: ButtonProps;
    buttonText: ReactNode | string;
    onConfirmModalBtn: (event?: SyntheticEvent) => any;
    onCancelModalBtn?: (event?: SyntheticEvent) => any;
    beforeShow?: (event?: SyntheticEvent) => void;
    confirmBtnText: string;
    confirmButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
    cancelBtnText?: string;
    modalProps?: ModalProps;
    additionalButtons?: ReactNode;
    showModal?: boolean;
}

const ModalButton: React.FC<Props> = ({
    modalTitle,
    onConfirmModalBtn,
    onCancelModalBtn,
    confirmBtnText,
    buttonText,
    buttonProps = {},
    confirmButtonProps = {},
    cancelButtonProps = {},
    cancelBtnText = 'Cancel',
    children,
    modalProps = {},
    additionalButtons,
    showModal = false,
    beforeShow,
}) => {
    const [show, setShow] = React.useState<boolean>(showModal);

    const handleButtonClick = (e: SyntheticEvent) => {
        if (beforeShow) beforeShow(e);
        if (!e.isPropagationStopped()) {
            setShow(true);
        }
    };
    const handleClose = () => setShow(false);

    return (
        <>
            <Button onClick={handleButtonClick} {...buttonProps}>
                {buttonText}
            </Button>
            <Modal show={show} onHide={handleClose} {...modalProps}>
                <Modal.Header closeButton className={'border-0 align-items-start'}>
                    <ModalTitle>{modalTitle}</ModalTitle>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Stack direction="horizontal" className="mx-auto" gap={4}>
                        <Button
                            {...{ variant: 'outline-secondary', ...cancelButtonProps }}
                            onClick={(e) => {
                                onCancelModalBtn && onCancelModalBtn(e);
                                if (!e.isPropagationStopped()) {
                                    handleClose();
                                }
                            }}
                        >
                            {cancelBtnText}
                        </Button>
                        <Button
                            {...{ variant: 'secondary', ...confirmButtonProps }}
                            onClick={(e) => {
                                onConfirmModalBtn(e);
                                if (!e.isPropagationStopped()) {
                                    handleClose();
                                }
                            }}
                        >
                            {confirmBtnText}
                        </Button>
                        {additionalButtons}
                    </Stack>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalButton;
