import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
    query GetProfile {
        profile {
            id
            membershipNumber
            name
            title
            firstName
            lastName
            email
            phone
            organisation
            website
            avatar
            profession
            professionalRegistration
            emdrLevel
            emdrChildLevel
            emdrLevelDate
            emdrChildLevelDate
            qualifications
            address {
                id
                addressLine1
                addressLine2
                city
                country
                postcode
                region
                regionId
            }
            accreditationApplications {
                accreditationType {
                    name
                    accreditationTypeId
                }
                applicationStatus
                id
            }
            activeAccreditations {
                startDate
            }
            membershipIsExpired
        }
    }
`;

export const GET_MEMBERSHIP = gql`
    query GetMembership {
        membership {
            membershipId
            memberId
            number
            type
            active
            startDate
            endDate
            name
            subscriptionId
            yearlyPrice
            initialPrice
        }
    }
`;

export const GET_MEMBER_ACCREDITATIONS = gql`
    query GetMemberAccreditations {
        memberAccreditations {
            id
            accreditationType {
                name
                description
                price
                active
                accreditationTypeId
            }
            active
            expired
            startDate
            endDate
        }
    }
`;

export const GET_DOCUMENTS = gql`
    query GetDocuments {
        documents {
            total
            totalCPD
            totalDueToExpiry
            documents {
                id
                name
                cpd
                expiries
            }
        }
    }
`;

export const GET_EVENTS = gql`
    query GetEvents {
        events {
            total
            events {
                id
                eventId
                memberId
                name
                href
                postHref
                surveyHref
                documents {
                    id
                    name
                    filename
                }
                startDate
                endDate
                expiryDate
                surveyOpened
                certificateIssued
                memberCertificateIssued
            }
        }
    }
`;
export const GET_CPD_SCORE = gql`
    query GetCpdScore {
        cpdScore {
            generalScore
            caScore
            score
            percentile
        }
    }
`;
