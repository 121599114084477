import React from 'react';
import { Alert, Button, Card, CardBody, Col, Row } from 'react-bootstrap';
import EventDetails from './EventDetails';
import { useEventCheckout } from './useEventCheckout';

const EventCheckout: React.FC = () => {
    let { models, operations } = useEventCheckout();
    let { auth, postId, quantity, confirmGuest } = models;
    let { setConfirmGuest, onLogin } = operations;

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Card border="light" className="w-50 m-auto mt-4" style={{ maxWidth: '600px' }}>
                        <CardBody style={{ textAlign: 'left' }}>
                            {!auth.isAuthenticated && !confirmGuest && (
                                <div className="w-100 text-center">
                                    <h1 className="my-4">
                                        <i className="bi bi-cart-check"></i>
                                    </h1>
                                    <Button variant="primary" size="lg" onClick={() => onLogin()}>
                                        Member's Checkout
                                    </Button>
                                    <p className="my-2">
                                        <small>or</small>
                                    </p>
                                    <Button
                                        style={{ borderRadius: '99px' }}
                                        variant="info"
                                        size="sm"
                                        onClick={() => setConfirmGuest(true)}
                                    >
                                        Checkout as Guest
                                    </Button>
                                    <Alert
                                        variant="light"
                                        className="m-auto my-4"
                                        style={{ maxWidth: '400px' }}
                                    >
                                        <small>
                                            <p>
                                                You must be logged in to access the member pricing
                                                for this event.
                                            </p>
                                            <a target={'_blank'} href="../../become-a-member">
                                                Click Here to Join
                                            </a>
                                        </small>
                                    </Alert>
                                </div>
                            )}

                            {(auth.isAuthenticated || confirmGuest) && postId && (
                                <EventDetails
                                    postId={postId}
                                    initialQuantity={parseInt(quantity ?? '1')}
                                    onLogin={onLogin}
                                    guest={confirmGuest}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default EventCheckout;
