import { GoCardlessDropinOptions, useGoCardlessDropin } from '@gocardless/react-dropin';
import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';

type PaymentButtonDropin = GoCardlessDropinOptions & {
    label: ReactNode;
    disabled?: boolean;
};

export const GoCardlessButton = (options: PaymentButtonDropin) => {
    const { open } = useGoCardlessDropin({ ...options });

    return (
        <Button disabled={options.disabled} onClick={() => open()} size="lg">
            {options.label}{' '}
            {options.environment === 'sandbox' && <code> {options.environment}</code>}
        </Button>
    );
};
