import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_PROFILE } from '../../../graphql/profile/queries';
import { UPDATE_PROFILE } from '../../../graphql/profile/mutations';
import { UpdateProfileCommandInput } from '../../../graphql/__generated__/graphql';
import { EditableName } from './EditableName';
import { IEditableNameFormProps } from './IEditableNameFormProps';

export const EditableNameContainer: React.FC = () => {
    const [updateProfile] = useMutation(UPDATE_PROFILE);
    const { data, error, loading, refetch } = useQuery(GET_PROFILE);
    const [updating, setUpdating] = useState<boolean>(false);

    const handleUpdate = async (form: IEditableNameFormProps) => {
        setUpdating(true);
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateProfile({
                    variables: {
                        profile: {
                            id: data.profile.id,
                            title: form.title,
                            firstName: form.firstName,
                            lastName: form.lastName,
                        } as UpdateProfileCommandInput,
                    },
                }).then(() => {
                    setUpdating(false);
                })
            );
        });
    };

    return (
        <EditableName
            data={data}
            loading={loading || updating}
            error={error}
            onUpdate={handleUpdate}
        />
    );
};
