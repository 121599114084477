import React, { useEffect } from 'react';
import { Alert, Row } from 'react-bootstrap';
import IFormStep from 'interfaces/IFormStep';
import SupervisorEntry from '../Components/SupervisorEntry';
import HelpButton from '../../../Form/HelpButton';
import _ from 'lodash';

const Supervisors: React.FC<IFormStep> = ({ formData, handleInputChange, requirements }) => {
    const supervisors = formData.supervisors || [];
    const help = requirements.config?.help && requirements.config?.help['supervisors'];

    const handleChange = (name: string, value: string | boolean, index: number) => {
        const updatedSupervisors = supervisors.map((s, i) => {
            if (i === index) {
                return {
                    ...s,
                    [name]: value,
                };
            }

            if (name === 'primary') {
                return {
                    ...s,
                    [name]: false, // change other supervisors to primary=false
                };
            }

            return s;
        });

        handleInputChange('supervisors', updatedSupervisors);
    };

    // Default the primary supervisor
    useEffect(() => {
        if (supervisors.length === 1) {
            handleChange('primary', true, 0);
            return;
        }
        const primarySupervisor = supervisors.find((s) => s.primary);
        if (!primarySupervisor) {
            const maxSupervisor = _.maxBy(supervisors, 'numberOfClients');
            if (maxSupervisor)
                handleChange(
                    'primary',
                    true,
                    supervisors.findIndex((s) => s === maxSupervisor)
                );
        }
    }, []);

    return (
        <>
            <Row>
                <h3 className="fw-bold mb-3">
                    Supervisor details {help && <HelpButton title={'Supervisors'} content={help} />}
                </h3>
            </Row>
            <Row>
                <h4 className={'fw-normal text-black'}>
                    Tell us more about the supervisors you listed in your clinical contact records.
                </h4>
                <h4 className={'fw-normal text-black'}>
                    We'll contact them to get some more details about your practice.
                </h4>
            </Row>
            {
                <Row className="col-8 mb-4 mt-2">
                    {!supervisors.length && (
                        <Alert variant={'warning'}>
                            This will prepopulate with the supervisors selected on the previous
                            page.
                        </Alert>
                    )}
                </Row>
            }
            {supervisors.map((supervisor, index) => (
                <Row
                    className="col-8 mb-4"
                    key={`clinicalContact[${index}]`}
                    id={`clinicalContact[${index}]`}
                >
                    <SupervisorEntry
                        index={index}
                        supervisor={supervisor}
                        onInputChangeEvent={(name, value) => handleChange(name, value, index)}
                        onCheckboxClick={(e) =>
                            handleChange(e.currentTarget.name, e.currentTarget.checked, index)
                        }
                    />
                </Row>
            ))}
        </>
    );
};

export default Supervisors;
