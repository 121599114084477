import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    GET_COMMUNICATIONPREFERENCES,
    UPDATE_MEMBERCOMMUNICATIONPREFERENCES,
} from '../../graphql/member';
import { GET_PROFILE } from '../../graphql/profile/queries';
import {
    MemberCommunicationPreferenceDto,
    MemberCommunicationPreferenceDtoInput,
    UpdateMemberCommunicationPreferencesCommandInput,
} from '../../graphql/__generated__/graphql';

export const useCommunicationPreferences = () => {
    const profileQuery = useQuery(GET_PROFILE);

    const { mId } = useParams();

    const { loading, error, data, refetch } = useQuery(GET_COMMUNICATIONPREFERENCES, {
        variables: { memberId: mId ?? profileQuery.data?.profile?.id },
    });

    const [updateMemberCommunicationPreferences] = useMutation(
        UPDATE_MEMBERCOMMUNICATIONPREFERENCES
    );

    const [show, setShow] = useState<boolean>(mId !== undefined);
    const [formData, setFormData] = useState<MemberCommunicationPreferenceDto[]>([]);

    useEffect(() => {
        if (data?.communicationPreferences) {
            setFormData(
                [...data.communicationPreferences].sort(
                    (a: MemberCommunicationPreferenceDto, b: MemberCommunicationPreferenceDto) => {
                        if ((a.name ?? '') < (b.name ?? '')) return -1;
                        if ((a.name ?? '') > (b.name ?? '')) return 1;
                        return 0;
                    }
                )
            );
        }
    }, [data]);

    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleInputChangeEvent = (index: number) => {
        setFormData((prev) =>
            prev.map((preference, i) =>
                i === index ? { ...preference, enabled: !preference.enabled } : preference
            )
        );
    };

    const onUpdate = () => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateMemberCommunicationPreferences({
                    variables: {
                        command: {
                            memberId: mId ?? profileQuery.data?.profile?.id,
                            communicationPreferences: formData.map((x) => {
                                return {
                                    enabled: x.enabled,
                                    memberId: x.memberId,
                                    name: x.name,
                                    userId: x.userId,
                                } as MemberCommunicationPreferenceDtoInput;
                            }),
                        } as UpdateMemberCommunicationPreferencesCommandInput,
                    },
                }).then(() => {
                    refetch({
                        variables: {
                            memberId: mId ?? profileQuery.data?.profile?.id,
                        },
                    });

                    handleClose();
                })
            );
        });
    };

    return {
        models: { show, formData, anonymous: mId },
        operations: {
            handleOpen,
            handleClose,
            handleInputChangeEvent,
            onUpdate,
        },
    };
};
