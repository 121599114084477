import { IAccreditationApplication } from '../../../interfaces/IAccreditationApplication';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import ApplicationBlock from '../ApplicationBlock';
import IApplicationReview from '../../../interfaces/IApplicationReview';

interface Props {
    applications: IAccreditationApplication[];
    header: string;
    onSelectApplication: (app: IAccreditationApplication, action: string) => void;
    onSelectReview: (rev: IApplicationReview, app: IAccreditationApplication) => void;
    onAbandonApplication: (app: IAccreditationApplication) => void;
    onSubmitFinalApplication: (app: IAccreditationApplication) => void;
}

const ApplicationsBlockList: React.FC<Props> = ({
    applications,
    header,
    onSelectApplication,
    onSelectReview,
    onAbandonApplication,
    onSubmitFinalApplication,
}) => {
    return (
        <>
            <h2 className={'fs-2 mb-4'}>{header}</h2>
            {applications.map((application, index) => (
                <Row
                    key={`application-${index}`}
                    className={index === applications.length - 1 ? 'mb-4' : ''}
                >
                    <Col>
                        <ApplicationBlock
                            application={application}
                            accreditationType={application.accreditationType}
                            handleOnSelectReview={(review) => onSelectReview(review, application)}
                            handleOnSelectApplication={() =>
                                onSelectApplication(application, 'apply')
                            }
                            handleOnViewApplication={() => onSelectApplication(application, 'view')}
                            handleOnAbandonApplication={() => onAbandonApplication(application)}
                            handleOnSubmitApplication={() => onSubmitFinalApplication(application)}
                        />
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default ApplicationsBlockList;
