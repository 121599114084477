import { Col, Form, Row } from 'react-bootstrap';
import { IEvaluationRequirement } from '../../../interfaces/IApplicationRequirements';
import { FeedbackLevel } from '../../../interfaces/FeedbackLevel';
import React from 'react';
import IRankingsPair from '../../../interfaces/IRankingsPair';

interface Props {
    req: IEvaluationRequirement;
    handleFeedback: (level: FeedbackLevel, msg: string) => void;
    handleChange: (name: string, value: IRankingsPair) => void;
    readOnly: boolean;
    value?: IRankingsPair;
}

interface RowProps {
    title: string;
    name: string;
    handleChange: (name: string, value: number) => void;
    value?: number;
    readOnly: boolean;
}

interface RadioButtonProps {
    radioValue: number;
    name: string;
    handleChange: (name: string, value: number) => void;
    value?: number;
    readOnly: boolean;
}

const rankings = ['Novice', 'Intermediate', 'Competent', 'Advanced'];

const rankingsClass = (value?: number) => (value ? rankings[value - 1].toLowerCase() : '');
const rankingsTitle = (value: number) => rankings[value - 1];

const RankingRadioButton: React.FC<RadioButtonProps> = ({
    handleChange,
    name,
    value,
    radioValue,
    readOnly,
}) => {
    const title = rankingsTitle(radioValue);
    return (
        <Col xs={3} onClick={() => handleChange(name, radioValue)}>
            <Form.Label
                className={'text-primary fw-bold'}
                onClick={() => handleChange(name, radioValue)}
            >
                <Form.Check
                    type={'radio'}
                    value={radioValue}
                    onChange={() => handleChange(name, radioValue)}
                    checked={value === radioValue}
                    readOnly={readOnly}
                    title={title}
                />
                {title}
            </Form.Label>
        </Col>
    );
};

const RankingSelectRow: React.FC<RowProps> = ({ title, name, handleChange, value, readOnly }) => {
    return (
        <Row className={'align-items-center rankings'}>
            <Col xs={5} className={`mt-1 mb-1`}>
                <Row>
                    <Col xs={1} className={`${rankingsClass(value)} opacity-50`}>
                        &nbsp;
                    </Col>
                    <Col>{title}</Col>
                </Row>
            </Col>
            <Col xs={7} className={'text-center mt-2 mb-2'}>
                <Form.Group
                    as={Row}
                    controlId={name}
                    className={`bg-primary-subtle align-content-center py-3`}
                >
                    {[1, 2, 3, 4].map((item) => (
                        <RankingRadioButton
                            name={name}
                            handleChange={handleChange}
                            readOnly={readOnly}
                            radioValue={item}
                            value={value}
                        />
                    ))}
                </Form.Group>
            </Col>
        </Row>
    );
};

const RankingSelect: React.FC<Props> = ({ req, readOnly, handleChange, value = {} }) => {
    const handleElementChange = (name: string, newValue: number) => {
        if (!readOnly) {
            handleChange(name, {
                ...(value || {}),
                [name]: newValue,
            });
        }
    };

    return (
        <>
            {!req.elements?.length && (
                <RankingSelectRow
                    name={req.name}
                    title={req.subtitle}
                    handleChange={handleElementChange}
                    value={value[req.name]}
                    readOnly={readOnly}
                ></RankingSelectRow>
            )}
            {req.elements?.map((el, i) => {
                const identifier = req.name + i;
                return (
                    <RankingSelectRow
                        key={el}
                        title={el}
                        name={identifier}
                        handleChange={handleElementChange}
                        value={value[identifier]}
                        readOnly={readOnly}
                    />
                );
            })}
        </>
    );
};

export default RankingSelect;
