import { useQuery } from '@apollo/client';
import { PropsWithChildren, useEffect } from 'react';
import { GET_PROFILE } from './graphql/profile/queries';

interface RouteWrapperProps extends PropsWithChildren {
    handleMembershipExpiryStatus: (expired: boolean) => void;
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
    handleMembershipExpiryStatus,
    children,
}) => {
    const { data, loading } = useQuery(GET_PROFILE, {
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (data && data.profile) handleMembershipExpiryStatus(data.profile.membershipIsExpired);
    }, [data, loading]);

    return <>{children}</>;
};
