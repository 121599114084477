import React from 'react';
import { Spinner } from 'react-bootstrap';
import MultiSelectList from '../MultiSelectList';
import { LanguagesSelectProps } from './LanguagesSelectProps';
import { useLanguages } from './useLanguagess';

const LanguagesSelect: React.FC<LanguagesSelectProps> = (props: LanguagesSelectProps) => {
    const { name, value, onChange } = props;
    const { models } = useLanguages();
    const { languages } = models;

    return languages ? (
        <MultiSelectList
            name={name}
            availableOptions={
                languages?.map((language) => {
                    return { id: language, label: language };
                }) as any
            }
            value={
                value.map((value) => {
                    return { id: value, label: value };
                }) as any
            }
            onChange={(value) =>
                onChange(languages.filter((x) => value.map((x) => x.id).includes(x)))
            }
        />
    ) : (
        <Spinner size="sm" />
    );
};

export default LanguagesSelect;
