import React from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import { Block } from '../Blocks/Block';
import { useCommunicationPreferences } from './useCommunicationPreferences';

const CommunicationPreferences: React.FC = () => {
    const { models, operations } = useCommunicationPreferences();

    const { show, formData, anonymous } = models;
    const { handleOpen, handleClose, handleInputChangeEvent, onUpdate } = operations;

    return (
        <>
            {anonymous ? (
                show ? (
                    <></>
                ) : (
                    <p className="m-4">
                        Your communication preferences have been updated. <br />
                        You can now close this page.
                    </p>
                )
            ) : (
                <Button
                    title="Update Preferences"
                    variant="secondary"
                    size="sm"
                    onClick={handleOpen}
                >
                    <i className="bi bi-gear"></i>
                </Button>
            )}

            <Modal show={show} onHide={anonymous ? undefined : handleClose}>
                <Modal.Body>
                    <Block title="Communication Preferences">
                        <p className="mb-4">
                            Please select the types of communication you would like to recieve.
                        </p>

                        <Row>
                            {formData.length > 0 &&
                                formData.map((preference, index) => (
                                    <Form.Group as={Col} key={index} sm={12} className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id={`switch-${preference.name}`}
                                            label={preference.name}
                                            checked={preference.enabled}
                                            onChange={() => handleInputChangeEvent(index)}
                                        />
                                    </Form.Group>
                                ))}

                            <Form.Group as={Col} sm={12} className="mb-3">
                                <Form.Check
                                    type="switch"
                                    label={'Essential Communications'}
                                    checked={true}
                                    readOnly={true}
                                    disabled={true}
                                />
                                <Form.Text>
                                    We occasionally need to send essential communications regarding
                                    your account and membership status. While these notifications
                                    cannot be disabled, please be assured that we will only contact
                                    you when it is necessary to provide critical information or
                                    updates.{' '}
                                </Form.Text>
                            </Form.Group>
                        </Row>
                    </Block>
                </Modal.Body>

                <Modal.Footer className="justify-content-center">
                    <ButtonGroup>
                        {!anonymous && (
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        )}

                        <Button
                            variant="primary"
                            onClick={() => onUpdate().then(() => handleClose())}
                        >
                            Update
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CommunicationPreferences;
