import { Button } from 'react-bootstrap';
import { MemberInvoice } from '../../../graphql/__generated__/graphql';
import { useInvoiceHistory } from '../../../hooks/useInvoiceHistory';

export interface IInvoiceProps {
    invoice: MemberInvoice;
}

export const Invoice: React.FC<IInvoiceProps> = ({ invoice }) => {
    const { operations } = useInvoiceHistory();

    return (
        <>
            {invoice.line?.map(
                (x) =>
                    x.description /* filters out the sub total lines in the invoice data */ && (
                        <tr>
                            <td>{invoice.docNumber}</td>
                            <td>{new Date(invoice.dueDate).toLocaleDateString()}</td>
                            <td>{x.description}</td>
                            <td>&pound;{x.amount}</td>
                            <td>
                                <Button
                                    variant="link"
                                    onClick={() => operations.downloadInvoice(invoice.id)}
                                    title="Download Invoice"
                                >
                                    <i className="bi bi-cloud-arrow-down"></i>
                                </Button>
                            </td>
                        </tr>
                    )
            )}
        </>
    );
};
