import React, { PropsWithChildren, ReactElement } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveProgressButton from '../../Accreditation/ApplicationForm/Components/SaveProgressButton';
import { FeedbackLevel } from '../../../interfaces/FeedbackLevel';
import FormStepperSteps from './FormStepperSteps';

export interface IStepConfig {
    title: string;
    helpId?: string;
    content?: React.FC<any>;
    onChangeStep?: (
        form: React.MutableRefObject<null>,
        callback: (success: boolean, msg?: string[]) => void
    ) => void;
    showBreadcrumb?: boolean;
    showNextButton?: boolean;
    showSaveProgressButton?: boolean;
}

interface FormStepperProps extends PropsWithChildren {
    stepNumber: number;
    formRef: React.MutableRefObject<null>;
    handleNextStep: (back?: boolean) => void;
    handleCancel: () => void;
    handleFeedback?: (level: FeedbackLevel, message?: string[]) => void;
    handleSubmit: (e: React.FormEvent) => void;
    submitted: boolean;
    submitting: boolean;
    allowSubmit: boolean;
    steps: IStepConfig[];
    renderStep?: (comp: React.FC<any>, stepNumber: number) => ReactElement;
    handleSaveProgress?: () => undefined | Promise<any>;
    handleSetStep?: (step: number) => void;
    submitText?: string;
    readOnly?: boolean;
    allowSkipStep?: boolean;
    defaultShowSaveProgressButton?: boolean;
    nextButtonText?: string;
    className?: string;
}

const FormStepper: React.FC<FormStepperProps> = ({
    stepNumber,
    steps,
    children,
    formRef,
    handleNextStep,
    handleCancel,
    handleSubmit,
    handleFeedback,
    handleSaveProgress,
    handleSetStep,
    submitted,
    submitting,
    allowSubmit,
    renderStep,
    submitText = 'Join Now',
    readOnly = false,
    allowSkipStep = false,
    defaultShowSaveProgressButton = true,
    nextButtonText = 'Next',
    className = '',
}) => {
    const navigate = useNavigate();

    const {
        content: CurrentStep,
        showNextButton = true,
        showSaveProgressButton = defaultShowSaveProgressButton,
        onChangeStep = (_form, callback) => callback(true),
    } = steps[stepNumber - 1];

    const handleChangeStep = (nextStep: number) => {
        if (!allowSkipStep || !handleSetStep) return;
        if (!onChangeStep) handleSetStep(nextStep + 1);

        return onChangeStep(formRef, (success, message) => {
            if (nextStep < stepNumber || success) return handleSetStep(nextStep + 1);

            if (!success) {
                handleFeedback && handleFeedback('danger', message);
            }
        });
    };

    const progress = (e: React.FormEvent, back?: boolean) => {
        scrollToTop();
        back
            ? stepNumber === 1
                ? handleCancel()
                : handleNextStep(back)
            : allowSubmit
              ? handleSubmit(e)
              : handleNextStep();
    };

    const scrollToTop = () => {
        const c = formRef.current as any;
        if (c) c.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Row className="my-4 ">
                <Col
                    className={`d-flex justify-content-center align-items-center position-relative ${className}`}
                >
                    <FormStepperSteps
                        allowSkipStep={allowSkipStep}
                        steps={steps}
                        handleChangeStep={handleChangeStep}
                        stepNumber={stepNumber}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={defaultShowSaveProgressButton ? 9 : 12}>
                    {renderStep && CurrentStep && renderStep(CurrentStep, stepNumber)}
                    {children}
                </Col>
                {defaultShowSaveProgressButton && (
                    <Col xs={3} className={'d-inline-block'}>
                        {showSaveProgressButton && !readOnly && (
                            <SaveProgressButton onClick={handleSaveProgress} />
                        )}
                    </Col>
                )}
            </Row>
            <Row className={defaultShowSaveProgressButton ? 'col-9 mt-5' : 'col-auto mt-4'}>
                <ButtonToolbar
                    className={
                        defaultShowSaveProgressButton ? '' : 'w-100 d-flex justify-content-between'
                    }
                >
                    {submitted ? (
                        <Button
                            className="my-4 mx-auto"
                            size="lg"
                            style={{ minWidth: '170px' }}
                            onClick={() => navigate('/dashboard')}
                        >
                            Sign In
                        </Button>
                    ) : (
                        <>
                            <ButtonGroup size="lg">
                                <Button
                                    variant="outline-secondary"
                                    onClick={(e) =>
                                        stepNumber === 1 ? handleCancel() : progress(e, true)
                                    }
                                    disabled={submitted}
                                    className={'fs-6 fw-bold'}
                                >
                                    <i className="bi bi-chevron-left pe-2"></i>
                                    {stepNumber === 1 ? 'Cancel' : 'Previous'}
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup size="lg" className={'ms-4'}>
                                {showNextButton && stepNumber < steps.length && !allowSubmit && (
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => handleChangeStep(stepNumber)}
                                        className={'fs-6'}
                                    >
                                        <>
                                            {nextButtonText}
                                            <i className="bi bi-chevron-right ps-2"></i>
                                        </>
                                    </Button>
                                )}
                                {allowSubmit && !submitting && (
                                    <Button
                                        onClick={(e) => progress(e)}
                                        style={{ borderRadius: 0 }}
                                    >
                                        <>
                                            {' '}
                                            {submitText}
                                            <i className="bi bi-check ps-2"></i>
                                        </>
                                    </Button>
                                )}
                                {allowSubmit && submitting && (
                                    <Button disabled style={{ borderRadius: 0 }}>
                                        <>
                                            {' '}
                                            {submitText}
                                            <Spinner
                                                style={{ marginLeft: '1em' }}
                                                animation="border"
                                                role="status"
                                                size="sm"
                                            >
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </>
                                    </Button>
                                )}
                            </ButtonGroup>
                        </>
                    )}
                </ButtonToolbar>
            </Row>
        </>
    );
};

export default FormStepper;
