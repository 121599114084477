import React from 'react';
import { Col, Row } from 'react-bootstrap';
import IFormStep from 'interfaces/IFormStep';
import { DocumentsBlock } from 'components/Documents/DocumentsBlock';
import { useDocuments } from 'hooks/useDocuments';
import ApplicationRequirementsSelectBlock from '../Components/ApplicationRequirementsSelectBlock';
import { LoadingSpinner } from 'components/LoadingSpinner';
import HelpButton from '../../../Form/HelpButton';
import { MemberDocument } from '../../../../graphql/__generated__/graphql';

const Documents: React.FC<IFormStep> = ({ formData, handleInputChange, requirements, models }) => {
    const { models: documentModels } = useDocuments();
    const documents = formData.documents || [];
    const help = requirements.config?.help && requirements.config?.help['documents'];

    const handleDocumentChange = (name: string, value: string) => {
        const appDocument = {
            name,
            value,
        };

        const existingDocument = documents.find((doc) => doc.name === name);

        const updatedDocuments = existingDocument
            ? documents.map((s) => {
                  if (s.name === name) {
                      return appDocument;
                  }
                  return s;
              })
            : documents.concat([appDocument]);

        handleInputChange('documents', updatedDocuments);
    };

    if (documentModels.loading) {
        return <LoadingSpinner />;
    }

    const toDocumentOption = (doc: MemberDocument) => ({
        id: doc.id as string,
        name: doc.name as string,
    });

    const documentOptionsData = documentModels.documents?.map(toDocumentOption);

    return (
        <>
            <Row>
                <h3 className="fw-bold mb-3">
                    Documents {help && <HelpButton title={'Documents'} content={help} />}
                </h3>
            </Row>
            <Row className={'mb-3 text-black'}>
                <h4>
                    Attach documents that confirm your eligibility to be an accredited{' '}
                    {models.application.accreditationType.name}.
                </h4>
                <h4>Any new documents can be uploaded using the form on the right.</h4>
            </Row>
            <Row className={'mb-3'}>
                <Col xs={7}>
                    {requirements.documents?.map((requirementsGroup, index) => (
                        <Row className={'mb-4'} key={`documents-block-${index}`}>
                            <ApplicationRequirementsSelectBlock
                                {...requirementsGroup}
                                handleChange={handleDocumentChange}
                                selectOptions={documentOptionsData}
                                applicationData={documents}
                            />
                        </Row>
                    ))}
                </Col>
                <Col xs={5}>
                    <DocumentsBlock alwaysShowUpload />
                </Col>
            </Row>
        </>
    );
};

export default Documents;
