import { useQuery } from '@apollo/client';
import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { GET_MEMBER_ACCREDITATIONS } from '../../../graphql/profile/queries';
import { LoadingError } from '../../LoadingError';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Block } from '../Block';
import { MemberAccreditation } from '../../../graphql/__generated__/graphql';
import _ from 'lodash';

interface IAccreditationBlock {
    displayAction: boolean;
}

const AccreditationBlock: React.FC<IAccreditationBlock> = ({ displayAction }) => {
    const { data, error, loading } = useQuery(GET_MEMBER_ACCREDITATIONS);

    const accreditations = data?.memberAccreditations || [];
    const sortedAccreditations = _.sortBy(
        accreditations,
        (a: MemberAccreditation) => a?.accreditationType?.name
    );

    return (
        <Block
            title="Accreditation"
            link={
                displayAction
                    ? {
                          title: 'Manage your accreditations',
                          href: '/accreditation',
                          variant: 'secondary',
                      }
                    : undefined
            }
        >
            {loading && <LoadingSpinner />}
            {error && <LoadingError />}
            {!loading && !error && (
                <>
                    {!sortedAccreditations.length && (
                        <Row>
                            <Badge bg="primary" className="membership-block-membership">
                                No accreditations
                            </Badge>
                        </Row>
                    )}
                    {sortedAccreditations?.map((accreditation: MemberAccreditation) => (
                        <>
                            <Row>
                                <Col>
                                    <div className="membership-block-membership">
                                        {accreditation.accreditationType?.name}
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ marginBottom: '0.5em' }}>
                                        {accreditation?.active ? (
                                            <Badge
                                                bg="primary"
                                                className="membership-block-membership"
                                            >
                                                Active
                                            </Badge>
                                        ) : (
                                            <Badge
                                                bg="danger"
                                                className="membership-block-membership"
                                            >
                                                Expired
                                            </Badge>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <div className="membership-block-membershiprenews pb-2 mb-2 border-bottom">
                                Renewal date: {new Date(accreditation?.endDate).toDateString()}
                            </div>
                        </>
                    ))}
                </>
            )}
        </Block>
    );
};

export default AccreditationBlock;
