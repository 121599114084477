import { useMutation } from '@apollo/client';
import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import { IAccreditationApplication } from '../interfaces/IAccreditationApplication';
import {
    AccreditationApplicationPaymentCommandInput,
    AccreditationApplicationPaymentCompleteCommandInput,
} from '../graphql/__generated__/graphql';
import {
    ACCREDITATION_APPLICATION_PAYMENT,
    ACCREDITATION_APPLICATION_PAYMENT_RESPONSE,
} from '../graphql/payment/mutations';

export const useAccreditationPayments = () => {
    const [accreditationPayment] = useMutation(ACCREDITATION_APPLICATION_PAYMENT);
    const [accreditationPaymentResponseMutation] = useMutation(
        ACCREDITATION_APPLICATION_PAYMENT_RESPONSE
    );

    const initialiseApplicationPayment = async (application: IAccreditationApplication) => {
        return accreditationPayment({
            variables: {
                command: {
                    redirectUri: '/accreditation/apply',
                    applicationId: application.accreditationApplicationId,
                } as AccreditationApplicationPaymentCommandInput,
            },
        })
            .then(async (response: any) => {
                return response.data.accreditationApplicationPayment;
            })
            .catch((e: any) => {
                console.log('Failed to initialise.', e);
            });
    };

    const finaliseApplicationPayment = (
        application: IAccreditationApplication,
        billingRequest: BillingRequest,
        billingRequestFlow: BillingRequestFlow
    ) => {
        console.log('billing', billingRequest);

        return accreditationPaymentResponseMutation({
            variables: {
                command: {
                    applicationId: application.accreditationApplicationId,
                    created: billingRequest.created_at,
                    id: billingRequest.id,
                    status: billingRequest.status,
                    billingRequestJson: JSON.stringify(billingRequest),
                    billingRequestFlowJson: JSON.stringify(billingRequestFlow),
                } as AccreditationApplicationPaymentCompleteCommandInput,
            },
        });
    };

    return {
        operations: {
            initialiseApplicationPayment,
            finaliseApplicationPayment,
        },
    };
};
