import IReviewFormProps from './IReviewFormProps';
import React, { useRef } from 'react';
import { Card, CardBody, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FormStepper from '../../Form/FormStepper/FormStepper';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useSupervisorReviewFormComponent } from './hooks/useSupervisorReviewFormComponent';
import IEvaluationFormStep from 'interfaces/IEvaluationFormStep';
import { FeedbackLevel } from '../../../interfaces/FeedbackLevel';
import { useNotificationContext } from '../../../contexts/NotificationContext';

const PrimarySupervisorReviewForm: React.FC<IReviewFormProps> = ({ reviewSummary, readOnly }) => {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const notificationContext = useNotificationContext();

    const { models, operations } = useSupervisorReviewFormComponent(reviewSummary, readOnly);
    const { formData, updating, currentStep, requirements } = models;
    const {
        handleNextStep,
        handleInputChangeEvent,
        handleInputChange,
        handleSaveProgress,
        handleSetStep,
        handleSubmit,
    } = operations;

    if (!requirements?.evaluation || models.loading) return <LoadingSpinner />;

    const openApplicationInNewTab = () => {
        localStorage.setItem(
            'application',
            JSON.stringify(models.applicationReview.accreditationApplication)
        );
        window.open('/accreditation/view', '_blank', 'noreferrer');
    };

    const handleFormFeedback = (level: FeedbackLevel, message?: string[] | string) => {
        notificationContext.notify(level, message);
    };

    const renderStep = (Step: React.FC<IEvaluationFormStep>, stepNumber: number) => (
        <Step
            formData={formData}
            formRef={formRef}
            models={models}
            onFeedback={handleFormFeedback}
            handleInputChangeEvent={handleInputChangeEvent}
            handleNext={handleNextStep}
            handleSubmit={handleSubmit}
            onInputChange={handleInputChange}
            requirementsGroup={requirements.evaluation[stepNumber - 1]}
            requirements={requirements}
            readOnly={readOnly}
        />
    );

    const stepsConfig = models.stepsConfig.slice(
        0,
        readOnly ? models.stepsConfig.length - 1 : models.stepsConfig.length
    );

    return (
        <>
            <h2 className="fs-1 fw-bold mb-5">
                Supervisor evaluation for {reviewSummary.applicant.name}
            </h2>
            <h4>
                View {reviewSummary.applicant.name}'s application &nbsp;
                <a
                    href={'/accreditation/view'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    onClick={(e) => {
                        e.preventDefault();
                        openApplicationInNewTab();
                    }}
                >
                    here
                </a>
                &nbsp;(opens in a new tab)
            </h4>
            {models.requirements?.config?.isAlgorithm && (
                <p>
                    Current score {formData.score} (min. {models.requirements?.config?.minScore})
                </p>
            )}
            {formData.comments && (
                <Row>
                    <Card className="col-auto">
                        <CardBody>
                            <Card.Title>Supervisor comments</Card.Title>
                            {formData.comments}
                        </CardBody>
                    </Card>
                </Row>
            )}
            <Row>
                <Form
                    noValidate
                    ref={formRef}
                    onSubmit={() => operations.handleSubmit()}
                    action={'/accreditation'}
                >
                    <FormStepper
                        stepNumber={currentStep}
                        renderStep={renderStep}
                        steps={stepsConfig}
                        formRef={formRef}
                        allowSkipStep={true}
                        handleFeedback={handleFormFeedback}
                        handleSetStep={handleSetStep}
                        handleNextStep={handleNextStep}
                        handleCancel={() => {
                            navigate('/accreditation');
                        }}
                        handleSubmit={() => {
                            console.log('submit');
                        }}
                        handleSaveProgress={handleSaveProgress}
                        submitted={false}
                        submitting={false}
                        allowSubmit={false}
                        readOnly={readOnly}
                        submitText={'Submit and pay'}
                        nextButtonText={'Save and continue'}
                        className={'overflow-x-auto'}
                    ></FormStepper>
                    <Form.Control name={'formSubmitResult'} value="evaluation" type={'hidden'} />
                </Form>
            </Row>
            {updating && (
                <Row>
                    <LoadingSpinner />
                </Row>
            )}
        </>
    );
};

export default PrimarySupervisorReviewForm;
