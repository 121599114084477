import React, { useEffect, useRef, useState } from 'react';
import { Card, FormCheck } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { IMultiSelectOption } from '../../interfaces/IMultiSelectOption';

interface MultiSelectProps {
    name?: string;
    required?: boolean;
    availableOptions?: IMultiSelectOption[] | undefined;
    value?: IMultiSelectOption[] | undefined;
    onChange?: (value: IMultiSelectOption[]) => void;
}

const MultiSelectList: React.FC<MultiSelectProps> = ({
    name,
    required,
    availableOptions,
    value,
    onChange,
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<IMultiSelectOption[]>(value ?? []);
    const [options, setOptions] = useState<IMultiSelectOption[]>(availableOptions ?? []);

    const listRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleOptionClick = (option: IMultiSelectOption) => {
        if (!selectedOptions.find((selectedOption) => selectedOption.id === option.id)) {
            setSelectedOptions(() => {
                var next = [...selectedOptions, option];
                if (onChange) onChange(next);
                return next;
            });
            setInputValue('');
        } else handleRemoveOption(option);
    };

    const handleRemoveOption = (option: IMultiSelectOption) => {
        const updatedOptions = selectedOptions.filter(
            (selectedOption) => selectedOption.id !== option.id
        );
        setSelectedOptions(() => {
            if (onChange) onChange(updatedOptions);
            return updatedOptions;
        });
    };

    useEffect(() => {
        if (availableOptions !== options) setOptions(availableOptions ?? []);

        if (value !== selectedOptions) {
            setSelectedOptions(value ?? []);
        }
    }, [availableOptions, value, selectedOptions]);

    return (
        <div>
            <style type="text/css">
                {`
                    .multiselect-list-group {
                        max-height: 100px;
                        overflow-y:auto;
                        text-align:left;
                    }
                `}
            </style>

            <div>
                <Card className="p-2 mb-2">
                    <ListGroup className="multiselect-list-group-noscroll" ref={listRef}>
                        {options
                            .sort((a, b) => {
                                const isOtherA = a.label.startsWith('Other');
                                const isOtherB = b.label.startsWith('Other');

                                if (isOtherA && !isOtherB) return 1;
                                if (!isOtherA && isOtherB) return -1;
                                return a.label.localeCompare(b.label);
                            })
                            .map((option) => (
                                <ListGroup.Item
                                    key={option.id}
                                    onClick={() => handleOptionClick(option)}
                                    style={{
                                        cursor: 'pointer',
                                        border: 'none',
                                    }}
                                    active={selectedOptions.includes(option)}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <FormCheck
                                            readOnly
                                            checked={selectedOptions.some(
                                                (x) => x.id === option.id
                                            )}
                                            className="me-2"
                                        />
                                        {option.label}
                                    </div>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </Card>
            </div>
        </div>
    );
};

export default MultiSelectList;
