import IApplicationReviewForm, {
    IPartData,
    IPartDData,
} from '../../../../interfaces/IApplicationReviewForm';
import IApplicationRequirements, {
    IEvaluationRequirement,
} from '../../../../interfaces/IApplicationRequirements';
import _ from 'lodash';
import ISimpleNameValuePair from '../../../../interfaces/ISimpleNameValuePair';
import IRankingsPair from '../../../../interfaces/IRankingsPair';

export const useReviewFormValidator = (applicationRequirements: IApplicationRequirements) => {
    const reviewReqs = applicationRequirements.evaluation;

    const keyedReqs = _.keyBy(reviewReqs, 'name');

    const sum = (total: number, current: number) => total + current;
    const countMinimumEntries = (reqs: IEvaluationRequirement[]) => {
        return reqs.map((i) => (i.elements ? i.elements.length : 1)).reduce(sum);
    };

    const numChoicesRequiredA = countMinimumEntries(keyedReqs['partA'].requirements);
    const numChoicesRequiredB = countMinimumEntries(keyedReqs['partB'].requirements);
    const numChoicesRequiredC = countMinimumEntries(keyedReqs['partC'].requirements);

    const validatePartD = (partD: IPartData<IPartDData>) => {
        const errors: string[] = [];
        const requirementPartToCheck = keyedReqs['partD'];

        if (!partD || !partD.value) {
            errors.push('Part D is missing one or more values');
            return errors;
        }

        for (const r of requirementPartToCheck.requirements) {
            const matchingValue = partD.value[r.name];
            if (matchingValue === undefined || matchingValue === '') {
                if (r.required) errors.push('Part D - ' + (r.title || r.subtitle) + ' is required');
                continue;
            }

            if (r.type !== 'number') {
                if ((matchingValue as string).length < (r.minLength || 0))
                    errors.push(
                        'Part D: ' + r.title + ' does not meet the minimum required length'
                    );
                if ((matchingValue as string).length > (r.maxLength || Number.MAX_SAFE_INTEGER))
                    errors.push('Part D: ' + r.title + ' exceeds the maximum permitted length');
            }
        }

        return errors;
    };

    const validateAlgorithmForm = (reviewForm: IApplicationReviewForm) => {
        const errors: string[] = [];

        const partACount = numChoicesRequiredA - Object.keys(reviewForm.partA.value).length;
        if (partACount > 0) errors.push('Part A is missing ' + partACount + ' selection(s)');
        const partBCount =
            numChoicesRequiredB -
            reviewForm.partB.map((i) => Object.keys(i.value.value).length).reduce(sum);
        if (partBCount > 0) errors.push('Part B is missing ' + partBCount + ' selection(s)');
        const partCCount = numChoicesRequiredC - Object.keys(reviewForm.partC.value).length;
        if (partCCount > 0) errors.push('Part C is missing ' + partCCount + ' selection(s)');
        const partDErrors = validatePartD(reviewForm.partD);
        partDErrors.forEach((err) => errors.push(err));

        const reviewScore = reviewForm.score || 0;
        const minScore = applicationRequirements.config?.minScore || 0;
        if (reviewScore < minScore)
            errors.push(
                'Current total of ' +
                    reviewScore +
                    ' falls below the minimum required score of ' +
                    minScore
            );

        return errors;
    };

    const validateRequirement = (
        req: IEvaluationRequirement,
        data?: IPartData<string | IRankingsPair>,
        partTitle?: string
    ) => {
        const errors: string[] = [];

        const errorPrefix = partTitle + (req.title && ' - ' + req.title);

        if (!data || !data.value) {
            if (req.required || req.minLength) errors.push(errorPrefix + ' is required');
            return errors;
        }

        const input = data.value as string;

        if (req.minLength && req.minLength > input.length)
            errors.push(errorPrefix + ' does not meet the minimum required length');
        if (req.maxLength && req.maxLength < input.length)
            errors.push(errorPrefix + ' exceeds the maximum permitted length');

        return errors;
    };

    const validateComplexPart = (input: ISimpleNameValuePair[], reqName: string) => {
        const errors: string[] = [];
        const requirementPartToCheck = keyedReqs[reqName];

        if (!input || !input.length) {
            errors.push(requirementPartToCheck.title + ' is required');
            return errors;
        }

        for (const r of requirementPartToCheck.requirements) {
            const matchingInput = input.find((a) => a.name === r.name);
            errors.push(
                ...validateRequirement(r, matchingInput?.value, requirementPartToCheck.title)
            );
        }

        return errors;
    };

    const validateSimplePart = (data: IPartData<string | IRankingsPair>, reqName: string) => {
        const requirementPartToCheck = keyedReqs[reqName];
        const reqs = requirementPartToCheck.requirements[0];

        return validateRequirement(reqs, data, requirementPartToCheck.title);
    };

    const validateStandardForm = (reviewForm: IApplicationReviewForm) => {
        const partAErrors = validateSimplePart(reviewForm.partA, 'partA');
        const partBErrors = validateComplexPart(reviewForm.partB, 'partB');
        const partCErrors = validateSimplePart(reviewForm.partC, 'partC');
        const partDErrors = validatePartD(reviewForm.partD);

        return [...partAErrors, ...partBErrors, ...partCErrors, ...partDErrors];
    };

    const validateForm = (reviewForm: IApplicationReviewForm) => {
        return applicationRequirements.config?.isAlgorithm
            ? validateAlgorithmForm(reviewForm)
            : validateStandardForm(reviewForm);
    };

    return {
        validateForm,
    };
};
