import React, { useEffect } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_APPLICATION_REVIEWS } from 'graphql/member';
import IApplicationReviewSummary from 'interfaces/IApplicationReviewSummary';
import { ReviewerRole } from '../../../graphql/__generated__/graphql';

const useApplicationReviews = () => {
    const [applicationReviews, setApplicationReviews] =
        React.useState<IApplicationReviewSummary[]>();

    const { data, loading, error, refetch } = useQuery(GET_APPLICATION_REVIEWS);

    useEffect(() => {
        if (!loading && !error) {
            setApplicationReviews(data.accreditationApplicationReviewSummaries);
        }
    }, [data, loading, error]);

    return {
        models: {
            applicationReviews,
            loading,
        },
        operations: {
            refetch,
        },
    };
};

const ReviewsBlock: React.FC = () => {
    const { models, operations } = useApplicationReviews();
    const { applicationReviews } = models;

    const navigate = useNavigate();

    const handleOnSelectReview = (reviewSummary: IApplicationReviewSummary) => {
        navigate('/accreditation/review', { state: { review: reviewSummary } });
    };

    useEffect(() => {
        operations.refetch({
            fetchPolicy: 'no-cache',
        });
    }, []);

    return applicationReviews?.length ? (
        <>
            <Row className={'col-9'}>
                <h2 className="fs-3 fw-bold mb-3">Reviews</h2>
            </Row>
            {applicationReviews?.map((review) => (
                <Row key={review.applicationReviewId} className={'mb-3'}>
                    <ReviewBlock
                        review={review}
                        handleOnSelectReview={() => handleOnSelectReview(review)}
                    />
                </Row>
            ))}
        </>
    ) : (
        <></>
    );
};

interface ReviewBlockProps {
    review: IApplicationReviewSummary;
    handleOnSelectReview: (review: IApplicationReviewSummary) => void;
}

const ReviewBlock: React.FC<ReviewBlockProps> = ({ review, handleOnSelectReview }) => {
    const isPrimary = review.reviewerRole === ReviewerRole.PrimarySupervisor;
    return (
        <Card className={`border-0 w-75 pb-2`}>
            <CardHeader className={`bg-transparent border-0 p-0`}>
                <div className={'end-pill-wrapper'}>
                    <Badge pill bg={'review-status'} className={'floating-pill'}>
                        Requested: {new Date(review.requestedDate).toLocaleDateString()}
                    </Badge>
                </div>
            </CardHeader>
            <CardBody className={'mb-0'}>
                <dl className={'d-flex mb-0'}>
                    <dt>{isPrimary ? 'Evaluation' : 'Sessions confirmation'}:</dt>
                    <dd className={'ms-1'}>{review.applicant.name}</dd>
                </dl>
                <Button
                    variant="outline-info"
                    className={'text-primary'}
                    onClick={() => handleOnSelectReview(review)}
                >
                    {isPrimary ? 'Complete evaluation' : 'Verify sessions'}
                </Button>
            </CardBody>
        </Card>
    );
};

export default ReviewsBlock;
