import { gql } from '@apollo/client';

export const MEMBERSHIP_PAYMENT = gql`
    mutation membershipPayment($command: MembershipPaymentCommandInput!) {
        membershipPayment(command: $command) {
            flow_id
            environment
        }
    }
`;

export const MEMBERSHIP_PAYMENT_RESPONSE = gql`
    mutation membershipPaymentResponse($command: MembershipPaymentCompleteCommandInput!) {
        membershipPaymentResponse(command: $command) {
            id
            success
            error
        }
    }
`;

export const MEMBERSHIP_PAYMENT_BYPASS = gql`
    mutation membershipPaymentBypass($command: MembershipPaymentBypassCommandInput!) {
        membershipPaymentBypass(command: $command) {
            id
            success
            error
        }
    }
`;

export const MEMBERSHIP_PAYMENT_CANCEL = gql`
    mutation membershipPaymentCancel($command: MembershipPaymentCancelCommandInput!) {
        membershipPaymentCancel(command: $command) {
            id
            success
            error
        }
    }
`;

export const ACCREDITATION_APPLICATION_PAYMENT = gql`
    mutation accreditationApplicationPayment(
        $command: AccreditationApplicationPaymentCommandInput!
    ) {
        accreditationApplicationPayment(command: $command) {
            flow_id
            environment
        }
    }
`;

export const ACCREDITATION_APPLICATION_PAYMENT_RESPONSE = gql`
    mutation accreditationApplicationPaymentResponse(
        $command: AccreditationApplicationPaymentCompleteCommandInput!
    ) {
        accreditationApplicationPaymentResponse(command: $command) {
            id
            success
            error
        }
    }
`;

//export const EVENT_PAYMENT = gql`
//mutation eventPayment($command:EventPaymentCommandInput!) {
//        eventPayment(command:$command){
//               flow_id
//    }
//}
//`;

//export const EVENT_PAYMENT_RESPONSE = gql`
//mutation eventPaymentResponse($command:EventPaymentCompleteCommandInput!) {
//        eventPaymentResponse(command:$command){
//               id
//               success
//               error
//    }
//}
//`;
