import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { EVENT_SURVEY_OPENED } from '../../graphql/event/mutations';
import { GET_EVENT_VIDEOS } from '../../graphql/event/queries';
import { UpdateEventParticipantCommandInput } from '../../graphql/__generated__/graphql';
import { MemberEventModalProps } from './MemberEventModalProps';

export const useMemberEvent = ({ ...props }) => {
    var { memberEvent } = { ...props } as MemberEventModalProps;

    const [show, setShow] = useState(false);

    const { data, error, loading, refetch } = useQuery(GET_EVENT_VIDEOS, {
        variables: {
            id: memberEvent?.eventId,
        },
    });

    const [videoSrc, setVideoSrc] = useState<string>();
    const [surveySrc, setSurveySrc] = useState<string>();
    const [expired, setExpired] = useState<boolean>();

    const [surveyOpened] = useMutation(EVENT_SURVEY_OPENED);

    const handleSurveyOpened = () => {
        surveyOpened({
            variables: {
                command: {
                    eventId: memberEvent?.eventId,
                    eventParticipantId: memberEvent?.id,
                } as UpdateEventParticipantCommandInput,
            },
        }).then((response) => {
            handleClose();
        });
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (memberEvent && expired === undefined)
            setExpired(
                memberEvent?.expiryDate ? new Date() > new Date(memberEvent.expiryDate) : false
            );
    }, [memberEvent]);

    useEffect(() => {
        if (!loading && !error) {
            if (data.eventVideos.length > 0) {
                let url =
                    process.env.REACT_APP_VIDEOAPI_ENDPOINT + '/' + data.eventVideos[0].videoId;
                console.log('setting video url', url);
                setVideoSrc(url);
            }
        }
    }, [loading, data]);

    return {
        models: {
            show,
            loading,
            videoSrc,
            surveySrc,
            expired,
        },
        operations: {
            handleShow,
            handleClose,
            handleSurveyOpened,
        },
    };
};
