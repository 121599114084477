import React, { createRef, RefObject, useEffect } from 'react';
import { IStepConfig } from './FormStepper';

interface Props {
    allowSkipStep: boolean;
    steps: IStepConfig[];
    handleChangeStep: (nextStep: number) => undefined | any;
    stepNumber: number;
}

const FormStepperIcons: React.FC<Props> = ({
    stepNumber,
    steps,
    handleChangeStep,
    allowSkipStep,
}) => {
    const [elRefs, setElRefs] = React.useState<RefObject<any>[]>([]);

    React.useEffect(() => {
        setElRefs(steps.map((_, i) => createRef()));
    }, []);

    useEffect(() => {
        scrollIntoView(stepNumber);
    }, [stepNumber]);

    const scrollIntoView = (index: number) => {
        const elRef = elRefs[index];
        const targetItem = elRef?.current;
        if (targetItem)
            targetItem.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
            });
    };

    return (
        <>
            {steps.map((step, i) => {
                const stepTitle = step.title;
                return (
                    <div
                        key={i}
                        className="d-flex flex-column justify-content-between align-items-between text-center mx-3"
                        title={allowSkipStep ? 'Skip to ' + stepTitle : ''}
                        style={{ cursor: allowSkipStep ? 'pointer' : 'default' }}
                        onClick={() => handleChangeStep(i)}
                        ref={elRefs[i]}
                    >
                        <div
                            className="d-flex flex-column align-items-center"
                            style={{ width: '140px' }}
                        >
                            <i
                                className={`bi bi-${stepNumber >= i + 2 ? 'check' : i + 1}-circle-fill me-2 fs-5 text-${stepNumber >= i + 1 ? 'secondary' : 'primary'} `}
                                style={{ opacity: stepNumber >= i + 1 ? 1 : 0.8 }}
                            ></i>

                            {i !== steps.length - 1 && (
                                <div
                                    className={`bg-${stepNumber >= i + 2 ? 'secondary' : 'primary'}`}
                                    style={{
                                        position: 'absolute',
                                        marginTop: 12,
                                        marginRight: -165,
                                        width: '140px',
                                        height: '5px',
                                        borderRadius: '50px',
                                        opacity: stepNumber >= i + 2 ? 1 : 0.8,
                                    }}
                                ></div>
                            )}
                        </div>

                        <p style={stepNumber === i + 1 ? { fontWeight: 'bold' } : undefined}>
                            <span>{stepTitle}</span>
                        </p>
                    </div>
                );
            })}
        </>
    );
};

export default FormStepperIcons;
