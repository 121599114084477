import React, { useEffect } from 'react';
import { Alert, Card, CardBody, Col, Row } from 'react-bootstrap';
import IFormStep from 'interfaces/IFormStep';
import _ from 'lodash';
import ApplicationReviewBlock from '../Components/ApplicationReviewBlock';
import AccreditationPaymentModal from '../../AccreditationPayment/AccreditationPaymentModal';
import ModalButton from '../../../ModalButton';
import { useApplicationValidator } from '../hooks/useApplicationValidator';
import HelpButton from '../../../Form/HelpButton';

const agreementsCheckboxes = [
    {
        name: 'agreement-1',
        label: "Once you've submitted your form you'll be unable to make any changes.",
    },
    { name: 'agreement-2', label: 'Your accreditation fee is non-refundable' },
    {
        name: 'agreement-3',
        label: "Your accreditation will last for 5 years, after which, to continue being EMDR Europe accredited, you'll need to apply for re-accreditation.",
    },
];

const SubmitAndPay: React.FC<IFormStep> = ({
    formData,
    handleFeedback,
    handleInputChange,
    models,
    requirements,
    handleSubmitApplication,
    handleApplicationUpdate,
}) => {
    const [formErrors, setFormErrors] = React.useState<string[]>([]);

    const formValidator = useApplicationValidator(models.application.accreditationType);

    useEffect(() => {
        setFormErrors(formValidator.validateForm(formData, models.application.hasMitigation));
    }, [formData]);

    const { application } = models;

    const handleAgreementChange = (name: string, value: boolean) =>
        handleInputChange('agreements', _.unionBy([{ name, value }], formData.agreements, 'name'));

    const handlePaymentSuccess = () => {
        handleFeedback('info', 'Payment was successful');
        handleApplicationUpdate();
    };

    const merged = _.merge(
        _.keyBy(agreementsCheckboxes, 'name'),
        _.keyBy(formData.agreements, 'name')
    );
    const formCheckboxes = _.values(merged);
    const help = requirements.config?.help && requirements.config?.help['submitAndPay'];

    return (
        <>
            <Row className={'col-8'}>
                <h3 className={'fw-bold mb-3'}>
                    Review {help && <HelpButton title={'Submit & pay'} content={help} />}
                </h3>
                <h4>
                    Please take a moment to review your application carefully before proceeding to
                    ensure all information is accurate and complete.
                </h4>
            </Row>
            {formErrors.length > 0 && (
                <Row>
                    <Col xs={'auto'}>
                        <Alert variant={'warning'}>
                            <Alert.Heading>
                                Application contains errors. These must be fixed before you can
                                continue.
                            </Alert.Heading>
                            <ul>
                                {formErrors.map((err, i) => (
                                    <li key={`error-${i}`}>{err}</li>
                                ))}
                            </ul>
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className={'col-10 mb-3'}>
                <Card className={'border-0 bg-transparent'}>
                    <CardBody>
                        <ApplicationReviewBlock {...models} requirements={requirements} />
                    </CardBody>
                </Card>
            </Row>
            <Row className={'mt-2'}>
                <Col xs={6}>
                    {!formErrors.length &&
                        (application.isPaid ? (
                            <ModalButton
                                modalTitle={'Confirm application submission'}
                                confirmBtnText={'Submit for review'}
                                onConfirmModalBtn={handleSubmitApplication}
                                buttonText={'Submit for review'}
                            >
                                <p>
                                    By clicking below you are confirming that your application is
                                    accurate and ready for evaluation.
                                </p>
                            </ModalButton>
                        ) : (
                            <AccreditationPaymentModal
                                handleAgreementChange={handleAgreementChange}
                                agreementChecks={formCheckboxes}
                                application={application}
                                onPaymentSuccess={handlePaymentSuccess}
                            />
                        ))}
                </Col>
            </Row>
        </>
    );
};

export default SubmitAndPay;
