import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDocuments } from '../../hooks/useDocuments';
import CPDBlock from '../Blocks/CPDBlock';
import { LoadingSpinner } from '../LoadingSpinner';
import { DocumentList } from './DocumentList';
import { DocumentsBlock } from './DocumentsBlock';
import './index.scss';

export const DocumentsContainer: React.FC = ({}) => {
    const { models } = useDocuments();

    return (
        <>
            <Row>
                <Col md="8">
                    <DocumentsBlock />
                </Col>
                <Col md="4">
                    <CPDBlock />
                </Col>
            </Row>
            <Row>
                <Col>
                    {models.loading && (
                        <div>
                            <LoadingSpinner></LoadingSpinner>
                        </div>
                    )}
                    {!models.loading && <DocumentList></DocumentList>}
                </Col>
            </Row>
        </>
    );
};
