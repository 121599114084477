import React, { ReactNode, useEffect } from 'react';
import { Button, Col, Nav, Navbar, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import imgSrc from '../../logo.png';
import CommunicationPreferences from '../CommunicationPreferences';
import ExpiredMembershipPopup from '../ExpiredMembershipPopup';

interface AuthorisedLayoutProps {
    children: ReactNode;
}

const AuthorisedLayout: React.FC<AuthorisedLayoutProps> = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();

    const getNavItemClass = (name: string) => {
        return location.pathname.includes(name)
            ? 'memberNavLink memberNavLinkSelected'
            : 'memberNavLink';
    };

    const getSelectedLinkStyle = () => {
        return { backgroundColor: 'rgba(238, 239, 242, 1)' };
    };

    useEffect(() => {}, [location.pathname]);

    return (
        <>
            <ExpiredMembershipPopup />
            <Row style={{ height: '100vh' }}>
                <Col md={12} lg={2} style={{ backgroundColor: 'white' }} className={'z-3'}>
                    <Navbar expand="lg" className="flex-lg-column align-items-start">
                        <Navbar.Brand className="p-3">
                            <Nav.Link href="/dashboard">
                                <img src={imgSrc} width="100" alt="Brand Logo" />
                            </Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            className="mr-1 my-auto"
                            style={{ border: 'none' }}
                        />
                        <Navbar.Collapse className="w-100">
                            <Nav defaultActiveKey="/home" as="ul" className="flex-column">
                                <Nav.Item as="li" className={getNavItemClass('/dashboard')}>
                                    <Nav.Link
                                        href="/dashboard"
                                        className="text-primary p-2"
                                        style={
                                            location.pathname.includes('/dashboard')
                                                ? getSelectedLinkStyle()
                                                : undefined
                                        }
                                    >
                                        <i className="bi bi-columns-gap mx-1"></i>
                                        <b className="mx-2">Dashboard</b>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className={getNavItemClass('/membership')}>
                                    <Nav.Link
                                        href="/membership"
                                        className="text-primary p-2"
                                        style={
                                            location.pathname.includes('/membership')
                                                ? getSelectedLinkStyle()
                                                : undefined
                                        }
                                    >
                                        <i className="bi bi-person-square mx-1"></i>
                                        <b className="mx-2">Membership</b>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className={getNavItemClass('/accreditation')}>
                                    <Nav.Link
                                        href="/accreditation"
                                        className="text-primary p-2"
                                        style={
                                            location.pathname.includes('/accreditation')
                                                ? getSelectedLinkStyle()
                                                : undefined
                                        }
                                    >
                                        <i className="bi bi-award mx-1"></i>
                                        <b className="mx-2">Accreditation</b>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className={getNavItemClass('/documents')}>
                                    <Nav.Link
                                        href="/documents"
                                        className="text-primary p-2"
                                        style={
                                            location.pathname.includes('/documents')
                                                ? getSelectedLinkStyle()
                                                : undefined
                                        }
                                    >
                                        <i className="bi bi-files-alt mx-1"></i>
                                        <b className="mx-2">Documents</b>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" className={getNavItemClass('/events')}>
                                    <Nav.Link
                                        href="/events"
                                        className="text-primary p-2"
                                        style={
                                            location.pathname.includes('/events')
                                                ? getSelectedLinkStyle()
                                                : undefined
                                        }
                                    >
                                        <i className="bi bi-calendar-event mx-1"></i>
                                        <b className="mx-2">Events</b>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item as="li" className="memberNavLink">
                                    <Nav.Link
                                        href="https://staging.emdrassociation.org.uk/members/"
                                        className="text-primary p-2"
                                    >
                                        <i className="bi bi-diagram-2 mx-1"></i>
                                        <b className="mx-2">Member Resources</b>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item as="li" className="memberNavLink">
                                    <Nav.Link
                                        href="https://emdrassociation.org.uk/members/"
                                        className="text-primary p-2"
                                    >
                                        <i className="bi bi-diagram-2 mx-1"></i>
                                        <b className="mx-2">Member Resources (LIVE)</b>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item as="li" className={'d-block d-lg-none memberNavLink'}>
                                    <Nav.Link
                                        onClick={() => auth.signoutRedirect()}
                                        className="text-primary p-2"
                                    >
                                        <i className="bi bi-box-arrow-right mx-1"></i>
                                        <b className="mx-2">Sign Out</b>{' '}
                                        <span>({auth.user?.profile.sub})</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
                <Col className="p-4 h-100" style={{ overflowY: 'auto' }}>
                    <Row className="mb-2 d-none d-lg-flex " role="banner">
                        <Col style={{ margin: 'auto', textAlign: 'right' }}>
                            <span>{auth.user?.profile.sub}</span>
                        </Col>
                        <Col xs="auto">
                            <CommunicationPreferences />
                            <Button
                                variant="primary"
                                onClick={() => auth.signoutRedirect()}
                                className="ms-2"
                            >
                                <small>Sign out</small>{' '}
                                <i className="ms-2 bi bi-box-arrow-right"></i>{' '}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center" role="main">
                        {children}
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default AuthorisedLayout;
