import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { MemberEventDocumentsModalProps } from './MemberEventDocumentsModalProps';
import { useMemberEventDocuments } from './useMemberEventDocuments';

const MemberEventDocumentsModal: React.FC<MemberEventDocumentsModalProps> = ({ ...props }) => {
    const { memberEventDocuments, eventId } = {
        ...props,
    } as MemberEventDocumentsModalProps;
    const { models, operations } = useMemberEventDocuments({ ...props });
    const { show } = models;
    const { handleShow, handleClose } = operations;

    return (
        <>
            <Button variant="outline-light" onClick={handleShow}>
                Documents
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {memberEventDocuments?.map((document, index) => (
                                <tr key={document.id}>
                                    <td>{document.name}</td>
                                    <td align="right">
                                        <div className="icon">
                                            <a
                                                onClick={() =>
                                                    operations.handleDownload(document.id, eventId)
                                                }
                                                href="#"
                                                title="Download Document"
                                            >
                                                <i className="bi bi-cloud-arrow-down"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MemberEventDocumentsModal;
