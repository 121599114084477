import IClinicalContact from '../../interfaces/IClinicalContact';
import React from 'react';
import { Card, CardBody } from 'react-bootstrap';

interface CCRProps {
    cc: IClinicalContact;
    showSupervisor?: boolean;
}

const toDL = (key: string, value?: any) => {
    return (
        <>
            <dt className={'col-5'}>{key}:</dt>
            <dd className={'col-7'}>{value || '-'}</dd>
        </>
    );
};

const ClinicalContactReviewEntry: React.FC<CCRProps> = ({ cc, showSupervisor = true }) => {
    return (
        <Card className={'bg-light-subtle border-1 mb-2 p-0 rounded-top-4'}>
            <Card.Header>{cc.initials}</Card.Header>
            <CardBody>
                <dl className={'row'}>
                    {cc.age && toDL('Age', cc.age)}
                    {toDL(
                        'Start date',
                        cc.startDate && new Date(cc.startDate).toLocaleDateString()
                    )}
                    {toDL('No. of Sessions', cc.numberOfSessions)}
                    {showSupervisor && toDL('Supervisor', cc.supervisor.name)}
                    {toDL('Presenting problem', cc.presentingProblem)}
                    {toDL('Setting', cc.treatmentSetting)}
                </dl>
            </CardBody>
        </Card>
    );
};

export default ClinicalContactReviewEntry;
