import React, { useEffect, useState } from 'react';
import IJoinForm from '../../interfaces/IJoinForm';
import { useDataContext } from '../../contexts/DataContext';
import { useNavigate } from 'react-router-dom';
import { ProfessionalRegistrationItem, ProfessionItem } from '../../graphql/__generated__/graphql';
import { IMembershipJoinForm } from './IMembershipJoinForm';
import { cloneDeep } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_EMDR_TRAINING_LEVELS } from '../../graphql/member';

export const useMembershipJoinFormComponent = (props: IMembershipJoinForm) => {
    const dataContext = useDataContext();

    const { data } = useQuery(GET_EMDR_TRAINING_LEVELS);

    const [formData, setFormData] = useState<IJoinForm>({
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        country: '',
        qualifications: '',
        childLevel: '',
        childLevelDate: '',
        professions: [],
        emdrLevel: '',
        emdrLevelDate: '',
        languages: '',
        registrations: [],
        region: '',
        membershipType: '',
        declarationAgreed: false,
        declarationAgreed2: false,
        advertisingOptIn: false,
        newsOptIn: false,
        username: '',
        password: '',
        confirmPassword: '',
    });
    const [validated, setValidated] = useState(false);
    const [showAddressInfo, setShowAddressInfo] = useState(true);
    const [showProfessionInfo, setShowProfessionInfo] = useState(false);
    const [showMembershipInfo, setShowMembershipInfo] = useState(false);
    const [showComplete, setShowComplete] = useState(false);

    const [currentStep, setCurrentStep] = useState(1);
    const [submitError, setSubmitError] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const navigate = useNavigate();

    const handleInputChangeEvent = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ): void => {
        const { name, value } = event.target;
        handleInputChange(name, value);
    };
    const handleCheckboxChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = event.target;
        handleInputChange(name, checked);
    };
    const handleInputChange = (
        name: string,
        value: string | string[] | boolean | ProfessionItem[] | ProfessionalRegistrationItem[]
    ): void => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setValidated(false);
    };
    const handleCancel = () => {
        navigate('/');
    };
    const handleNextStep = (back?: boolean) => {
        setValidated(false);

        if (showAddressInfo) {
            if (
                formData.title.length > 0 &&
                formData.firstName.length > 0 &&
                formData.lastName.length > 0 &&
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email) &&
                formData.phone.length > 0 &&
                formData.address1.length > 0 &&
                formData.city.length > 0 &&
                formData.postcode.length > 0 &&
                formData.country.length > 0 &&
                formData.region.length > 0
            ) {
                setShowAddressInfo(false);
                setShowProfessionInfo(true);
                setCurrentStep(2);
            } else {
                console.log('showaddress failed validation', formData);

                setValidated(true);
            }

            return;
        }

        if (showProfessionInfo) {
            if (back) {
                setShowProfessionInfo(false);
                setShowAddressInfo(true);
                setCurrentStep(1);
            } else {
                if (
                    /*formData.qualifications.length > 0 &&*/
                    formData.professions.length > 0 &&
                    formData.emdrLevel.length > 0 &&
                    formData.emdrLevelDate.length > 0 &&
                    formData.registrations.length > 0
                ) {
                    setShowProfessionInfo(false);
                    setShowMembershipInfo(true);
                    setCurrentStep(3);
                } else {
                    console.log('showProfessionInfo failed validation', formData);

                    setValidated(true);
                }
            }
            return;
        }

        if (showMembershipInfo && back) {
            setShowMembershipInfo(false);
            setShowProfessionInfo(true);
            setCurrentStep(2);
        }
    };

    const handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(false);

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{12,}$/;

        if (
            formData.username !== '' &&
            formData.confirmPassword !== '' &&
            formData.password === formData.confirmPassword &&
            formData.membershipType !== '' &&
            formData.declarationAgreed &&
            passwordRegex.test(formData.password)
        ) {
            setSubmitInProgress(true);

            dataContext
                .createMember(formData)
                .then((response) => {
                    console.log(response);
                    setSubmitError(false);
                    setShowMembershipInfo(false);
                    setShowComplete(true);
                })
                .catch((error) => {
                    console.error('there was an error creating the member', error);
                    setSubmitInProgress(false);
                    setSubmitError(true);
                })
                .finally(() => {});
        } else setValidated(true);
    };

    useEffect(() => {
        setValidated(false);
    }, []);

    useEffect(() => {
        if (formData.email.length > 0 && formData.username !== formData.email) {
            var fd = cloneDeep(formData);
            fd.username = fd.email;
            setFormData(fd);
        }
    }, [formData.email]);

    return {
        models: {
            formData,
            emdrTrainingLevels: data?.emdrTrainingLevels,
            validated,
            currentStep,
            showAddressInfo,
            showMembershipInfo,
            showProfessionInfo,
            showComplete,
            submitError,
            submitInProgress,
        },
        operations: {
            handleInputChangeEvent,
            handleInputChange,
            handleCheckboxChangeEvent,
            handleNextStep,
            handleCancel,
            handleSubmit,
        },
    };
};
