import { useState } from 'react';
import { useDocuments } from '../../hooks/useDocuments';

export const useMemberEventDocuments = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const { operations } = useDocuments();

    const handleDownload = (documentId: string, eventId: string) => {
        operations.downloadDocument(documentId, eventId);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return {
        models: {
            show,
        },
        operations: {
            handleShow,
            handleClose,
            handleDownload,
        },
    };
};
