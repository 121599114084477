import React, { useEffect, useState } from 'react';
import { IEditableContactFormProps } from './IEditableContactFormProps';

export const useEditableContact = ({ data }: any) => {
    const [formData, setFormData] = useState<IEditableContactFormProps>();
    const [show, setShow] = useState(false);

    const handleInputChangeEvent = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ): void => {
        const { name, value } = event.target;
        handleInputChange(name, value);
    };
    const handleInputChange = (name: string, value: string | string[] | boolean): void => {
        console.log(name, value);

        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleOpen = (e: React.MouseEvent) => {
        if ((e.currentTarget as HTMLElement).tagName.toLowerCase() !== 'a') setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (data?.profile && !formData) {
            setFormData({
                organisation: data?.profile?.organisation ?? '',
                email: data?.profile?.email ?? '',
                phone: data?.profile?.phone ?? '',
                website: data?.profile?.website ?? '',
                address1: data?.profile?.address?.addressLine1 ?? '',
                address2: data?.profile?.address?.addressLine2 ?? '',
                city: data?.profile?.address?.city ?? '',
                postcode: data?.profile?.address?.postcode ?? '',
                country: data?.profile?.address?.country ?? '',
                region: data?.profile?.address?.regionId ?? '',
            });
        }
    }, [data, formData]);

    return {
        models: { show, formData },
        operations: { handleInputChangeEvent, handleOpen, handleClose },
    };
};
