import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Stack } from 'react-bootstrap';
import eventOverlay from '../../eventoverlay.webp';
import eventOverlayPrevious from '../../eventoverlay_previous.webp';

import { MemberEvent } from '../../graphql/__generated__/graphql';
import { useEvents } from '../../hooks/useEvents';
import CPDBlock from '../Blocks/CPDBlock';
import MemberEventDocumentsModal from '../MemberEventDocumentsModal';
import MemberEventModal from '../MemberEventModal';

export const EventList: React.FC<{ compact?: boolean }> = ({ compact }) => {
    const { models } = useEvents();

    const eventLink = process.env.REACT_APP_EVENTLINK as string;

    return (
        <>
            <Row>
                {!compact && (
                    <Col sm={12} md={6} lg={6} xl={6} xxl={4} className="py-2">
                        <CPDBlock />
                    </Col>
                )}

                {models.events?.map((event, index) => {
                    return (
                        <Col
                            sm={12}
                            md={compact ? 12 : 6}
                            lg={compact ? 12 : 6}
                            xl={compact ? 6 : 6}
                            xxl={compact ? 6 : 4}
                            key={event.id}
                            className="py-2"
                        >
                            <EventItem event={event} />
                        </Col>
                    );
                })}

                {models.events?.length === 0 && (
                    <Card border="light" className="mb-3">
                        {compact && (
                            <CardHeader style={{ border: 'none', background: 'white' }}>
                                <div className="medium">Events</div>
                            </CardHeader>
                        )}
                        <CardBody
                            style={compact ? { maxHeight: '50vh', overflowY: 'scroll' } : undefined}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '2em',
                                    marginBottom: '2em',
                                }}
                            >
                                You do not have any booked events.
                                <div
                                    style={{
                                        marginTop: '2em',
                                        marginBottom: '2em',
                                    }}
                                >
                                    <Button
                                        target="_blank"
                                        href={eventLink}
                                        className="small"
                                        variant="outline-primary"
                                    >
                                        Find CPD Events
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </Row>
        </>
    );
};

export interface IEventProps {
    event: MemberEvent;
}

export const EventItem: React.FC<IEventProps> = ({ event }) => {
    const previousEvent = new Date(event.endDate ? event.endDate : event.startDate) < new Date();

    return (
        <Card className="bg-dark text-white" style={{ borderRadius: 0 }}>
            <Card.Img src={previousEvent ? eventOverlayPrevious : eventOverlay} alt="Card image" />
            <Card.ImgOverlay style={{ overflowY: 'auto' }}>
                <Card.Body className={'d-flex flex-column justify-content-between h-100'}>
                    <h2>
                        <a
                            title="View event details"
                            href={event.postHref ?? undefined}
                            rel="noreferrer"
                            target="_blank"
                            className="text-decoration-none text-light"
                        >
                            {event.name}
                            <small>
                                <i className="ms-2 bi bi-box-arrow-up-right"></i>
                            </small>
                        </a>
                    </h2>

                    <div className=" d-flex flex-column mt-3">
                        <p>
                            <i className="bi bi-calendar me-2"></i>
                            {new Date(event.startDate).toLocaleDateString('en-gb')}
                            <i className="bi bi-clock ms-4 me-2"></i>
                            {new Date(event.startDate).toLocaleTimeString('en-gb', {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                        </p>

                        <div className="d-flex justify-content-between align-items-center mt-1">
                            <Stack direction="horizontal" gap={2}>
                                {new Date(event.startDate) > new Date() ? (
                                    <Button
                                        variant="primary"
                                        as={'a'}
                                        href={event.href ?? undefined}
                                        target="_blank"
                                    >
                                        Join Now
                                    </Button>
                                ) : (
                                    <MemberEventModal memberEvent={event} />
                                )}
                                {event.documents && (
                                    <MemberEventDocumentsModal
                                        memberEventDocuments={event.documents}
                                        eventId={event.eventId}
                                    />
                                )}
                            </Stack>
                        </div>
                    </div>
                </Card.Body>
            </Card.ImgOverlay>
        </Card>
    );
};
