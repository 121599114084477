import { useQuery } from '@apollo/client';
import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { GET_MEMBERSHIPTYPES } from '../../../graphql/member';
import { MembershipTypeSelectProps } from './MembershipTypeSelectProps';
import { useMembershipTypes } from './useMembershipTypes';

const MembershipTypeSelect: React.FC<MembershipTypeSelectProps> = ({ name, value, onChange }) => {
    const { data } = useQuery(GET_MEMBERSHIPTYPES);
    const { models } = useMembershipTypes({ data });
    const { membershipTypes } = models;

    return membershipTypes ? (
        <Form.Select as="select" name={name} value={value} onChange={(e) => onChange(e)} required>
            <option value="">Select membership type</option>
            {membershipTypes.map((option, index) => (
                <option key={index} value={option.membershipTypeId}>
                    {option.name}
                </option>
            ))}
        </Form.Select>
    ) : (
        <Spinner size="sm" />
    );
};

export default MembershipTypeSelect;
