import { Accordion } from 'react-bootstrap';
import React from 'react';
import { FeedbackLevel } from '../../../../interfaces/FeedbackLevel';
import EvaluationInput from '../EvaluationInput';
import { IEvaluationRequirement } from '../../../../interfaces/IApplicationRequirements';
import IRankingsPair from '../../../../interfaces/IRankingsPair';

interface Props {
    value?: any;
    onChange: (name: string, value: any) => void;
    readOnly?: boolean;
    handleFeedback: (level: FeedbackLevel, message: string) => void;
    minLength?: number;
    maxLength?: number;
    req: IEvaluationRequirement;
}

const AccordionItem: React.FC<Props> = ({
    value,
    onChange,
    readOnly = false,
    handleFeedback,
    minLength = 1,
    maxLength,
    req,
}) => {
    const sectionComplete = value?.complete || false;
    const sectionValue = value?.value;

    const isAlgorithm = req.type?.toLowerCase() === 'algorithm';

    const handleChange = (change: { value: string | IRankingsPair; complete: boolean }) => {
        onChange(req.name, change);
    };

    return (
        <Accordion.Item eventKey={req.name} className={'bg-transparent'}>
            <Accordion.Header>
                {req.title} {sectionComplete && <i className={'bi bi-check2-circle ms-2 fs-4'} />}
            </Accordion.Header>
            <Accordion.Body className={'text-black'}>
                <EvaluationInput
                    isAlgorithm={isAlgorithm}
                    readOnly={readOnly}
                    req={req}
                    inputValue={sectionValue}
                    inputComplete={sectionComplete}
                    onChange={handleChange}
                    onFeedback={handleFeedback}
                    minLength={minLength}
                    maxLength={maxLength}
                />
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default AccordionItem;
