import MembershipRenewalModal from '../../MembershipPayment/MembershipRenewalModal';

export const MembershipBlockCTA = () => {
    return (
        <div className="cpd_block_calltoaction_container" style={{ color: 'black' }}>
            <MembershipRenewalModal />
        </div>
    );
};

export default MembershipBlockCTA;
