import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../../LoadingSpinner';
import SecondarySupervisorReviewForm from './SecondarySupervisorReviewForm';
import PrimarySupervisorReviewForm from './PrimarySupervisorReviewForm';
import IApplicationReviewSummary from 'interfaces/IApplicationReviewSummary';
import { Alert } from 'react-bootstrap';
import { ReviewStatus } from '../../../graphql/__generated__/graphql';

const ApplicationReviewForm: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const reviewSummary: IApplicationReviewSummary = location.state?.review;

    if (!reviewSummary) {
        navigate('/accreditation');
        return <LoadingSpinner />;
    }

    const formParams = {
        reviewSummary,
        readOnly:
            reviewSummary.reviewStatus === ReviewStatus.Complete ||
            reviewSummary.reviewStatus === ReviewStatus.NeedsWork,
    };

    switch (reviewSummary?.reviewerRole) {
        case 'PRIMARY_SUPERVISOR':
            return <PrimarySupervisorReviewForm {...formParams} />;
        case 'SECONDARY_SUPERVISOR':
            return <SecondarySupervisorReviewForm {...formParams} />;
        default:
            return <Alert variant={'danger'}>Something went wrong</Alert>;
    }
};

export default ApplicationReviewForm;
