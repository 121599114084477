import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import React from 'react';

const AlgorithmLegend = () => (
    <Card className="p-0">
        <CardHeader>
            <Card.Title>Legend</Card.Title>
        </CardHeader>
        <CardBody className={'fw-medium rankings'}>
            <Row className={'novice'}>
                <Col xs={6}>Novice</Col>
                <Col xs={6}>Score 1 point</Col>
            </Row>
            <Row className={'intermediate'}>
                <Col xs={6}>Intermediate</Col>
                <Col xs={6}>Score 2 points</Col>
            </Row>
            <Row className={'competent'}>
                <Col xs={6}>Competent</Col>
                <Col xs={6}>Score 3 points</Col>
            </Row>
            <Row className={'advanced'}>
                <Col xs={6}>Advanced</Col>
                <Col xs={6}>Score 4 points</Col>
            </Row>
            <Row>Only one rating can be selected per row. Scores are automatically calculated.</Row>
        </CardBody>
    </Card>
);

export default AlgorithmLegend;
