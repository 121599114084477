import React, { useState } from 'react';
import { Button, ButtonGroup, Image, Modal } from 'react-bootstrap';
import { LoadingError } from '../../LoadingError';
import { LoadingSpinner } from '../../LoadingSpinner';
import { IEditableAvatarProps } from './IEditableAvatarProps';
import { useEditableAvatar } from './useEditableAvatar';

export const EditableAvatar: React.FC<IEditableAvatarProps> = ({
    data,
    loading,
    error,
    onUpdate,
}) => {
    const { models, operations } = useEditableAvatar({ data });
    const { imageData } = models;
    const [hover, setHover] = useState(false);

    const avatarStyle: any = {
        width: '145px',
        height: '145px',
        opacity: hover ? 0.8 : 1,
        objectFit: 'cover',
    };

    const noAvatarStyle: any = {
        ...avatarStyle,
        width: '145px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#efefef',
        borderRadius: '9999px',
    };

    const NoImage = () => {
        return (
            <div
                style={noAvatarStyle}
                className="d-flex flex-column justify-content-center align-items-center"
            >
                <h6>
                    <i className="bi bi-camera"></i>
                </h6>
                <small>Upload Photo</small>
            </div>
        );
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            {error && <LoadingError />}
            {!loading && !error && (
                <>
                    <div
                        style={{
                            ...avatarStyle,
                            position: 'relative',
                            maxWidth: '10em',
                            width: '100%',
                            margin: '0 auto',
                            cursor: 'pointer',
                            display: 'block',
                            marginBottom: '.5em',
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={() => operations.handleOpen()}
                        title="click to update your avatar photo"
                        data-testid="member-avatar"
                    >
                        {data?.profile?.avatar ? (
                            <Image src={data?.profile?.avatar} roundedCircle style={avatarStyle} />
                        ) : (
                            <NoImage />
                        )}

                        <i
                            className={`bi bi-pencil ms-1`}
                            style={{
                                position: 'absolute',
                                top: hover ? '50%' : '90%',
                                left: hover ? '50%' : '90%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                                fontSize: hover ? '2em' : '0em',
                                display: 'flex',
                            }}
                        ></i>
                    </div>
                    <Modal show={models.show} onHide={operations.handleClose} size="sm">
                        <Modal.Body className="text-center">
                            {imageData ? (
                                <Image src={imageData} roundedCircle style={avatarStyle} />
                            ) : (
                                <NoImage />
                            )}
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <ButtonGroup>
                                <Button variant="secondary" onClick={operations.handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    as={'label'}
                                    onClick={
                                        imageData
                                            ? (e) => {
                                                  e.preventDefault();
                                                  operations.resetAvatarPhoto();
                                              }
                                            : undefined
                                    }
                                >
                                    {imageData ? (
                                        <>
                                            Clear <i className="bi bi-x-lg"></i>
                                        </>
                                    ) : (
                                        <>
                                            Upload <i className="bi bi-camera"></i>
                                        </>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        ref={models.inputRef}
                                        onChange={operations.handleImageUpload}
                                    />
                                </Button>

                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        onUpdate({ imageData })
                                            .then(() => {
                                                console.log(data);
                                            })
                                            .then(() => operations.handleClose())
                                    }
                                    disabled={data?.profile?.avatar === imageData}
                                >
                                    Update
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    );
};

export default EditableAvatar;
