import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import './index.scss';

export interface IBlockLinkProps {
    target?: string | undefined;
    title: string;
    href: string;
    variant?: string;
}

interface IBlockProps {
    title: string;
    bg?: string;
    fill?: string;
    link?: IBlockLinkProps;
    children: ReactNode;
    callToAction?: ReactNode;
}

export const Block: React.FC<IBlockProps> = ({ title, bg, fill, children, link, callToAction }) => {
    const getBlockClasses = () => {
        let classes = 'block-container';

        if (bg !== undefined) classes += ' block-bg-' + bg;
        else classes += ' block-bg-default';

        return classes;
    };

    const getBlockBodyClasses = () => {
        let classes = fill !== undefined ? 'block-body block-body-shadow' : 'block-body';

        if (fill !== undefined) classes += ' block-body-' + fill + '-gradient';
        else classes += ' block-body-default';
        return classes;
    };

    return (
        <div className={getBlockClasses()}>
            <div className="medium block-title">{title}</div>

            <div className={getBlockBodyClasses()}>{children}</div>

            {link && (
                <div className="block-button-container">
                    <Button
                        target={link.target}
                        href={link.href}
                        className="block-action-link"
                        variant={link.variant ?? 'default'}
                    >
                        {link.title}
                    </Button>
                </div>
            )}
            {callToAction && <div className="block-call-to-action-container">{callToAction}</div>}
        </div>
    );
};
