import React, { useState } from 'react';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import { useDocuments } from '../../hooks/useDocuments';
import { LoadingSpinner } from '../LoadingSpinner';

export interface ICPDFormProps {
    display?: boolean;
    onDismiss?: () => void;
    onSubmit?: (formData: FormData) => Promise<void>;
}

export const CPDForm: React.FC<ICPDFormProps> = ({ display, onDismiss, onSubmit }) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>();
    const { models } = useDocuments();

    const handleCancel = () => {
        if (onDismiss) onDismiss();

        setComplete(undefined);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let form = event.currentTarget;
        let formData = new FormData(form);

        if (!form.checkValidity()) {
            form.reportValidity();
        } else {
            setComplete(false);
            if (onSubmit) {
                setDisabled(true);
                await onSubmit(formData)
                    .then((e) => {
                        setComplete(true);
                        setDisabled(false);
                    })
                    .catch((e) => {
                        console.log(e.message);
                        setDisabled(false);
                    });
            }
        }

        return false;
    };

    return (
        <>
            <Modal show={display} onHide={handleCancel}>
                {complete !== undefined ? (
                    complete === true ? (
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="bi bi-check-lg me-2"></i>
                                    Request Submitted
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>Thank you, your CPD has been applied.</Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={handleCancel}
                                    disabled={disabled}
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </>
                    ) : (
                        <div className="m-4">
                            <LoadingSpinner />
                        </div>
                    )
                ) : (
                    <Form id="form" onSubmit={handleSubmit} noValidate>
                        <Modal.Header closeButton>
                            <Modal.Title>Request CPD Points</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>How many CPD points are you requesting?</Form.Label>
                                <Stack direction="horizontal" gap={4}>
                                    <Form.Control
                                        placeholder={'0'}
                                        style={{ width: 'auto' }}
                                        name="cpd"
                                        type="number"
                                        min={0}
                                        max={99}
                                        required
                                    />
                                    <Form.Check type="checkbox" name="ca" label="C & A CPD?" />
                                </Stack>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Please provide information to support your CPD request.
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    id="request"
                                    name="request"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Please select the document the CPD request is for.
                                </Form.Label>
                                <Form.Select name="primary_document" required>
                                    <option value="">Select a Document</option>
                                    {models.documents &&
                                        models.documents.map((document, index) => (
                                            <option value={document.id} key={document.id}>
                                                {document.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Please select supporting documents. (optional)
                                </Form.Label>
                                <Form.Select name="supporting_documents" multiple>
                                    {models.documents &&
                                        models.documents.map((document, index) => (
                                            <option value={document.id} key={document.id}>
                                                {document.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCancel} disabled={disabled}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit" disabled={disabled}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Modal>
        </>
    );
};
