import React from 'react';
import { Alert, Button, Card, CardBody, Col, Row } from 'react-bootstrap';

interface Props {
    startForm: () => void;
}

const Prerequisites: React.FC<Props> = ({ startForm }) => {
    const additionalRefPDF = process.env.REACT_APP_ADDITIONAL_REFERENCE_PDF_URL;

    return (
        <Row className={'col-10'}>
            <Row>
                <Col xs={8}>
                    <h3 className="fw-bold mb-3">What you'll need</h3>
                    <p className="text-black">
                        Your progress will be saved as you go, so you can make a start now and come
                        back later to finish it.
                    </p>
                </Col>
            </Row>
            <Row>
                <h4>To complete this form you'll need:</h4>
            </Row>
            <Row className="mb-4">
                <Col xs={4}>
                    <Card border="light" className="h-100 p-2">
                        <CardBody>
                            <Card.Title className="mb-3">
                                <i className="bi bi-person-vcard text-white block-bg-dark-blue fs-3 p-2 rounded-circle d-inline-flex"></i>
                            </Card.Title>
                            <Card.Text className="text-black">
                                Contact details and accreditation info for your supervisor.&nbsp;
                                <a
                                    title={'Check eligibility link'}
                                    href="https://emdrassociation.org.uk/become-an-accredited-therapist/training/"
                                >
                                    Check you are eligible to apply
                                </a>
                                .
                            </Card.Text>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card border="light" className="h-100 p-2">
                        <CardBody>
                            <Card.Title className="mb-3">
                                <i className="bi bi-clipboard-check block-bg-ocean text-black fs-3 rounded-circle p-2 d-inline-flex"></i>
                            </Card.Title>
                            <Card.Text className="text-black">
                                Copies of relevant training certificates and professional
                                registration.
                            </Card.Text>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card border="light" className="h-100 p-2">
                        <CardBody>
                            <Card.Title className="mb-3">
                                <i className="bi bi-file-earmark-pdf block-bg-lime fs-3 rounded-circle p-2 d-inline-flex"></i>
                            </Card.Title>
                            <Card.Text className="text-black">
                                Your second reference as a PDF to upload. Get the template{' '}
                                <a
                                    title={'Additional reference template link'}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                    href={additionalRefPDF}
                                >
                                    here
                                </a>
                                .
                            </Card.Text>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={5}>
                    <Button
                        variant="outline-secondary"
                        className={'text-primary fs-6 p-3'}
                        title={'Start your accreditation application'}
                        onClick={() => startForm()}
                    >
                        Start your accreditation application
                        <i className="bi bi-chevron-right ms-2"></i>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <Alert variant="warning" title="Next">
                        <Alert.Heading className="fs-3 mb-3">
                            <i className="bi bi-info-circle mx-1"></i> Next:
                        </Alert.Heading>
                        <div className="mx-3">
                            <ol>
                                <li>
                                    After you've completed this form, your supervisor(s) will be
                                    notified and will begin your Evaluation.
                                </li>
                                <li>
                                    You'll be able to review their Evaluation and ask for changes.
                                </li>
                                <li>
                                    During that process you'll also be asked to make a payment for
                                    your accreditation fee.{' '}
                                    <a
                                        title={'Link to fees and bank details'}
                                        href="https://emdrassociation.org.uk/for-professionals/accreditation/"
                                    >
                                        Fees can be found here
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </Alert>
                </Col>
            </Row>
        </Row>
    );
};

export default Prerequisites;
