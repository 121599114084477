import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_INVOICE_HISTORY } from '../graphql/accounting/queries';
import { MemberInvoice } from '../graphql/__generated__/graphql';
import { useAuth } from 'react-oidc-context';

export const useInvoiceHistory = () => {
    const auth = useAuth();
    const { data, error, loading } = useQuery(GET_INVOICE_HISTORY);
    const [invoiceHistory, setInvoiceHistory] = useState<MemberInvoice[]>();

    useEffect(() => {
        if (!loading && !error) {
            setInvoiceHistory(data.memberInvoiceHistory);
        }

        if (error) console.log(error);
    }, [loading, error, data]);

    const downloadInvoice = async (id: any) => {
        let uri = process.env.REACT_APP_API_ENDPOINT + 'Downloads/Invoice/' + id;

        console.log(uri);
        let response = await fetch(uri, {
            headers: new Headers({
                Authorization: 'Bearer ' + auth.user?.access_token,
            }),
        });

        let reg = /filename=(.*);/gi;
        let s = response.headers.get('Content-Disposition');
        let filename = s
            ?.match(reg)?.[0]
            .replace('filename=', '')
            .replaceAll('"', '')
            .replaceAll(';', '');

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = url;
        link.download = filename ?? 'download-invoice';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return {
        models: {
            loading,
            error,
            invoiceHistory,
        },
        operations: {
            downloadInvoice,
        },
    };
};
