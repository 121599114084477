import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import ApplicationReviewBlock from './ApplicationForm/Components/ApplicationReviewBlock';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner';
import { useAccreditationApplicationFormComponent } from './ApplicationForm/hooks/useAccreditationApplicationFormComponent';
import _ from 'lodash';
import IApplicationReviewSummary from 'interfaces/IApplicationReviewSummary';
import { ReviewStatus } from '../../graphql/__generated__/graphql';

export const ApplicationView: React.FC = () => {
    const location = useLocation();
    const providedApplication =
        location.state?.application || JSON.parse(localStorage.getItem('application') || '{}');
    const { models } = useAccreditationApplicationFormComponent(providedApplication);
    const { loading, formData, requirements, memberProfile, application } = models;

    if (loading) {
        return <LoadingSpinner />;
    }

    const reviewers = _.groupBy(application.reviews, 'reviewerRole');

    const listItem = (review: IApplicationReviewSummary) => {
        return (
            review && (
                <li
                    data-testid={'reviewer-' + review.reviewer.memberId}
                    key={review.reviewer.memberId}
                    className={'mb-2'}
                >
                    <Row className={'align-items-center'}>
                        <Col xs={1}>
                            {(review.reviewStatus === ReviewStatus.Complete ||
                                review.reviewStatus === ReviewStatus.NeedsWork) && (
                                <i
                                    title={
                                        review.reviewStatus === ReviewStatus.NeedsWork
                                            ? 'Needs work'
                                            : 'Complete'
                                    }
                                    className={'fs-3 bi bi-check2 text-success me-2'}
                                />
                            )}
                            {review.reviewStatus === ReviewStatus.InProgress &&
                                review.updatedDate && (
                                    <i
                                        title={'In progress'}
                                        className={'fs-3 bi bi-clock-history text-warning me-2'}
                                    />
                                )}
                            {!review.updatedDate && (
                                <i
                                    title={'Not started'}
                                    className={'fs-3 bi bi-clock text-danger me-2'}
                                />
                            )}
                        </Col>
                        <Col>
                            <span className={'ms-2 fs-5 fw-bold'}>{review.reviewer.name}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="offset-1">
                            <span className={'ms-2 text-muted'}>
                                {(review.reviewStatus === ReviewStatus.Complete ||
                                    review.reviewStatus === ReviewStatus.NeedsWork) &&
                                    review.updatedDate &&
                                    `Completed on ${new Date(review.updatedDate).toLocaleString()}`}
                                {review.reviewStatus === ReviewStatus.InProgress &&
                                    review.updatedDate &&
                                    `In progress since ${new Date(review.updatedDate).toLocaleString()}`}
                                {!review.updatedDate && 'Not started'}
                            </span>
                        </Col>
                    </Row>
                </li>
            )
        );
    };

    return (
        <>
            <Row className={'mb-5'}>
                <Col sm={8}>
                    <h2 className="fs-1 fw-bold mb-3">
                        Review your {application.accreditationType.name} accreditation application
                    </h2>
                    <Row>
                        <h4>
                            Because you've shared this with your supervisors, you won't be able to
                            make changes here. If anything doesn't look right, email us:
                            <a href={'mailto: accreditation@emdrassociation.org.uk'}>
                                accreditation@emdrassociation.org.uk
                            </a>
                        </h4>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <ApplicationReviewBlock
                        memberProfile={memberProfile}
                        formData={formData}
                        requirements={requirements}
                    />
                </Col>
                <Col sm={3}>
                    <Row className={'mb-3'}>
                        <Card className={'border-0 w-100'}>
                            <CardHeader className={'border-0 bg-transparent h4'}>
                                <Card.Title className={'fw-bold'}>Evaluations</Card.Title>
                            </CardHeader>
                            <CardBody>
                                <ul className={'list-unstyled'}>
                                    {reviewers.PRIMARY_SUPERVISOR?.map(listItem)}
                                    {reviewers.SECONDARY_SUPERVISOR?.map(listItem)}
                                </ul>
                            </CardBody>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
