import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { GET_DOCUMENTS } from '../graphql/profile/queries';
import { DELETE_DOCUMENT } from '../graphql/document/mutations';
import { DeleteDocumentCommandInput, MemberDocument } from '../graphql/__generated__/graphql';

export const useDocuments = () => {
    const auth = useAuth();
    const { data, error, loading, refetch } = useQuery(GET_DOCUMENTS);
    const [documents, setDocuments] = useState<MemberDocument[]>();
    const [totalCPD, setTotalCPD] = useState<Number>();
    const [total, setTotal] = useState<Number>();
    const [dueToExpiry, setDueToExpiry] = useState<Number>();
    const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT);

    useEffect(() => {
        if (!loading && !error) {
            setDocuments(data.documents.documents);
            setTotalCPD(data.documents.totalCPD);
            setTotal(data.documents.total);
            setDueToExpiry(data.documents.totalDueToExpiry);
        }
        if (error) console.log('Error retriving documents', error);
    }, [error, loading, data]);

    const addDocument = async (
        event: React.FormEvent<HTMLFormElement>
    ): Promise<boolean | null> => {
        let formData = new FormData(event.currentTarget);

        let result = await fetch(`${process.env.REACT_APP_DOCUMENTAPI_ENDPOINT}/Member/Upload`, {
            method: 'POST',
            body: formData,
            headers: new Headers({
                Authorization: 'Bearer ' + auth.user?.access_token,
            }),
        }).then((response) => {
            if (!response.ok) {
                console.log(response);
                return false;
            } else {
                refetch();
                return true;
            }
        });

        return result;
    };
    const downloadDocument = async (id: string, eventid?: string) => {
        const uri =
            process.env.REACT_APP_DOCUMENTAPI_ENDPOINT +
            (eventid ? `/Event/${eventid}/Download/${id}` : `/Member/Download/${id}`);

        const response = await fetch(uri, {
            headers: new Headers({
                Authorization: 'Bearer ' + auth.user?.access_token,
            }),
        });

        const reg = /filename=(.*);/gi;
        const s = response.headers.get('Content-Disposition');
        const filename = s
            ?.match(reg)?.[0]
            .replace('filename=', '')
            .replaceAll('"', '')
            .replaceAll(';', '');

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = url;
        link.download = filename ?? 'download-file';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    const deleteDocument = async (id: string) => {
        console.log('delete document ' + id);

        deleteDocumentMutation({
            variables: {
                command: {
                    documentId: id,
                } as DeleteDocumentCommandInput,
            },
        })
            .then(() => {
                refetch();
            })
            .catch((e) => {
                console.log('Failed todelete document ' + id, e);
            });
    };
    const shareDocument = async (id: string) => {
        let url = process.env.REACT_APP_DOCUMENT_SHARE_URL + '/' + id;

        navigator.clipboard.writeText(url).then(() => {
            window.alert(
                'Your share link has been copied to the clipboard.\n\nFor security, any user accessing your shared document will need to provide your membership number.'
            );
        });
    };

    return {
        models: {
            loading,
            error,
            documents,
            totalCPD,
            total,
            dueToExpiry,
        },
        operations: {
            addDocument,
            downloadDocument,
            deleteDocument,
            shareDocument,
        },
    };
};
