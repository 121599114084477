import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useMembership } from '../../hooks/useMembership';

export const MembershipCodeForm: React.FC = () => {
    const { operations } = useMembership();

    const [membershipCode, setMembershipCode] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const handleSubmit = async (id: string) => {
        setError(undefined);
        setSubmitting(true);

        await operations
            .bypassMembershipPayment(id, membershipCode)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                //delay resubmission attempts so it can't be spammed.
                setTimeout(() => {
                    setError(error);
                    setSubmitting(false);
                }, 1500);
            });
    };

    return (
        <Card
            border="light"
            className="w-50 m-auto mt-4 p-4 text-primary"
            style={{ maxWidth: '600px' }}
        >
            <Card.Body>
                <Row>
                    <Col>
                        {/*<InputGroup >*/}
                        {/*    <InputGroup.Text style={{ borderBottomLeftRadius: '28px', borderTopLeftRadius: '28px' }}><b>Got a code?</b></InputGroup.Text>*/}
                        {/*    <Form.Control*/}
                        {/*        placeholder="Membership code"*/}
                        {/*        value={membershipCode}*/}
                        {/*        onChange={(e) => { setMembershipCode(e.target.value) }}*/}
                        {/*    />*/}
                        {/*    <Button variant="outline-secondary" onClick={() => handleSubmit("")} disabled={submitting || membershipCode.length === 0}>*/}
                        {/*        {submitting ? <Spinner size="sm" /> : "Submit"}*/}
                        {/*    </Button>*/}
                        {/*</InputGroup>*/}

                        <Form.Group
                            as={Col}
                            lg={12}
                            className="mb-3"
                            data-testid="membership-address2"
                        >
                            <Form.Label>Got a code?</Form.Label>
                            <Form.Control
                                placeholder="Membership code"
                                value={membershipCode}
                                onChange={(e) => {
                                    setMembershipCode(e.target.value);
                                }}
                            />
                            <br />
                            <Button
                                variant="secondary"
                                onClick={() => handleSubmit('')}
                                disabled={submitting || membershipCode.length === 0}
                            >
                                {submitting ? <Spinner size="sm" /> : 'Submit'}
                            </Button>
                        </Form.Group>

                        {error && (
                            <p className="m-0 mt-2 text-danger">
                                <small>{error}</small>
                            </p>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
