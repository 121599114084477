import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_PROFILE } from '../../../graphql/profile/queries';
import { UPDATE_PROFILE } from '../../../graphql/profile/mutations';
import { UpdateProfileCommandInput } from '../../../graphql/__generated__/graphql';
import { EditableContact } from './EditableContact';
import { IEditableContactFormProps } from './IEditableContactFormProps';

export const EditableContactContainer: React.FC = () => {
    const [updateProfile] = useMutation(UPDATE_PROFILE);
    const { data, error, loading, refetch } = useQuery(GET_PROFILE);
    const [updating, setUpdating] = useState<boolean>(false);

    const handleUpdate = async (form: IEditableContactFormProps) => {
        setUpdating(true);

        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateProfile({
                    variables: {
                        profile: {
                            id: data.profile.id,
                            organisation: form.organisation,
                            email: form.email,
                            phone: form.phone,
                            website: form.website,
                            address: {
                                id: data.profile.address.id,
                                addressLine1: form.address1,
                                addressLine2: form.address2,
                                city: form.city,
                                postcode: form.postcode,
                                country: form.country,
                                regionId: form.region,
                            },
                        } as UpdateProfileCommandInput,
                    },
                }).then(() => {
                    refetch();
                    setUpdating(false);
                })
            );
        });
    };

    return (
        <EditableContact
            data={data}
            loading={loading || updating}
            error={error}
            onUpdate={handleUpdate}
        />
    );
};
