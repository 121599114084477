import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
    mutation profileUpdateMutation($profile: UpdateProfileCommandInput) {
        profileUpdate(profile: $profile) {
            address {
                id
                addressLine1
                addressLine2
                city
                country
                postcode
                regionId
            }
            id
            email
            firstName
            lastName
            name
            organisation
            phone
            website
            avatar
            title
        }
    }
`;
