import React, { useEffect, useState } from 'react';
import { useDataContext } from '../../../contexts/DataContext';
import { ProfessionalRegistrationItem } from '../../../graphql/__generated__/graphql';

import MultiSelectList from '../MultiSelectList';

interface ProfessionalRegistrationSelectProps {
    name: string;
    value: ProfessionalRegistrationItem[];
    onChange: (selectedValues: ProfessionalRegistrationItem[]) => void;
}

const ProfessionalRegistrationSelect: React.FC<ProfessionalRegistrationSelectProps> = ({
    name,
    value,
    onChange,
}) => {
    const { getProfessionalRegistrations } = useDataContext();

    const [professionalRegistrations, setProfessionalRegistrations] = useState<
        ProfessionalRegistrationItem[]
    >([]);

    useEffect(() => {
        if (professionalRegistrations.length === 0)
            getProfessionalRegistrations().then((results) => {
                setProfessionalRegistrations(results);
            });
    });

    return (
        <MultiSelectList
            required
            name={name}
            availableOptions={
                professionalRegistrations.map((registration) => {
                    return {
                        id: registration.professionalRegistrationId,
                        label: registration.name,
                    };
                }) as any
            }
            value={
                value.map((value) => {
                    return {
                        id: value.professionalRegistrationId,
                        label: value.name,
                    };
                }) as any
            }
            onChange={(value) =>
                onChange(
                    professionalRegistrations.filter((x) =>
                        value.map((x) => x.id).includes(x.professionalRegistrationId)
                    )
                )
            }
        />
    );
};

export default ProfessionalRegistrationSelect;
