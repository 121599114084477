import { gql } from '@apollo/client';

export const GET_ACCOUNT = gql`
    query GetAccount {
        memberAccount {
            id
            displayName
            companyName
            billingAddress {
                id
                line1
                line2
                line3
                line4
                line5
                city
                country
                countryCode
                county
                postcode
            }
        }
    }
`;

export const GET_INVOICE_HISTORY = gql`
    query GetInvoiceHistory {
        memberInvoiceHistory {
            id
            docNumber
            dueDate
            line {
                description
                amount
            }
        }
    }
`;
