import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import CountrySelect from '../../Form/CountrySelect';
import RegionSelect from '../../Form/RegionSelect/RegionSelect';
import { LoadingError } from '../../LoadingError';
import { IEditableContact } from './IEditableContact';
import { useEditableContact } from './useEditableContact';

export const EditableContact: React.FC<IEditableContact> = ({ data, loading, error, onUpdate }) => {
    const [hover, setHover] = useState(false);
    const { models, operations } = useEditableContact({ data });
    const { handleOpen, handleClose, handleInputChangeEvent } = operations;
    const { show, formData } = models;

    return (
        <>
            {/*loading && <LoadingSpinner></LoadingSpinner>*/}
            {error && <LoadingError></LoadingError>}
            {!loading && !error && (
                <>
                    <div
                        className="mt-2"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={handleOpen}
                        style={{
                            cursor: 'pointer',
                            opacity: hover ? 0.8 : 1,
                        }}
                        title="click to update your contact details"
                    >
                        <Row>
                            <Col className="my-3">
                                <p className="medium">
                                    <b>{data?.profile?.organisation}</b>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            marginLeft: '1em',
                                        }}
                                    >
                                        <i className="bi bi-pencil"></i>
                                    </span>
                                </p>
                                <p>
                                    {data?.profile?.address?.addressLine1 + ', '}
                                    {data?.profile?.address?.addressLine2?.length > 0
                                        ? data?.profile?.address?.addressLine2 + ', '
                                        : ''}
                                    {data?.profile?.address?.city?.length > 0
                                        ? data?.profile?.address?.city + ', '
                                        : ''}
                                    {data?.profile?.address?.postcode}
                                </p>
                                <p>{data?.profile?.address?.region}</p>
                            </Col>
                        </Row>
                        <Row>
                            {data?.profile?.phone?.length > 0 && (
                                <Col md={12}>
                                    <i className="bi bi-phone me-3"></i>
                                    <a
                                        href={'tel:' + data?.profile?.phone}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {data?.profile?.phone}
                                    </a>
                                </Col>
                            )}
                            {data?.profile?.email?.length > 0 && (
                                <Col md={12}>
                                    <i className="bi bi-envelope me-3"></i>
                                    <a
                                        href={'mailto:' + data?.profile?.email}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {data?.profile?.email}
                                    </a>
                                </Col>
                            )}
                            {data?.profile?.website?.length > 0 && (
                                <Col md={12}>
                                    <i className="bi bi-globe me-3"></i>
                                    <a
                                        href={
                                            data?.profile?.website?.startsWith("http://") ||
                                                data?.profile?.website?.startsWith("https://")
                                                ? data.profile.website
                                                : `http://${data?.profile?.website}`
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        {data?.profile?.website}
                                    </a>
                                </Col>
                            )}
                        </Row>
                    </div>
                    {formData && (
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Body>
                                <div>
                                    <Row>
                                        <Form.Group
                                            as={Col}
                                            xs={12}
                                            className="mb-3"
                                            data-testid="membership-address1"
                                        >
                                            <Form.Label>Organisation name</Form.Label>
                                            <Form.Control
                                                required
                                                name="organisation"
                                                value={formData.organisation}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-address1"
                                        >
                                            <Form.Label>Address line 1</Form.Label>
                                            <Form.Control
                                                required
                                                name="address1"
                                                value={formData.address1}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-address2"
                                        >
                                            <Form.Label>Address line 2</Form.Label>
                                            <Form.Control
                                                name="address2"
                                                value={formData.address2}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-city"
                                        >
                                            <Form.Label>Town/city</Form.Label>
                                            <Form.Control
                                                required
                                                name="city"
                                                value={formData.city}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-postcode"
                                        >
                                            <Form.Label>Postcode</Form.Label>
                                            <Form.Control
                                                required
                                                name="postcode"
                                                value={formData.postcode}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-country"
                                        >
                                            <Form.Label>Country</Form.Label>
                                            <CountrySelect
                                                name="country"
                                                value={formData.country}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-country"
                                        >
                                            <Form.Label>Region</Form.Label>
                                            <RegionSelect
                                                name="region"
                                                value={formData.region}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <hr />

                                    <Row>
                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-email"
                                        >
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                required
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChangeEvent}
                                                type="email"
                                            />
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md={9}
                                            lg={6}
                                            className="mb-3"
                                            data-testid="membership-phone"
                                        >
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                required
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group
                                            as={Col}
                                            md={12}
                                            lg={12}
                                            className="mb-3"
                                            data-testid="membership-website"
                                        >
                                            <Form.Label>Website</Form.Label>
                                            <Form.Control
                                                name="website"
                                                value={formData.website}
                                                onChange={handleInputChangeEvent}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <ButtonGroup>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => onUpdate(formData).then(() => handleClose())}
                                    >
                                        Update
                                    </Button>
                                </ButtonGroup>
                            </Modal.Footer>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default EditableContact;
