import { PropsWithChildren, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

interface Props extends PropsWithChildren {
    title: string;
    content?: string | string[];
}

const HelpButton: React.FC<Props> = ({ title, content = '', children }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (!content && !children) return <></>;

    const dangerousHtml = { __html: Array.isArray(content) ? content.join('') : content };
    return (
        <>
            <Button
                // variant={'outline-primary'}
                as={'i'}
                onClick={handleShow}
                className={
                    'bg-transparent border-0 text-primary bi bi-question-circle ms-2 p-0 fs-4 align-baseline'
                }
                title={'Show help'}
            />
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div dangerouslySetInnerHTML={dangerousHtml}></div>
                    {children}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default HelpButton;
