import React from 'react';
import { useEvents } from '../../hooks/useEvents';
import { LoadingSpinner } from '../LoadingSpinner';
import { EventList } from './EventList';

export const EventsContainer: React.FC<{ compact?: boolean }> = (props) => {
    const { models } = useEvents();
    const { loading } = models;

    return loading ? (
        <div>
            <LoadingSpinner />
        </div>
    ) : (
        <EventList {...props} />
    );
};
