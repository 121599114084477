import IAccreditationApplicationForm from '../../../../interfaces/IAccreditationApplicationForm';
import { AccreditationType } from '../../../../graphql/__generated__/graphql';
import IApplicationRequirements, {
    IAppRequirement,
} from '../../../../interfaces/IApplicationRequirements';
import ISimpleNameValuePair from '../../../../interfaces/ISimpleNameValuePair';
import ISupervisor from '../../../../interfaces/ISupervisor';
import IClinicalContact from '../../../../interfaces/IClinicalContact';

export const useApplicationValidator = (accreditationType: AccreditationType) => {
    const accreditationTypeRequirements: IApplicationRequirements = JSON.parse(
        accreditationType?.requirementsJson || '{}'
    );

    const validateNumberField = (required: IAppRequirement, value: number) => {
        return value >= (required.min || 0) && value <= (required.max || Number.MAX_SAFE_INTEGER);
    };

    const validateField = (
        requirement: IAppRequirement,
        actual?: ISimpleNameValuePair
    ): boolean => {
        if (!actual) return false;

        switch (requirement.type) {
            case 'number':
                return validateNumberField(requirement, actual.value);
            case 'checkbox':
                return actual.value as boolean;
            case 'document':
                return !!actual.value;
        }

        return true;
    };

    const validateExperience = (
        applicationForm: IAccreditationApplicationForm,
        hasMitigation: boolean = false
    ) => {
        const errors = [];
        for (const expReq of accreditationTypeRequirements.experience.filter(
            (x) => x.required !== false
        )) {
            const value = applicationForm.experiences?.find((exp) => exp.name === expReq.name);
            if (hasMitigation && (value?.value === '' || value?.value === false)) {
                errors.push(expReq.title + ' is a required field');
            } else if (!hasMitigation && !validateField(expReq, value)) {
                errors.push(expReq.title + ' is invalid');
            }
        }

        return errors;
    };

    const validateSupervisor = (supervisor: ISupervisor): string[] => {
        const errors = [];

        if (!supervisor.ongoing && !supervisor.endDate) {
            errors.push(supervisor.name + ' requires an end date or should be set as ongoing');
        }

        if (!supervisor.startDate) {
            errors.push(supervisor.name + ' requires a start date');
        }

        return errors;
    };

    const validateSupervisors = (applicationForm: IAccreditationApplicationForm): string[] => {
        if (!applicationForm.supervisors?.find((s) => s.primary))
            return ['You must specify at least one primary supervisor'];
        return applicationForm.supervisors?.flatMap((s) => validateSupervisor(s));
    };

    const validateClinicalContact = (clinicalContact: IClinicalContact): boolean => {
        if (!clinicalContact.initials?.length) return false;
        if (!clinicalContact.treatmentSetting?.length) return false;
        if (!clinicalContact.supervisor || !clinicalContact.supervisor.id) return false;
        if (!clinicalContact.startDate?.length) return false;
        if (!clinicalContact.numberOfSessions || clinicalContact.numberOfSessions < 1) return false;
        if (!clinicalContact.presentingProblem?.length) return false;

        return true;
    };

    const validateClinicalContacts = (
        applicationForm: IAccreditationApplicationForm,
        strict = false
    ): string[] => {
        if (strict) {
            const errors = [];
            const clientRequirements = accreditationTypeRequirements.experience?.find(
                (e) => e.name === 'clients-treated'
            );
            const clientCount = applicationForm.clinicalContacts?.length;
            const minClients = clientRequirements?.min || Number.MIN_SAFE_INTEGER;
            const maxClients = clientRequirements?.max || Number.MAX_SAFE_INTEGER;

            if (clientCount < minClients) {
                errors.push(
                    'A minimum of ' +
                        clientRequirements?.min +
                        ' clients are required (currently ' +
                        clientCount +
                        ')'
                );
            } else if (clientCount > maxClients) {
                errors.push(
                    'A maximum of ' +
                        clientRequirements?.max +
                        ' clients are allowed (currently ' +
                        clientCount +
                        ')'
                );
            }

            const clientErrors = applicationForm.clinicalContacts?.map(validateClinicalContact);
            if (clientErrors.filter((r) => !r).length)
                errors.push(
                    clientErrors.length +
                        (clientErrors.length === 1
                            ? ' client is incomplete'
                            : ' clients are incomplete')
                );

            return errors;
        }

        if (!applicationForm.clinicalContacts?.length)
            return ['You must specify at least one complete clinical contact to continue'];

        for (const clinicalContact of applicationForm.clinicalContacts) {
            if (!validateClinicalContact(clinicalContact)) {
                return ['One or more clinical contacts are incomplete'];
            }
        }

        return [];
    };

    const validateDocuments = (applicationForm: IAccreditationApplicationForm): string[] => {
        const flatDocs = accreditationTypeRequirements.documents?.flatMap(
            (req) => req.requirements
        );
        if (!flatDocs) return [];

        const errors = [];
        for (const req of flatDocs.filter((x) => x.required)) {
            const matchingDoc = applicationForm.documents?.find((doc) => req.name === doc.name);
            if (!validateField(req, matchingDoc)) {
                errors.push((req.title || 'Minimum qualification confirmation ') + ' is required');
            }
        }

        return errors;
    };

    const validateForm = (
        applicationForm: IAccreditationApplicationForm,
        hasMitigation: boolean = false
    ) => {
        return [
            ...validateClinicalContacts(applicationForm, true),
            ...validateDocuments(applicationForm),
            ...validateExperience(applicationForm, hasMitigation),
            ...validateSupervisors(applicationForm),
        ];
    };

    return {
        validateForm,
        validateClinicalContact,
        validateClinicalContacts,
        validateDocuments,
        validateExperience,
        validateSupervisors,
    };
};
