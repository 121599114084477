import { useQuery } from '@apollo/client';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { GET_PROFESSIONS } from '../../../graphql/member';
import MultiSelectList from '../MultiSelectList';
import { CoreProfessionSelectProps } from './CoreProfessionSelectProps';
import { useCoreProfessions } from './useCoreProfessions';

const CoreProfessionSelect: React.FC<CoreProfessionSelectProps> = (
    props: CoreProfessionSelectProps
) => {
    const { name, value, onChange } = props;
    const { data, error, loading } = useQuery(GET_PROFESSIONS);
    const { models, operations } = useCoreProfessions({ data });
    const { professions } = models;

    return professions ? (
        <MultiSelectList
            required
            name={name}
            availableOptions={
                professions?.map((profession) => {
                    return {
                        id: profession.professionId,
                        label: profession.name,
                    };
                }) as any
            }
            value={
                value.map((value) => {
                    return { id: value.professionId, label: value.name };
                }) as any
            }
            onChange={(value) =>
                onChange(professions.filter((x) => value.map((x) => x.id).includes(x.professionId)))
            }
        />
    ) : (
        <Spinner size="sm" />
    );
};

export default CoreProfessionSelect;
