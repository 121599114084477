import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import React, { ChangeEvent } from 'react';
import IClinicalContact from 'interfaces/IClinicalContact';
import MemberSelect from 'components/Form/MemberSelect/MemberSelect';
import IMember from '../../../../interfaces/IMember';

interface IClinicalContactEntry {
    contact?: IClinicalContact;
    index?: number;
    handleInputChange: (name: string, value: string | IMember | number) => void;
    showAge?: boolean;
}

const today = new Date().getDate();

const ClinicalContactEntry: React.FC<IClinicalContactEntry> = ({
    contact,
    index = -1,
    showAge = false,
    handleInputChange,
}) => {
    const checkValid = (input: any) => input !== undefined && !!input;
    const checkInvalid = (input: any) => input !== undefined && !input;

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) =>
        handleInputChange(e.target.name, e.target.value);

    const handleOnChangeNumber = (e: ChangeEvent<HTMLInputElement>) =>
        handleInputChange(e.target.name, Number(e.target.value));

    return (
        <>
            <Row className="mb-3 text-black">
                <Col xs={showAge ? 5 : 7}>
                    <FormGroup
                        key={`contact[${index}].initials}`}
                        controlId={`contact[${index}].initials`}
                    >
                        <Form.Label className="h4">Client initials</Form.Label>
                        <Form.Control
                            name="initials"
                            value={contact?.initials?.toUpperCase() || ''}
                            required
                            autoCapitalize={'characters'}
                            onChange={handleOnChange}
                            autoFocus={true}
                            isValid={checkValid(contact?.initials)}
                            isInvalid={checkInvalid(contact?.initials)}
                        />
                    </FormGroup>
                </Col>
                {showAge && (
                    <Col xs={2}>
                        <FormGroup
                            key={`contact[${index}].age}`}
                            controlId={`contact[${index}].age`}
                        >
                            <Form.Label className={'h4'}>Client age</Form.Label>
                            <Form.Control
                                name="age"
                                value={contact?.age || ''}
                                type="number"
                                max={18}
                                min={1}
                                required
                                onChange={handleOnChangeNumber}
                                isValid={checkValid(contact?.age)}
                                isInvalid={checkInvalid(contact?.age)}
                            />
                        </FormGroup>
                    </Col>
                )}
                <Col xs={5}>
                    <FormGroup
                        key={`contact[${index}].startDate}`}
                        controlId={`contact[${index}].endDate`}
                    >
                        <Form.Label className={'h4'}>Start date of EMDR</Form.Label>
                        <Form.Control
                            name="startDate"
                            value={contact?.startDate || ''}
                            type="date"
                            max={today}
                            required
                            onChange={handleOnChange}
                            isValid={checkValid(contact?.startDate)}
                            isInvalid={checkInvalid(contact?.startDate)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3 text-black">
                <Col xs={12}>
                    <FormGroup
                        key={`contact[${index}].presentingProblem}`}
                        controlId={`contact[${index}].presentingProblem`}
                    >
                        <Form.Label className="h4">Presenting problem</Form.Label>
                        <Form.Control
                            name="presentingProblem"
                            value={contact?.presentingProblem || ''}
                            onChange={handleOnChange}
                            required
                            isValid={checkValid(contact?.presentingProblem)}
                            isInvalid={checkInvalid(contact?.presentingProblem)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3 text-black">
                <Col xs={9}>
                    <FormGroup
                        key={`contact[${index}].treatmentSetting}`}
                        controlId={`contact[${index}].treatmentSettings`}
                    >
                        <Form.Label className="h4">
                            Setting where the treatment took place
                        </Form.Label>
                        <Form.Control
                            name="treatmentSetting"
                            value={contact?.treatmentSetting || ''}
                            onChange={handleOnChange}
                            required
                            isValid={checkValid(contact?.treatmentSetting)}
                            isInvalid={checkInvalid(contact?.treatmentSetting)}
                        />
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup
                        key={`contact[${index}].numberOfSessions}`}
                        controlId={`contact[${index}].numberOfSessions`}
                    >
                        <Form.Label className="h4">No. of sessions</Form.Label>
                        <Form.Control
                            name="numberOfSessions"
                            value={contact?.numberOfSessions || ''}
                            onChange={handleOnChangeNumber}
                            type="number"
                            min={1}
                            required
                            isValid={
                                contact?.numberOfSessions !== undefined &&
                                contact?.numberOfSessions > 0
                            }
                            isInvalid={
                                contact?.numberOfSessions !== undefined &&
                                contact?.numberOfSessions < 1
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="text-black">
                <Col>
                    <FormGroup
                        key={`contact[${index}].supervisor}`}
                        controlId={`contact[${index}.supervisor`}
                    >
                        <Form.Label className="h4">Supervisor</Form.Label>
                        <MemberSelect
                            value={contact?.supervisor}
                            name={'supervisor'}
                            onSelect={(value) => handleInputChange('supervisor', value)}
                            required
                            supervisorsOnly={true}
                            placeholder={'Start typing...'}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default ClinicalContactEntry;
