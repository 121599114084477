import React from 'react';
import { Row } from 'react-bootstrap';
import IFormStep from 'interfaces/IFormStep';
import MemberDetailsBlock from '../Components/MemberDetailsBlock';
import Notice from '../Components/Notice';
import { LoadingSpinner } from 'components/LoadingSpinner';
import HelpButton from '../../../Form/HelpButton';

const YourDetails: React.FC<IFormStep> = ({ models, requirements }) => {
    if (models.loading) {
        return <LoadingSpinner />;
    }

    const help = requirements.config?.help && requirements.config?.help['yourDetails'];

    return (
        <>
            <Row className="col-8">
                <h2 className="fw-bold mb-3  fs-3">
                    Check your details{' '}
                    {help && <HelpButton title={'Your details'} content={help} />}
                </h2>
                <Notice>
                    Please check your profile information carefully before proceeding. Anything
                    missing or out of date, please update{' '}
                    <a href={'/dashboard'}>here, under Name and Address details</a>, then resume
                    your application by clicking the button “Start/resume application”.
                </Notice>
            </Row>
            <Row className={'col-8'}>
                <MemberDetailsBlock memberProfile={models.memberProfile} />
            </Row>
            <Row className="col-8">
                <Notice>
                    Information accurate? If so please press Next below. Anything missing or
                    inaccurate please update{' '}
                    <a href={'/dashboard'}>here, under Name and Address details</a>, then resume
                    your application by clicking the button “Start/resume application”.
                </Notice>
            </Row>
        </>
    );
};

export default YourDetails;
