import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import { REQUEST_CPD } from '../../../graphql/document/mutations';
import { GET_CPD_SCORE } from '../../../graphql/profile/queries';
import { CpdScore, RequestCpdCommandInput } from '../../../graphql/__generated__/graphql';
import { CPDForm } from '../../CPDForm';
import { LoadingError } from '../../LoadingError';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Block } from '../Block';
import './index.scss';

interface ICPDBlockProps {
    displayAction?: boolean;
}

const useCPD = () => {
    const { data, refetch, error, loading } = useQuery(GET_CPD_SCORE);
    const [requestCPDMutation] = useMutation(REQUEST_CPD);

    const [cpdScore, setCpdScore] = useState<CpdScore>();
    const [displayRequestForm, setDisplayRequestForm] = useState<boolean>(false);

    useEffect(() => {
        if (!loading && !error) {
            setCpdScore(data.cpdScore);
        }

        if (error) console.log('CPDBlock Error', error);
    }, [loading, error, data]);

    const showRequestCPD = () => {
        setDisplayRequestForm(true);
    };
    const dismissRequestCPD = () => {
        setDisplayRequestForm(false);
    };
    const submitCPDRequest = async (formData: FormData) => {
        console.log('submitting', formData.get('ca'));

        await requestCPDMutation({
            variables: {
                command: {
                    cpd: parseInt(formData.get('cpd') as string),
                    ca: formData.get('ca') === 'on',
                    reasonForRequest: formData.get('request'),
                    primaryDocument: formData.get('primary_document'),
                    supportingDocuments: formData.getAll('supporting_documents'),
                } as RequestCpdCommandInput,
            },
        })
            .then((e) => {
                refetch();
                return true;
            })
            .catch((e) => {
                console.log(e.message);
            });
    };

    return {
        models: {
            loading,
            error,
            cpdScore,
            displayRequestForm,
        },
        operations: {
            showRequestCPD,
            dismissRequestCPD,
            submitCPDRequest,
        },
    };
};

const CPDBlockCTA = () => {
    const { models, operations } = useCPD();
    const eventLink = process.env.REACT_APP_EVENTLINK as string;

    return (
        <div className="cpd_block_calltoaction_container">
            <Button
                target="_blank"
                href={eventLink}
                className="block-action-link small"
                variant="default"
            >
                Find CPD Events
            </Button>
            <Button
                className="block-action-link small"
                variant="default"
                onClick={() => operations.showRequestCPD()}
            >
                Request CPD
            </Button>
            <CPDForm
                onDismiss={() => operations.dismissRequestCPD()}
                onSubmit={operations.submitCPDRequest}
                display={models.displayRequestForm}
            />
        </div>
    );
};

const CPDBlock: React.FC<ICPDBlockProps> = ({ displayAction }) => {
    const { models } = useCPD();
    const { cpdScore, loading, error } = models;

    return (
        <Block title="CPD points" bg="ocean" fill="ocean" callToAction={<CPDBlockCTA />}>
            {loading && <LoadingSpinner />}
            {error && <LoadingError />}
            {!loading && !error && (
                <Container className="my-1 p-0">
                    <Stack direction="horizontal" gap={2} className="align-items-end">
                        <Stack direction="horizontal" gap={2} className="align-items-end">
                            <div className="small">
                                <b>CPD </b>
                                {cpdScore?.generalScore ?? 0}
                            </div>
                            <h2></h2>
                        </Stack>
                        <Stack direction="horizontal" gap={2} className="align-items-end">
                            <div className="small">
                                <b>Child & Adolescent CPD</b> {cpdScore?.caScore ?? 0}{' '}
                            </div>
                            <h2></h2>
                        </Stack>
                    </Stack>
                    <Stack direction="horizontal" gap={2} className="align-items-end">
                        <h2 className="large">
                            {cpdScore?.score}
                            {/* <div className="cpd-block-ranking">{cpdScore?.percentile}</div>*/}
                        </h2>
                        <div className="mb-2" style={{ fontSize: '1.4em' }}>
                            <b>Total CPD</b>
                        </div>
                    </Stack>
                </Container>
            )}
        </Block>
    );
};

export default CPDBlock;
