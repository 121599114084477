import { useApolloClient, useQuery } from '@apollo/client';
import { GET_MEMBER_ACCREDITATIONS } from '../graphql/profile/queries';
import { useEffect, useState } from 'react';
import { IAccreditation } from '../interfaces/IAccreditation';

export const useAccreditations = () => {
    const client = useApolloClient();

    const { data, loading, error } = useQuery(GET_MEMBER_ACCREDITATIONS, { client });

    const [accreditations, setAccreditations] = useState<IAccreditation[]>([]);

    useEffect(() => {
        if (data) {
            setAccreditations(data.memberAccreditations);
        }
    }, [loading, error, data]);

    return {
        loading,
        accreditations,
    };
};
