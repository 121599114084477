import React from 'react';
import { Badge, Button, Card, CardBody, CardHeader } from 'react-bootstrap';
import '../accreditation.scss';
import { IAccreditation } from '../../../interfaces/IAccreditation';
import { IAccreditationApplication } from '../../../interfaces/IAccreditationApplication';

interface IAccreditationCardProps {
    accreditation: IAccreditation;
    application?: IAccreditationApplication;
    onRenewAccreditation: () => void;
    expiring?: boolean;
    renewable?: boolean;
    urgent?: boolean;
}

const AccreditationCard: React.FC<IAccreditationCardProps> = ({
    accreditation,
    application,
    onRenewAccreditation,
    renewable = true,
    expiring = false,
    urgent = false,
}) => {
    const { accreditationType } = accreditation;

    return (
        <Card className="mb-3" border="light">
            <CardHeader className={`bg-transparent border-0 p-0`}>
                {accreditation.active && (
                    <div className={'end-pill-wrapper'}>
                        {expiring ? (
                            <Badge
                                pill
                                bg={urgent ? 'danger' : 'warning'}
                                className={'floating-pill'}
                            >
                                Expires {new Date(accreditation.endDate).toLocaleDateString()}
                                {application &&
                                    application.accreditationApplicationId &&
                                    ' - Renewal application in progress'}
                            </Badge>
                        ) : (
                            <Badge pill bg={'primary'} className={'floating-pill'}>
                                Active{' '}
                                {accreditation.startDate &&
                                    'since ' +
                                        new Date(accreditation.startDate).toLocaleDateString()}
                            </Badge>
                        )}
                    </div>
                )}
                {accreditation.expired && (
                    <div className={'end-pill-wrapper'}>
                        <Badge pill bg={'danger'} className={'floating-pill'}>
                            Expired {new Date(accreditation.endDate).toLocaleDateString()}
                        </Badge>
                    </div>
                )}
            </CardHeader>
            <CardBody>
                <h3 className="card-title mb-3 fw-bold">{accreditationType.name} accreditation</h3>
                {renewable && (
                    <p className="card-subtitle mb-2 fw-bold text-black">
                        Cost: &pound;{accreditationType.price.toFixed(2)}
                    </p>
                )}
                <p className="card-text">{accreditationType.description}</p>
                {renewable && (
                    <Button variant={'outline-secondary'} onClick={onRenewAccreditation}>
                        Renew your {accreditationType.name} accreditation
                    </Button>
                )}
            </CardBody>
        </Card>
    );
};

export default AccreditationCard;
