import React, { useEffect } from 'react';
import IEvaluationFormStep from 'interfaces/IEvaluationFormStep';
import { Alert, Button, Form, FormControl, FormGroup, Row, Stack } from 'react-bootstrap';
import ISimpleNameValuePair from 'interfaces/ISimpleNameValuePair';
import { useReviewFormValidator } from '../hooks/useReviewFormValidator';
import HelpButton from '../../../Form/HelpButton';
import ModalButton from '../../../ModalButton';
import { ReviewStatus } from '../../../../graphql/__generated__/graphql';

function generateCheckboxes(applicantName: string, minimumHours: number): ISimpleNameValuePair[] {
    return [
        {
            name: `I confirm that ${applicantName} has completed a minimum of ${minimumHours} Hours Clinical Supervision/Consultation.`,
            value: false,
        },
        {
            name: `I confirm that I have personally supervised the work of ${applicantName} as outlined in this reference and
    competency framework document in relation to each of the cases listed.`,
            value: false,
        },
        {
            name: `I confirm that ${applicantName} has conformed to the level of attainment as stated and prescribed by the Association.`,
            value: false,
        },
        {
            name: `I attest that this is an honest and valid evaluation of ${applicantName}'s competencies.`,
            value: false,
        },
        {
            name: `I understand that if for any reason information is forthcoming to suggest that the above conditions were not met
    the consultant and/or applicant may forfeit their accreditation and possibly Association membership.`,
            value: false,
        },
    ];
}

const Confirmation: React.FC<IEvaluationFormStep> = ({
    models,
    handleInputChangeEvent,
    handleSubmit,
}) => {
    const [checkboxes, setCheckboxes] = React.useState<ISimpleNameValuePair[]>([]);

    const validator = useReviewFormValidator(models.requirements);

    const handleCheckboxClick = (index: number, value: boolean) => {
        setCheckboxes((prevState) => {
            return prevState.map((c, i) => {
                return {
                    ...c,
                    value: i === index ? value : c.value,
                };
            });
        });
    };

    const handleReturnToApplicant = () => {
        handleSubmit(ReviewStatus.NeedsWork);
    };

    useEffect(() => {
        const hoursOfSupervisionReq = models.requirements.experience.find(
            (req: any) => req.name === 'hours-of-supervision'
        );
        setCheckboxes(
            generateCheckboxes(models.applicationReview.applicant.name, hoursOfSupervisionReq.min)
        );
    }, []);

    const errors = validator.validateForm(models.formData);
    const isValid = errors.length === 0;

    return (
        <Row className={'col-8'}>
            <>
                <h2>
                    Confirmation
                    <HelpButton
                        title={'Confirmation'}
                        content={process.env.REACT_APP_HELP_CONFIRMATION}
                    />
                </h2>
                {!isValid && (
                    <Alert variant={'warning'}>
                        <ul className={'list-unstyled'}>
                            {errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {isValid && (
                    <FormGroup>
                        {checkboxes.map(({ name, value }, index) => (
                            <Row key={'confirm-' + index}>
                                <Form.Label className={'d-flex'}>
                                    <Form.Check
                                        style={{ paddingRight: '1em' }}
                                        required
                                        onChange={(e) =>
                                            handleCheckboxClick(index, e.currentTarget.checked)
                                        }
                                        checked={value as boolean}
                                    />
                                    {name}
                                </Form.Label>
                            </Row>
                        ))}
                        <Row className={'mt-2'}>
                            <Stack direction="horizontal" gap={2}>
                                <Button
                                    type={'submit'}
                                    variant={'outline-secondary'}
                                    disabled={checkboxes.some((c) => !c.value)}
                                >
                                    Submit supervisor evaluation
                                </Button>
                                <ModalButton
                                    modalTitle={'Return to applicant'}
                                    buttonText={'Return to applicant'}
                                    onConfirmModalBtn={handleReturnToApplicant}
                                    confirmBtnText={'Return application'}
                                    buttonProps={{
                                        variant: 'warning',
                                    }}
                                >
                                    <p>
                                        Please provide comments for the applicant citing the reasons
                                        for returning their application.
                                    </p>
                                    <FormControl
                                        as={'textarea'}
                                        rows={8}
                                        name={'comments'}
                                        value={models.formData.comments || ''}
                                        onChange={handleInputChangeEvent}
                                    />
                                </ModalButton>
                            </Stack>
                        </Row>
                    </FormGroup>
                )}
            </>
        </Row>
    );
};

export default Confirmation;
