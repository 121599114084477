import { IAccreditationApplication } from '../../../interfaces/IAccreditationApplication';
import { useAccreditationPayments } from '../../../hooks/useAccreditationPayments';
import { useState } from 'react';
import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';

const useAccreditationPaymentModal = (application: IAccreditationApplication) => {
    const { operations } = useAccreditationPayments();

    const [show, setShow] = useState(false);

    const [flowId, setFlowId] = useState<string | null>(null);
    const [envId, setEnvId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setFlowId(null);
        setEnvId('');
    };

    const handleSetupPayment = () => {
        setLoading(true);
        operations
            .initialiseApplicationPayment(application)
            .then((payment) => {
                if (payment) {
                    setFlowId(payment.flow_id);
                    setEnvId(payment.environment ?? '');
                } else {
                    setFlowId(null);
                    setEnvId('');
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleExit = (error: any, metadata: any) => {
        // TODO: Handle this
        console.log('error', error);
        console.log('metadata', metadata);
    };

    const handleSuccess = (
        billingRequest: BillingRequest,
        billingRequestFlow: BillingRequestFlow
    ) => {
        setLoading(true);
        return operations
            .finaliseApplicationPayment(application, billingRequest, billingRequestFlow)
            .then((_r) => {
                setFlowId(null);
                setEnvId('');
                setLoading(false);
                setShow(false);
                return this;
            });
    };

    return {
        models: { show, envId, flowId, loading },
        operations: {
            handleShow,
            handleClose,
            handleSetupPayment,
            handleSuccess,
            handleExit,
        },
    };
};

export default useAccreditationPaymentModal;
