import React, { useEffect, useState } from 'react';
import IApplicationReview from 'interfaces/IApplicationReview';
import IApplicationReviewForm, { IPartData } from 'interfaces/IApplicationReviewForm';
import useApplicationReview from './useApplicationReview';
import SingleInput from '../Steps/SingleInput';
import AccordionInput from '../Steps/AccordionInput';
import PartD from '../Steps/PartD';
import Confirmation from '../Steps/Confirmation';
import ISimpleNameValuePair from 'interfaces/ISimpleNameValuePair';
import IApplicationRequirements, {
    IEvaluationRequirementsGroup,
} from 'interfaces/IApplicationRequirements';
import { useQuery } from '@apollo/client';
import { GET_ACCREDITATION_TYPES } from 'graphql/member';
import { AccreditationType, ReviewStatus } from 'graphql/__generated__/graphql';
import { IStepConfig } from '../../../Form/FormStepper/FormStepper';
import IRankingsPair from '../../../../interfaces/IRankingsPair';
import { useNavigate } from 'react-router-dom';

function reviewToForm(applicationReview: IApplicationReview): IApplicationReviewForm {
    const { applicationReviewId, jsonData } = applicationReview;
    const appData = JSON.parse(jsonData || '{}');

    return {
        applicationReviewId: applicationReviewId,
        ...appData,
    };
}

export const useSupervisorReviewFormComponent = (
    applicationReviewSummary: IApplicationReview,
    readOnly = false
) => {
    const { models, operations } = useApplicationReview(applicationReviewSummary);
    const navigate = useNavigate();

    const { update } = operations;

    // Application form data
    const [applicationReview, setApplicationReview] =
        useState<IApplicationReview>(applicationReviewSummary);
    const [formData, setFormData] = useState<IApplicationReviewForm>(
        reviewToForm(applicationReviewSummary)
    );
    const [requirements, setRequirements] = useState<IApplicationRequirements>();
    const [stepsConfig, setStepsConfig] = useState<IStepConfig[]>([]);

    // Form visibility
    const [updating, setUpdating] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);

    const { data, error, loading } = useQuery(GET_ACCREDITATION_TYPES);

    useEffect(() => {
        if (!error && !loading) {
            const appType = data.activeAccreditationTypes.find(
                (at: AccreditationType) =>
                    at.accreditationTypeId ===
                    models.applicationReview.accreditationType.accreditationTypeId
            );
            setRequirements(JSON.parse(appType.requirementsJson));
        }
    }, [data, error, loading]);

    useEffect(() => {
        setApplicationReview(models.applicationReview);
        setFormData(reviewToForm(models.applicationReview));
    }, [models.loading, models.applicationReview]);

    const determineContentType = (evalGroup: IEvaluationRequirementsGroup) => {
        switch ((evalGroup.contentType || '').toLowerCase()) {
            case 'accordion':
                return AccordionInput;
            case 'singleinput':
                return SingleInput;
            case 'partd':
                return PartD;
            default:
                return evalGroup.requirements.length > 1 ? AccordionInput : SingleInput;
        }
    };

    const _calculatePartScore = (data: IRankingsPair) => {
        return Object.values(data).reduce((total, value) => total + value);
    };

    const calculateScore = (formData: IApplicationReviewForm): number => {
        const partAScore = formData.partA
            ? _calculatePartScore(formData.partA.value as IRankingsPair)
            : 0;
        const partBScore = formData.partB
            ? formData.partB
                  .flatMap((i) => i.value)
                  .flatMap((i) => i.value)
                  .map(_calculatePartScore)
                  .reduce((total, value) => total + value)
            : 0;
        const partCScore = formData.partC
            ? _calculatePartScore(formData.partC.value as IRankingsPair)
            : 0;

        return partAScore + partBScore + partCScore;
    };

    useEffect(() => {
        const newStepsConfig: IStepConfig[] = (requirements?.evaluation || []).map((req) => {
            return {
                title: req.title,
                content: determineContentType(req),
                helpId: req.name,
            };
        });

        setStepsConfig([
            ...newStepsConfig,
            {
                title: 'Confirmation',
                showNextButton: false,
                showSaveProgressButton: false,
                content: Confirmation,
            },
        ]);
    }, [requirements]);

    const handleSaveProgress = () => {
        if (!formData || updating || readOnly) return;

        setUpdating(true);

        const updatedReview: IApplicationReview = {
            ...applicationReview,
            jsonData: JSON.stringify(formData),
            reviewStatus: ReviewStatus.InProgress,
        };

        return update(updatedReview)
            .then(() => {
                setApplicationReview((prevState) => ({
                    ...prevState,
                    jsonData: JSON.stringify(formData),
                }));
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    const handleInputChangeEvent = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ): void => {
        const { name, value } = event.target;
        handleInputChange(name, value);
    };

    const handleInputChange = (
        name: string,
        value:
            | string
            | string[]
            | boolean
            | ISimpleNameValuePair[]
            | IPartData<unknown>
            | IRankingsPair
    ): void => {
        console.log(name, value);

        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        if (requirements?.config?.isAlgorithm) {
            updatedFormData.score = calculateScore(updatedFormData);
        }

        setFormData(updatedFormData);
    };

    const handleSubmit = (reviewStatus?: ReviewStatus) => {
        update({
            ...applicationReviewSummary,
            jsonData: JSON.stringify(formData),
            reviewStatus: reviewStatus ?? ReviewStatus.Complete,
        })
            .then((response) => {
                navigate('/accreditation?formSubmitResult=evaluation');
            })
            .catch((error) => {
                console.error('error', error);
                return false;
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    const handleNextStep = (back?: boolean) => {
        handleSaveProgress();
        if (back) {
            setCurrentStep(Math.max(currentStep - 1, 1));
        } else {
            setCurrentStep(Math.min(currentStep + 1, stepsConfig.length));
        }
    };

    const handleSetStep = (step: number) => setCurrentStep(step);

    return {
        models: {
            formData,
            currentStep,
            updating,
            stepsConfig,
            applicationReview,
            loading: models.loading,
            requirements,
        },
        operations: {
            handleInputChangeEvent,
            handleInputChange,
            handleNextStep,
            handleSaveProgress,
            handleSetStep,
            handleSubmit,
        },
    };
};
