import React from 'react';

export const LoadingError: React.FC = () => {
    return (
        <div style={{ textAlign: 'center', padding: '1em' }}>
            <i className="bi bi-exclamation-triangle" style={{ color: 'red' }}></i>
            <div style={{ marginLeft: '0.5em', display: 'inline-block' }}>
                Error loading data, please try again later.
            </div>
        </div>
    );
};
