import { Button } from 'react-bootstrap';
import React from 'react';

interface SaveProgressButtonProps {
    onClick?: () => Promise<any> | undefined;
}

const SaveProgressButton: React.FC<SaveProgressButtonProps> = ({
    onClick,
}: SaveProgressButtonProps) => {
    const [enabled, setEnabled] = React.useState(true);

    const handleSaveProgressButton = () => {
        if (!onClick) return;
        setEnabled(false);
        onClick()?.then(() => {
            setEnabled(true);
        });
    };

    return (
        <Button
            disabled={!enabled}
            variant={'outline-secondary'}
            className={'w-50 sticky-top'}
            onClick={() => handleSaveProgressButton()}
        >
            {enabled ? 'Save progress' : 'Saving...'}
        </Button>
    );
};

export default SaveProgressButton;
