import React from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Stack } from 'react-bootstrap';
import '../accreditation.scss';
import {
    AccreditationType,
    ApplicationStatus,
    LockOwner,
    ReviewStatus,
} from 'graphql/__generated__/graphql';
import AbandonApplicationModal from '../ApplicationForm/Components/AbandonApplicationModal';
import { IAccreditationApplication } from 'interfaces/IAccreditationApplication';
import IApplicationReview from 'interfaces/IApplicationReview';

interface IApplicationBlockProps {
    application: IAccreditationApplication;
    accreditationType: AccreditationType;
    handleOnSelectApplication: () => void;
    handleOnViewApplication: () => void;
    handleOnAbandonApplication: () => void;
    handleOnSubmitApplication: () => void;
    handleOnSelectReview: (review: IApplicationReview) => void;
}

// TODO: Move elsewhere
export const niceStatus = (applicationStatus: ApplicationStatus, lockedBy: LockOwner) => {
    switch (applicationStatus) {
        case ApplicationStatus.InProgress: {
            switch (lockedBy) {
                case LockOwner.Applicant:
                    return (
                        <>
                            <i className={'bi bi-pencil me-2'} />
                            In Progress
                        </>
                    );
                case LockOwner.Emdr:
                    return 'Awaiting EMDR review';
                case LockOwner.Supervisor:
                    return 'Awaiting supervisor review';
                default:
                    return 'Status unknown';
            }
        }
        case ApplicationStatus.ReadyForReview:
            return 'Ready for supervisor review';
        case ApplicationStatus.ReadyToSubmit:
            return 'Reviews complete';
        case ApplicationStatus.NeedsWork:
            return 'One or more items need your attention';
        case ApplicationStatus.Rejected:
            return 'Rejected';
        case ApplicationStatus.Approved:
            return 'Approved';
        default:
            return 'Status unknown';
    }
};

const ApplicationBlock: React.FC<IApplicationBlockProps> = ({
    application,
    accreditationType,
    handleOnSelectApplication,
    handleOnSelectReview,
    handleOnViewApplication,
    handleOnAbandonApplication,
    handleOnSubmitApplication,
    // handleOnSubmitForReview
}) => {
    const { applicationStatus, lockedBy, reviews } = application;
    const { name, price, description } = accreditationType;

    const badgeClass = `app-status-${lockedBy?.toLowerCase()}-${applicationStatus?.toLowerCase().replaceAll('_', '-')}`;

    const renderButtons = () => {
        if (lockedBy !== LockOwner.Applicant) {
            return (
                <Button variant="outline-secondary" onClick={handleOnViewApplication}>
                    View application
                </Button>
            );
        }

        if (applicationStatus === ApplicationStatus.ReadyToSubmit) {
            return (
                <Button className={'btn btn-primary'} onClick={handleOnSubmitApplication}>
                    Submit to EMDR
                </Button>
            );
        }

        return (
            <>
                <Button variant={'outline-secondary'} onClick={handleOnSelectApplication}>
                    {applicationStatus === ApplicationStatus.NeedsWork
                        ? 'Fix application'
                        : 'Continue your application'}
                </Button>
                {applicationStatus === ApplicationStatus.InProgress && !application.isPaid && (
                    <AbandonApplicationModal
                        onConfirm={handleOnAbandonApplication}
                        accreditationType={accreditationType}
                    />
                )}
            </>
        );
    };

    return (
        <Card className="mb-3" border="light">
            <CardHeader className={`bg-transparent border-0 p-0`}>
                {applicationStatus && (
                    <div className={'end-pill-wrapper'}>
                        <Badge pill bg={badgeClass} className={'floating-pill'}>
                            {niceStatus(applicationStatus, lockedBy)}
                        </Badge>
                    </div>
                )}
            </CardHeader>
            <CardBody>
                <h3 className="card-title mb-3 fw-bold">{name} accreditation</h3>
                {!applicationStatus && (
                    <p className="card-subtitle mb-2 fw-bold text-black">
                        Cost: &pound;{price.toFixed(2)}
                    </p>
                )}
                <p className="card-text">{description}</p>
                {!applicationStatus ? (
                    <Button variant={'outline-secondary'} onClick={handleOnSelectApplication}>
                        Apply for {name} accreditation
                    </Button>
                ) : (
                    <>
                        <ul className={'list-unstyled'}>
                            {reviews
                                ?.filter(
                                    (review) =>
                                        review.reviewStatus === ReviewStatus.Complete ||
                                        review.reviewStatus === ReviewStatus.NeedsWork
                                )
                                .map((review) => (
                                    <li key={review.applicationReviewId}>
                                        {review.reviewStatus === ReviewStatus.NeedsWork ? (
                                            <i className={'bi bi-x-circle text-danger fs-5'} />
                                        ) : (
                                            <i
                                                className={'bi bi-check2-circle text-success fs-5'}
                                            />
                                        )}
                                        <Button
                                            onClick={() => handleOnSelectReview(review)}
                                            variant={'link'}
                                        >
                                            Review {review.reviewer.name}'s evaluation
                                            <i className={'bi bi-chevron-right ms-2'} />
                                        </Button>
                                    </li>
                                ))}
                        </ul>
                        <Stack direction="horizontal" className="mx-auto" gap={4}>
                            {renderButtons()}
                        </Stack>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default ApplicationBlock;
