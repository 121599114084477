import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import React, { useEffect } from 'react';
import { Button, Form, Modal, ModalTitle, Row, Stack } from 'react-bootstrap';
import { IAccreditationApplication } from 'interfaces/IAccreditationApplication';
import { GoCardlessButton } from '../../Form/PaymentButton/GoCardlessButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import _ from 'lodash';
import useAccreditationPaymentModal from './useAccreditationPaymentModal';

interface Props {
    handleAgreementChange: (field: string, value: boolean) => void;
    onPaymentSuccess: () => void;
    agreementChecks: any[];
    application: IAccreditationApplication;
}

const AccreditationPaymentModal: React.FC<Props> = ({
    handleAgreementChange,
    onPaymentSuccess,
    agreementChecks,
    application,
}) => {
    const { models, operations } = useAccreditationPaymentModal(application);
    const { handleShow, handleClose, handleSuccess, handleExit, handleSetupPayment } = operations;
    const { show, flowId, envId, loading } = models;

    const handlePaymentSuccess = (
        billingRequest: BillingRequest,
        billingRequestFlow: BillingRequestFlow
    ) =>
        handleSuccess(billingRequest, billingRequestFlow).then(() => {
            onPaymentSuccess();
        });

    useEffect(() => {
        if (!flowId && !loading) {
            handleSetupPayment();
        }
    }, [loading, flowId]);

    if (!flowId && loading) return <LoadingSpinner />;

    return (
        <>
            <Button className="btn btn-primary" onClick={handleShow}>
                Continue to payment
            </Button>
            <Modal show={show} onHide={handleClose} size={'lg'}>
                <Modal.Header closeButton className={'border-0 align-items-start'}>
                    <ModalTitle>
                        Next, you'll need to pay your &pound;
                        {application.accreditationType.price} accreditation fee to submit your
                        application.
                    </ModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {agreementChecks.map(({ name, label, value }) => (
                            <Row key={name}>
                                <Form.Label className={'d-flex'}>
                                    <Form.Check
                                        style={{ paddingRight: '1em' }}
                                        required
                                        onChange={(e) =>
                                            handleAgreementChange(name, e.currentTarget.checked)
                                        }
                                        checked={(value as boolean) || false}
                                    />
                                    {label}
                                </Form.Label>
                            </Row>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Stack direction="horizontal" className="mx-auto" gap={4}>
                        <Button variant="outline-secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <GoCardlessButton
                            disabled={!_.every(agreementChecks.map((a) => a.value))}
                            billingRequestFlowID={flowId as string}
                            environment={envId}
                            onExit={handleExit}
                            onSuccess={handlePaymentSuccess}
                            label={<>Checkout</>}
                        />
                    </Stack>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AccreditationPaymentModal;
