import { MemberSelectProps } from './MemberSelectProps';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_MEMBERS, SEARCH_SUPERVISORS } from '../../../graphql/member';
import React from 'react';
import AsyncSelect from 'react-select/async';
import IMember from '../../../interfaces/IMember';

interface MemberOption {
    label: string;
    value: IMember;
}

const MemberSelect: React.FC<MemberSelectProps> = ({
    name,
    value,
    onSelect,
    required,
    supervisorsOnly,
    placeholder,
}: MemberSelectProps) => {
    const [getMembers] = useLazyQuery(supervisorsOnly ? SEARCH_SUPERVISORS : SEARCH_MEMBERS);

    const promiseOptions = (searchTerm: string) =>
        new Promise<MemberOption[]>((resolve) => {
            setTimeout(() => {
                getMembers({ variables: { searchTerm } }).then((result) => {
                    resolve(
                        (result?.data?.members || []).map((member: any) => ({
                            label: member.name,
                            value: {
                                name: member.name,
                                id: member.memberId,
                                email: member.email,
                            },
                        }))
                    );
                });
            }, 500);
        });

    return (
        <AsyncSelect
            aria-label={name}
            name={name}
            loadOptions={promiseOptions}
            defaultValue={value?.id ? { value, label: value?.name } : undefined}
            required={required}
            onChange={(newValue) => newValue?.value && onSelect(newValue.value)}
            placeholder={placeholder}
        />
    );
};

export default MemberSelect;
