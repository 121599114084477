import { Button, Modal, ModalTitle, Stack } from 'react-bootstrap';
import React from 'react';
import { AccreditationType } from 'graphql/__generated__/graphql';
import { LoadingSpinner } from 'components/LoadingSpinner';

interface Props {
    onConfirm: () => void;
    accreditationType: AccreditationType;
}

const AbandonApplicationModal: React.FC<Props> = ({ accreditationType, onConfirm }) => {
    const [show, setShow] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleConfirm = () => {
        setDeleting(true);
        onConfirm();
    };

    return (
        <>
            <Button
                variant={'link'}
                className={'btn-link link-underline'}
                onClick={handleShow}
                disabled={show}
            >
                <i className={'bi-trash'}></i> Abandon application
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className={'border-0 align-items-start'}>
                    <ModalTitle>
                        Are you sure you want to abandon your {accreditationType.name} application?
                    </ModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        This will prevent your supervisors from accessing it, and you'll lose any
                        fees paid towards it. It can't be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    {deleting ? (
                        <>
                            <p>Please wait</p> <LoadingSpinner />
                        </>
                    ) : (
                        <Stack direction="horizontal" className="mx-auto" gap={4}>
                            <Button variant="outline-secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                variant={'outline-danger'}
                                data-test-id={'confirm-delete-btn'}
                                onClick={handleConfirm}
                            >
                                Confirm: abandon application
                            </Button>
                        </Stack>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AbandonApplicationModal;
