import { gql } from '@apollo/client';

export const CREATE_MEMBER = gql`
    mutation memberCreateMutation($member: CreateMemberCommandInput) {
        memberCreate(member: $member) {
            memberId
            title
            firstname
            lastname
            email
            website
            qualifications
            childTrainingLevel
            childTrainingLevelDateAchieved
            region {
                regionId
                name
            }
            addresses {
                addressId
                addressLine1
                addressLine2
            }
            professions {
                professionId
                name
            }
            professionalRegistrations {
                professionalRegistrationId
                name
            }
        }
    }
`;

export const UPDATE_MEMBERCOMMUNICATIONPREFERENCES = gql`
    mutation memberCommunicationPreferencesMutation(
        $command: UpdateMemberCommunicationPreferencesCommandInput
    ) {
        memberCommunicationPreferences(command: $command) {
            memberId
            name
            enabled
        }
    }
`;
export const CREATE_APPLICATION = gql`
    mutation applicationCreateMutation($application: CreateAccreditationApplicationCommandInput) {
        accreditationApplicationCreate(application: $application) {
            accreditationApplicationId
            applicationStatus
            jsonData
            accreditationType {
                accreditationTypeId
                name
                price
                requirementsJson
            }
        }
    }
`;

export const PROGRESS_APPLICATION = gql`
    mutation applicationProgressMutation(
        $application: ProgressAccreditationApplicationCommandInput
    ) {
        accreditationApplicationProgress(application: $application) {
            accreditationApplicationId
            applicationStatus
            lockedBy
            updatedDate
            reviews {
                applicationReviewId
                reviewerRole
                reviewStatus
                updatedDate
                reviewer {
                    name
                    memberId
                }
                applicant {
                    name
                }
            }
            accreditationType {
                accreditationTypeId
                name
                price
                requirementsJson
            }
        }
    }
`;

export const UPDATE_APPLICATION = gql`
    mutation applicationUpdateMutation($application: UpdateAccreditationApplicationCommandInput) {
        accreditationApplicationUpdate(application: $application) {
            accreditationApplicationId
            applicationStatus
            jsonData
            isPaid
            accreditationType {
                accreditationTypeId
                name
                price
                requirementsJson
            }
            hasMitigation
        }
    }
`;

export const DELETE_APPLICATION = gql`
    mutation deleteApplicationMutation($application: DeleteAccreditationApplicationCommandInput) {
        accreditationApplicationDelete(application: $application) {
            accreditationApplicationId
        }
    }
`;

export const UPDATE_APPLICATION_REVIEW = gql`
    mutation applicationReviewUpdateMutation($review: UpdateApplicationReviewCommandInput) {
        applicationReviewUpdate(review: $review) {
            applicationReviewId
            jsonData
            reviewStatus
        }
    }
`;

//export const UPDATE_MEMBER = gql`
//  mutation memberUpdateMutation($member:UpdateMemberCommandInput) {
//    memberUpdate(member: $member) {
// memberId
//        title
//        firstname
//        lastname
//        email
//        website
//        qualifications
//        childTrainingLevel
//        childTrainingLevelDateAchieved
//        region {
//            regionId
//            name
//        }
//        addresses{
//          addressId
//          addressLine1
//          addressLine2
//        }
//        professions{
//          professionId
//          name
//        }
//        professionalRegistrations{
//          professionalRegistrationId
//          name
//        }    }
//  }
//`;
