import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_PROFILE } from '../graphql/profile/queries';
import { MemberProfile } from '../graphql/__generated__/graphql';

export const useMemberProfile = () => {
    const [profile, setProfile] = useState<MemberProfile>();
    const { data, loading, error } = useQuery(GET_PROFILE);

    useEffect(() => {
        if (!loading && !error) {
            setProfile(data.profile as MemberProfile);
        }

        if (error) console.log('Error loading profile', error);
    }, [error, loading, data]);

    return {
        models: {
            loading,
            error,
            profile,
        },
        operations: {},
    };
};
