import React, { useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router';

export const DocumentSharingContainer: React.FC = () => {
    const { id } = useParams();
    const [memberNumber, setMemberNumber] = useState<string>();
    const [downloadError, setDownloadError] = useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent) => {
        setDownloadError(false);

        const uri =
            process.env.REACT_APP_DOCUMENTAPI_ENDPOINT +
            '/Member/SharedDocument/' +
            memberNumber +
            '/' +
            id;

        event.preventDefault();
        event.stopPropagation();

        await fetch(uri)
            .then((response) => {
                if (response.status === 200) {
                    // try and get the orginal filename.
                    let reg = /filename=(.*);/gi;
                    let disposition = response.headers.get('Content-Disposition');
                    let filename = disposition
                        ?.match(reg)?.[0]
                        .replace('filename=', '')
                        .replaceAll('"', '')
                        .replaceAll(';', '');

                    response.blob().then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');

                        link.href = url;
                        link.download = filename ?? 'download-file';

                        document.body.appendChild(link);

                        link.click();

                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    });
                } else {
                    setDownloadError(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            {downloadError && (
                <Row className="justify-content-md-center">
                    <Col md={4}>
                        <Alert variant="danger" dismissible>
                            <b>Download failed. </b> Ensure you have entered the correct membership
                            number for this download and try again.
                        </Alert>
                    </Col>
                </Row>
            )}

            <Row className="justify-content-md-center">
                <Col md="4">
                    <Card border="light">
                        <CardBody>
                            <Form onSubmit={handleSubmit}>
                                <Row style={{ marginBottom: '2em' }}>
                                    <Col>
                                        Plese enter the membership number of the members document to
                                        download the shared document.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            placeholder="Membership #"
                                            onChange={(e) => setMemberNumber(e.target.value)}
                                            required
                                        ></Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '2em',
                                        }}
                                    >
                                        <Button type="submit">Download</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
