import React from 'react';
import { MemberDocument } from '../../graphql/__generated__/graphql';
import { useDocuments } from '../../hooks/useDocuments';
import './index.scss';

export interface IDocumentItemProps {
    document: MemberDocument;
    compact?: boolean;
}
export const DocumentItem: React.FC<IDocumentItemProps> = ({ document, compact }) => {
    const { operations } = useDocuments();

    const deleteDocument = () => {
        if (
            window.confirm(
                "Are you sure want to delete document '" +
                    document.name +
                    "'?\n\nThis action can't be undone."
            )
        ) {
            operations.deleteDocument(document.id);
        }
    };


    const formatDateUK = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const expiryDate = new Date(document.expiries);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const isExpired = expiryDate <= now;

    console.log("Formatted Expiry Date:", formatDateUK(expiryDate), "Expired?:", isExpired);

    return (
        <>
            <tr key={document.id}>
                <td>
                    <span className={isExpired && document.expiries ? 'text-danger' : undefined}>
                        {isExpired && document.expiries && (
                            <i
                                style={{ marginRight: '1em' }}
                                className="bi bi-exclamation-circle-fill"
                            ></i>
                        )}
                        {document.name}
                    </span>
                </td>
                {!compact && (
                    <>
                        <td>
                            {document.expiries != null ? expiryDate.toLocaleDateString() : 'N/A'}
                        </td>
                        <td>{document.cpd !== 0 ? document.cpd : 'N/A'}</td>
                    </>
                )}
                <td align="right">
                    <div className="icon">
                        <a
                            onClick={() => operations.downloadDocument(document.id)}
                            href="#"
                            title="Download Document"
                        >
                            <i className="bi bi-cloud-arrow-down"></i>
                        </a>
                    </div>
                    <div className="icon">
                        <a
                            onClick={() => operations.shareDocument(document.id)}
                            href="#"
                            title="Share Document"
                        >
                            <i className="bi bi-share"></i>
                        </a>
                    </div>
                    <div className="icon">
                        <a onClick={() => deleteDocument()} href="#" title="Delete Document">
                            <i className="bi bi-trash3"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </>
    );
};
