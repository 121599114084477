import React, { useEffect, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FormStepper from '../../Form/FormStepper/FormStepper';
import { useAccreditationApplicationFormComponent } from './hooks/useAccreditationApplicationFormComponent';
import IFormStep from 'interfaces/IFormStep';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../../LoadingSpinner';
import { FeedbackLevel } from '../../../interfaces/FeedbackLevel';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { IAccreditationApplication } from '../../../interfaces/IAccreditationApplication';
import Prerequisites from './Prerequisites';

const ApplicationForm: React.FC = () => {
    const formRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const notificationContext = useNotificationContext();

    const checkLocalStorage = () => {
        const backup = localStorage.getItem('accreditationApplicationBackup');

        if (!backup) return location.state?.application;
        try {
            return JSON.parse(backup);
        } catch (e) {
            console.warn('Invalid application backup detected and removed');
            return;
        }
    };

    const barebonesApplication: IAccreditationApplication = checkLocalStorage();

    if (!barebonesApplication) {
        navigate('/accreditation');
    }
    const { models, operations } = useAccreditationApplicationFormComponent(barebonesApplication);

    const { formData, updating, currentStep, requirements, operationResult, application } = models;

    const {
        handleNextStep,
        handleInputChangeEvent,
        handleInputChange,
        handleSaveProgress,
        handleSetStep,
        handleSubmitApplication,
        handleApplicationUpdate,
    } = operations;

    const handleSubmit = async () => {
        return handleSubmitApplication()
            .then(() => {
                navigate('/accreditation?formSubmitResult=application');
                return this;
            })
            .catch(() => {
                handleFormFeedback(
                    'danger',
                    'Something went wrong when submitting the application. Please try again later'
                );
            });
    };

    const handleFormFeedback = (level: FeedbackLevel, message?: string[] | string) => {
        notificationContext.notify(level, message);
    };

    useEffect(() => {
        if (operationResult?.message) {
            handleFormFeedback(
                operationResult.success ? 'info' : 'danger',
                operationResult.message
            );
        }
    }, [operationResult]);

    const renderStep = (Step: React.FC<IFormStep>) => (
        <Step
            formData={formData}
            models={models}
            handleInputChangeEvent={handleInputChangeEvent}
            handleFeedback={handleFormFeedback}
            handleNext={handleNextStep}
            handleInputChange={handleInputChange}
            handleSubmitApplication={handleSubmit}
            handleApplicationUpdate={handleApplicationUpdate}
            requirements={requirements}
        />
    );

    if (!application) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <h1 className="fs-1 fw-bold mb-5">
                Apply for {application.accreditationType.name} accreditation
            </h1>

            <Row>
                <Col>
                    {!application.accreditationApplicationId && (
                        <Prerequisites startForm={() => handleSetStep(1)} />
                    )}
                    {application.accreditationApplicationId && (
                        <Form ref={formRef} noValidate>
                            <FormStepper
                                stepNumber={currentStep}
                                renderStep={renderStep}
                                steps={models.stepsConfig}
                                formRef={formRef}
                                handleFeedback={handleFormFeedback}
                                handleSetStep={handleSetStep}
                                handleNextStep={handleNextStep}
                                handleCancel={() => {
                                    navigate('/accreditation');
                                }}
                                handleSubmit={() => {
                                    console.log('submit');
                                }}
                                handleSaveProgress={handleSaveProgress}
                                submitted={false}
                                submitting={false}
                                allowSubmit={false}
                                allowSkipStep={!!models.application.accreditationApplicationId}
                                submitText={'Submit and pay'}
                                nextButtonText={'Save and continue'}
                                className={'overflow-x-auto'}
                            ></FormStepper>
                        </Form>
                    )}
                </Col>
            </Row>

            {updating && (
                <Row>
                    <LoadingSpinner />
                </Row>
            )}
        </>
    );
};

export default ApplicationForm;
