import React from 'react';
import IEvaluationFormStep from 'interfaces/IEvaluationFormStep';
import { Row } from 'react-bootstrap';
import AlgorithmLegend from '../AlgorithmLegend';
import EvaluationInput from '../EvaluationInput';
import IRankingsPair from '../../../../interfaces/IRankingsPair';
import HelpButton from '../../../Form/HelpButton';

const SingleInput: React.FC<IEvaluationFormStep> = ({
    formData,
    onFeedback,
    onInputChange,
    requirements,
    requirementsGroup,
    readOnly = false,
}) => {
    const formPartData = formData[requirementsGroup.name];
    const partComplete = formPartData?.complete || false;
    const partValue = formPartData?.value;

    const help = requirements.config?.help && requirements.config?.help[requirementsGroup.name];
    const req = requirementsGroup.requirements[0];
    const { minLength = 1, maxLength } = req;
    const isAlgorithm = req.type?.toLowerCase() === 'algorithm';

    const handleChange = (change: { value: string | IRankingsPair; complete: boolean }) => {
        onInputChange(requirementsGroup.name, change);
    };

    return (
        <Row>
            <h2>
                {requirementsGroup.pageTitle}{' '}
                {partComplete && <i className={'bi bi-check2-circle me-2 fs-4'} />}
                <HelpButton title={requirementsGroup.pageTitle} content={help}>
                    {isAlgorithm && <AlgorithmLegend />}
                </HelpButton>
            </h2>
            {!isAlgorithm && <p className={'text-black'}>{requirementsGroup.pageSubtitle}</p>}
            <EvaluationInput
                isAlgorithm={isAlgorithm}
                readOnly={readOnly}
                req={req}
                inputValue={partValue}
                inputComplete={partComplete}
                onFeedback={onFeedback}
                minLength={minLength}
                maxLength={maxLength}
                onChange={handleChange}
            />
        </Row>
    );
};

export default SingleInput;
