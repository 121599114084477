import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { LoadingError } from '../../LoadingError';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Block, IBlockLinkProps } from '../Block';
import './index.scss';
import { useMembership } from '../../../hooks/useMembership';

interface IMembershipBlock {
    link?: IBlockLinkProps;
    callToAction?: React.ReactNode;
}

const MembershipBlock: React.FC<IMembershipBlock> = ({ link, callToAction }) => {
    const { models, operations } = useMembership();
    const { membership, error, loading } = models;
    const { formattedExpiryDate } = operations;

    return (
        <Block title="Membership" bg="lime" fill="lime" link={link} callToAction={callToAction}>
            {loading && <LoadingSpinner />}
            {error && <LoadingError />}
            {!loading && !error && (
                <>
                    <div className="membership-block-badge-container d-flex justify-content-between">
                        <div className="small">
                            <b>{membership?.type}</b>
                        </div>
                        <div>
                            {(membership?.subscriptionId?.length ?? 0) > 0 && (
                                <Badge pill>Auto Renew</Badge>
                            )}
                        </div>
                    </div>

                    <div className="membership-block-membershipnumber">{membership?.number}</div>

                    <div className="small me-2 mt-2">
                        <b>Renews {formattedExpiryDate(new Date(membership?.endDate))}</b>
                    </div>

                    {!membership?.active && (
                        <Button
                            className="membership-block-renewal-btn "
                            onClick={() => {
                                window.alert('take user to renewal screen');
                            }}
                        >
                            Renew
                        </Button>
                    )}
                </>
            )}
        </Block>
    );
};

export default MembershipBlock;
