import { gql } from '@apollo/client';

export const DELETE_DOCUMENT = gql`
    mutation deleteDocument($command: DeleteDocumentCommandInput!) {
        deleteDocument(command: $command) {
            total
            totalCPD
            documents {
                id
                name
                cpd
                expiries
            }
        }
    }
`;

export const REQUEST_CPD = gql`
    mutation requestCPD($command: RequestCPDCommandInput!) {
        requestCPD(command: $command) {
            id
        }
    }
`;
