import React from 'react';
import { ButtonGroup, Col, Form, FormGroup, Row, Table } from 'react-bootstrap';
import IFormStep from 'interfaces/IFormStep';
import { LoadingError } from 'components/LoadingError';
import _ from 'lodash';
import IClinicalContact from '../../../../interfaces/IClinicalContact';
import HelpButton from '../../../Form/HelpButton';
import ModalButton from '../../../ModalButton';
import ClinicalContactEntry from '../Components/ClinicalContactEntry';

const defaultEntry = {
    supervisor: { id: '', name: '' },
};
const ClinicalContact: React.FC<IFormStep> = ({
    formData,
    requirements,
    handleInputChange,
    models,
}) => {
    const clinicalContacts = formData.clinicalContacts ?? [];

    const generateNewClientTemplate = (
        template: IClinicalContact = defaultEntry
    ): IClinicalContact => {
        if (!clinicalContacts.length) {
            const { supervisor, treatmentSetting } = template;
            return {
                supervisor,
                treatmentSetting,
            };
        }

        const { supervisor, treatmentSetting } = clinicalContacts[clinicalContacts.length - 1];

        return {
            supervisor,
            treatmentSetting,
        };
    };

    const [editableClinicalContact, setEditableClinicalContact] = React.useState<IClinicalContact>(
        generateNewClientTemplate()
    );
    const [keepModalOpen, setKeepModalOpen] = React.useState(false);

    const handleEditableClinicalContactUpdate = (name: string, value: any) => {
        setEditableClinicalContact((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUpdateClinicalContact = (index: number) => {
        const updatedClinicalContacts = clinicalContacts.map((cc, i) => {
            if (i === index) {
                return editableClinicalContact;
            }

            return cc;
        });
        handleInputChange('clinicalContacts', updatedClinicalContacts);
    };

    const handleAddNewClinicalContact = () => {
        handleInputChange('clinicalContacts', clinicalContacts.concat([editableClinicalContact]));
        setEditableClinicalContact(generateNewClientTemplate(editableClinicalContact));
    };

    const handleDeleteClient = (index: number) => {
        handleInputChange(
            'clinicalContacts',
            _.filter(clinicalContacts, (_item, i) => i !== index)
        );
        setEditableClinicalContact(generateNewClientTemplate());
    };

    const clientRequirements = requirements.experience?.find((e) => e.name === 'clients-treated');
    const sessionRequirements = requirements.experience?.find(
        (e) => e.name === 'sessions-conducted'
    );
    if (!clientRequirements || !sessionRequirements) return <LoadingError />;

    const help = requirements.config?.help && requirements.config?.help['clinicalContacts'];

    const totalSessions: number = (clinicalContacts || [])
        .map((cc) => Number(cc.numberOfSessions || 0))
        .reduce((total, current) => {
            return total + current;
        }, 0);

    const meetsMinimumClientsRequirements = clinicalContacts.length >= clientRequirements.min;
    const meetsMinimumSessionsRequirements = totalSessions >= sessionRequirements.min;

    const isValid = (contact: IClinicalContact) => {
        return !models.formValidator.validateClinicalContact(contact);
    };

    return (
        <>
            <Row>
                <h3 className="fw-bold mb-3">
                    EMDR clinical contact activity{' '}
                    {help && <HelpButton title={'Clinical contacts'} content={help} />}
                </h3>
            </Row>
            <Row className={'mb-3 col-10 text-black fw-normal'}>
                <h4 className={'fw-normal'}>
                    Add details for each client separately. A new record entry form will appear
                    automatically when you complete a record.
                </h4>
                <h4 className={'fw-normal'}>
                    You'll need to enter at least {clientRequirements.min}{' '}
                    {clientRequirements.max ? ` (max ${clientRequirements.max}) ` : ''}
                    different cases covering {sessionRequirements.min} sessions to continue your
                    application. The majority of records you enter should reflect all 8 phases.
                </h4>
            </Row>
            {/*{clientInfoCard}*/}
            {clinicalContacts.length > 0 && (
                <Row className={'mb-4'}>
                    <Col xs={12}>
                        <Table responsive className={'client-contact-table'}>
                            <thead>
                                <tr>
                                    <th>Client initials</th>
                                    {requirements.config?.isCA && <th>Age</th>}
                                    <th>EMDR start date</th>
                                    <th>Presenting problem</th>
                                    <th>Sessions</th>
                                    <th>Treatment setting</th>
                                    <th>Supervisor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {clinicalContacts.map((contact, index) => (
                                    <tr key={'clinicalContact-' + index}>
                                        <td>{contact.initials}</td>
                                        {requirements.config?.isCA && <td>{contact.age}</td>}
                                        <td>
                                            {new Date(contact.startDate || '').toLocaleDateString()}
                                        </td>
                                        <td>{contact.presentingProblem}</td>
                                        <td>{contact.numberOfSessions}</td>
                                        <td>{contact.treatmentSetting}</td>
                                        <td>{contact.supervisor?.name}</td>
                                        <td>
                                            <ButtonGroup>
                                                <ModalButton
                                                    buttonText={''}
                                                    modalProps={{ size: 'lg' }}
                                                    confirmBtnText={'Update clinical contact'}
                                                    onConfirmModalBtn={() =>
                                                        handleUpdateClinicalContact(index)
                                                    }
                                                    onCancelModalBtn={() =>
                                                        setEditableClinicalContact(
                                                            generateNewClientTemplate()
                                                        )
                                                    }
                                                    beforeShow={() => {
                                                        setEditableClinicalContact(contact);
                                                    }}
                                                    modalTitle={'Edit clinical contact'}
                                                    buttonProps={{
                                                        as: 'i',
                                                        className:
                                                            'btn-sm btn-secondary border-0 text-secondary bi bi-pencil bg-transparent fs-5',
                                                        title: 'Edit client ' + contact.initials,
                                                    }}
                                                    cancelButtonProps={{
                                                        variant: 'outline-secondary',
                                                        className: 'text-primary fs-6',
                                                    }}
                                                    confirmButtonProps={{
                                                        className: 'text-primary fs-6',
                                                        disabled: isValid(editableClinicalContact),
                                                    }}
                                                >
                                                    <ClinicalContactEntry
                                                        index={index}
                                                        contact={editableClinicalContact}
                                                        showAge={requirements.config?.isCA}
                                                        handleInputChange={(name, value) =>
                                                            handleEditableClinicalContactUpdate(
                                                                name,
                                                                value
                                                            )
                                                        }
                                                    />
                                                </ModalButton>
                                                <ModalButton
                                                    buttonText={''}
                                                    confirmBtnText="Confirm: delete"
                                                    onConfirmModalBtn={() =>
                                                        handleDeleteClient(index)
                                                    }
                                                    buttonProps={{
                                                        as: 'i',
                                                        className:
                                                            'btn-sm text-danger border-0 bi bi-trash bg-transparent fs-5',
                                                        title: 'Delete client ' + contact.initials,
                                                    }}
                                                    modalTitle="Are you sure you want to delete this record?"
                                                    confirmButtonProps={{
                                                        variant: 'outline-danger',
                                                    }}
                                                    cancelButtonProps={{
                                                        variant: 'outline-secondary',
                                                    }}
                                                    cancelBtnText="Cancel: keep record"
                                                >
                                                    This can't be undone.
                                                </ModalButton>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {clinicalContacts?.length < (clientRequirements.max ?? Number.MAX_SAFE_INTEGER) && (
                <Row>
                    <Col xs={5}>
                        <ModalButton
                            modalProps={{ size: 'lg' }}
                            confirmBtnText={'Add clinical contact'}
                            onConfirmModalBtn={(e) => {
                                if (keepModalOpen) e?.stopPropagation();
                                handleAddNewClinicalContact();
                            }}
                            onCancelModalBtn={() =>
                                setEditableClinicalContact(generateNewClientTemplate())
                            }
                            modalTitle={'Add clinical contact'}
                            buttonText={
                                <>
                                    <i className={'bi bi-plus-circle me-3'} />
                                    Add clinical contact
                                </>
                            }
                            buttonProps={{
                                variant: 'outline-secondary',
                                className: 'fs-6',
                                size: 'lg',
                            }}
                            cancelButtonProps={{
                                variant: 'outline-secondary',
                                className: 'fs-6',
                            }}
                            confirmButtonProps={{
                                className: 'text-primary fs-6',
                                disabled: isValid(editableClinicalContact),
                            }}
                        >
                            <ClinicalContactEntry
                                contact={editableClinicalContact}
                                showAge={requirements.config?.isCA}
                                handleInputChange={handleEditableClinicalContactUpdate}
                            />
                            <Row className={'mt-3'}>
                                <FormGroup>
                                    <Form.Label className={'d-flex'}>
                                        <Form.Check
                                            className={'me-3'}
                                            checked={keepModalOpen}
                                            onChange={() => setKeepModalOpen(!keepModalOpen)}
                                        />
                                        Keep form open
                                    </Form.Label>
                                </FormGroup>
                            </Row>
                        </ModalButton>
                    </Col>
                </Row>
            )}
            {/*{clientInfoCard}*/}
        </>
    );
};

export default ClinicalContact;
